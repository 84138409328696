<template>
    <div>
        <div class="login-cover">
            <div class="login-cover-image" v-bind:style="{ backgroundImage: 'url('+ bg +')' }"></div>
            <div class="login-cover-bg"></div>
        </div>
        
        <div class="login login-v2" data-pageload-addclass="animated fadeIn">
            <div class="login-header">
                <div class="brand">
                    <span class="logo"></span> <b>BPress</b> Mediabooster
					<small>Mailing manager for heroes.</small>
                </div>
                <div class="icon">
                    <i class="fa fa-lock"></i>
                </div>
            </div>
            <div class="login-content">

                <button type="button" class="btn btn-default w-100 mt-2" data-toggle="button" aria-pressed="false" autocomplete="off" @click="adminAuth" v-if="isAdminLogin">
                    ACCEDI COME AMMINISTRATORE
                </button>

                <button type="button" class="btn btn-default w-100 mt-2" data-toggle="button" aria-pressed="false" autocomplete="off" @click="googleAuth" v-else>
                    ACCEDI CON <i class="fab fa-google" style="margin-right:2px"></i>OOGLE
                </button>
                
            </div>

        </div>

    </div>
</template>

<script>
import GAuth from 'vue-google-oauth2'
import Vue from 'vue'

export default {
    name : 'Login',
    mounted() {
        if (!this.$parent.env_local)
            Vue.use(GAuth, {
                clientId: process.env.MIX_GOOGLE_CLIENT_ID,
                scope: 'profile email',
                prompt: 'select_account'
            })
    },
    data() {
        return {
            isAdminLogin: process.env.MIX_LOCAL_LOGIN === 'true',
            bg : '/assets/img/login-bg/login-bg-20.jpg'
        }
    },
    methods: {
        googleAuth() {
            this.$gAuth.signIn()
                .then(GoogleUser => {
                    if (this.$gAuth.isAuthorized) {
                        window.axios.post('/login', {
                            email : GoogleUser.getBasicProfile().getEmail(),
                            name : GoogleUser.getBasicProfile().getName(),
                            google_id : GoogleUser.getBasicProfile().getId()
                        }).then(function (res) {
                                window.vm.auth_user = res.data === 'KO' ? null : res.data;
                                if (window.vm.auth_user !== null)
                                    window.location.href = '/';
                            })
                            .catch(function () {
                                window.vm.auth_user = null
                            });
                    }
                })
        },
        adminAuth() {
            window.axios.post('/login')
                .then(function (res) {
                    window.vm.auth_user = res.data;
                    window.location.href = '/';
                })
        }
    }
}
</script>
