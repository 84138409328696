<template>
	<div>
		<div class="row">
			<div class="col-md-6">
				<div class="row">
					<div class="col-6">
						<div class="widget widget-stats bg-gradient-purple" @click="redirect('/journalists')" style="cursor:pointer">
							<div class="stats-icon stats-icon-lg"><i class="fa fa-users fa-fw"></i></div>
							<div class="stats-content">
								<div class="stats-title">{{ 'journalists' | transUC }}</div>
								<div class="stats-number" v-text="stats.journalists"></div>
							</div>
						</div>
					</div>
					<div class="col-6">
						<div class="widget widget-stats bg-gradient-teal" @click="redirect('/newsletters')" style="cursor:pointer">
							<div class="stats-icon stats-icon-lg"><i class="fa fa-envelope fa-fw"></i></div>
							<div class="stats-content">
								<div class="stats-title">{{ 'mail sent' | transUC }}</div>
								<div class="stats-number" v-text="stats.messages"></div>
							</div>
						</div>
					</div>
				</div>

				<newsletters-graphs></newsletters-graphs>

			</div>
			<div class="col-md-6">

				<newsletters-list></newsletters-list>
				
			</div>
			
			<!-- <div class="col-lg-3 col-md-6">
				<div class="widget widget-stats bg-gradient-blue">
					<div class="stats-icon stats-icon-lg"><i class="fa fa-envelope fa-fw"></i></div>
					<div class="stats-content">
						<div class="stats-title">{{ 'open' | transUC }}</div>
						<div class="stats-number" v-text="stats.open"></div>
						<div class="stats-progress progress">
							<div class="progress-bar" :style="'width : '+percentage(stats.open)+'%;'"></div>
						</div>
						<div class="stats-desc" v-text="stats.open+'/'+stats.all">.</div>
					</div>
				</div>
			</div> -->
		</div>
	</div>
</template>

<script>
import NewslettersList from './dashboard/NewslettersList.vue'
import NewslettersGraphs from './dashboard/NewslettersGraphs.vue'
export default {
	name: 'Dashboard',
	components : {
		NewslettersList,
		NewslettersGraphs
	},
	mounted() {
		var t = this
		window.axios.get('/api/dashboard/stats')
		.then(function(response) {
			Object.keys(response.data.stats).map(item => {
				t.stats[item] = Number(response.data.stats[item]).toLocaleString('it-IT', {minimumFractionDigits : 0})
			});
			t.newsletters = response.data.newsletters
			t.graphs = response.data.graphs
		})
	},
	data() {
		return {
			stats : {
				journalists : 0,
				messages : 0
			},
			newsletters : [],
			graphs : []
		}
	},
	methods: {
		percentage(val) {
			if (!isNaN(val/this.all))
				return ((val/this.all)*100).toFixed(2)
			return 100
		},
		redirect( url ) {
			if ( this.$route.path !== url )
				this.$router.push({ path: url })
		}
	}
}
</script>