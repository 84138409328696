<template>
	<div>
        <g-vue-table
            :apiIndexUrl="'/api/newsletters'"
            sortBy="id"
            sortDirection="desc"
            :columns="columns"
            ref="GVueTable"
            :obj="obj"
            @newObj="$emit('newObj')"
            :actionsDropdown="actionsDropdown"
        ></g-vue-table>

        <bulk-delete-modal 
            ref="bulkDeleteModal" 
            domain="newsletters"
            deletableRef="GVueTable"
        ></bulk-delete-modal>
	</div>
</template>

<script>
export default {
    name : 'NewslettersList',
    props : [ 'obj' ],
    methods : {
    },
    computed : {
        actionsDropdown() {
            var t = this;
            return [{
                name : this.transUC('delete'),
                clicked : function(ids) {
                    t.$refs.bulkDeleteModal.show( ids );
                }
            }]
        }
    },
    data(){
        return {
            columns : [{
                name: '__checkbox',
                titleClass: 'text-center short-cell',
                dataClass: 'text-center short-cell',
            },{
                title: '#',
                name: 'id',
                sortField: 'id',
                titleClass: 'text-right medium-cell',
                dataClass: 'text-right medium-cell clickable',
                cellClicked: function(t, data) {
                    t.$refs.GVueTable.loaded = false
                    window.axios.get('/api/newsletters/'+data.id)
                    .then(function(response) {
                        t.$emit('setObj', response.data);
                    })
                }
            },{
                title : '',
                name: '',
                sortField: 'sent',
                callback(data) {
                    if (data.sent)
                        return '<i class="fa fa-check" title="INVIATA"></i>'
                    else if (data.in_sending)
                        return '<i class="fa fa-clock" title="IN CORSO"></i>'
                    return ''
                }
            },{
                title : this.transUC('name'),
                name: 'name',
                sortField: 'name',
                dataClass: 'clickable',
                cellClicked: function(t, data) {
                    t.$refs.GVueTable.loaded = false
                    window.axios.get('/api/newsletters/'+data.id)
                    .then(function(response) {
                        t.$emit('setObj', response.data);
                    })
                }
            },{
                title : this.transUC('subject'),
                name: 'subject',
                sortField: 'subject',
                dataClass: 'clickable',
                cellClicked: function(t, data) {
                    t.$refs.GVueTable.loaded = false
                    window.axios.get('/api/newsletters/'+data.id)
                    .then(function(response) {
                        t.$emit('setObj', response.data);
                    })
                }
            },{
                title : this.transUC('expected'),
                name: 'emails_count',
                sortField: 'emails_count',
                callback(data) {
                    return data !== 0 ? data : ''
                }
            },{
                title : this.transUC('delivery'),
                name: 'send_start',
                sortField: 'send_start'
            },{
                title : this.transUC('end'),
                name: 'send_end',
                sortField: 'send_end',
                titleClass: 'text-center updated-at-cell',
                dataClass: 'text-right updated-at-cell'
            }],
            moreParams : {}
        }
    }
}
</script>