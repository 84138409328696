<template>
    <div>
        <div class="row">
            <hr>
            <div class="col-md-3">
                <newsletter-pie :chart-data="chartData"></newsletter-pie>
                <br>
                <table class="table table-striped table-condensed" v-if="chartData.datasets !== undefined">
                    <tr v-for="(stat, k) in chartData.datasets[0].data" :key="k">
                        <td 
                            style="text-align:left;" 
                            v-text="chartData.labels[k]" 
                        ></td>
                        <td 
                            style="text-align:right;" 
                            v-text="stat"
                        ></td>
                        <td 
                            style="text-align:right;" 
                            v-text="percentage(stat, chartData.total)"
                        ></td>
                    </tr>
                </table>
            </div>
            <div class="col-md-9">
                <g-vue-table
                    :apiIndexUrl="'/api/newsletters/'+obj.id+'/statistics/details'"
                    sortBy="id"
                    sortDirection="desc"
                    :filtered="false"
                    :columns="columns"
                    ref="GVueTable"
                    :per-page="15"
                    :obj="obj"
                ></g-vue-table>
            </div>
        </div>
        <b-modal id="showNotifications" 
            ref="showNotifications"
            :title="transUF('notifications')"
            size="lg"
            ok-only 
        >
            <vue-json-pretty 
                :data="{data: notificationData}" 
                path="data" 
                rootPath:="'data'" 
                :showLength="true" 
                :deep="3" />
        </b-modal>
    </div>
</template>

<script>
import StatisticsDetails from './StatisticsDetails.vue'
import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css';
export default {
    name : 'NewsletterStatistics',
    components : {
        StatisticsDetails,
        VueJsonPretty
    },
    mounted() {
        var t = this
        t.getData()
        setInterval(function() {
            if (t.obj !== null)
                t.getData()
        }, 120000)
    },
    computed : {
        obj() { return this.$parent.obj }
    },
    data() {
        return {
            notificationData: [],
            chartData: [{
                labels: [],
                datasets: []
            }],
            columns : [{
                title : this.transUF('email'),
                name: 'email',
                sortField: 'email',
                dataClass: 'clickable',
                cellClicked : function(t, data) {
                    window.axios.get(`/api/newsletters/${data.newsletter_id}/statistics/notification/${data.email}?type=all`)
                    .then(function(response) {
                        t.notificationData = response.data;
                        t.openNotificationsModal();
                    })
                },
            },{
                title : `<i class="fa fa-paper-plane" title="${this.transUF('delivered')}"></i>`,
                name: 'delivered',
                sortField: 'delivered',
                callback(data) { return data ? '<i class="fa fa-check"></i>' : ''; },
                dataClass: 'clickable',
                cellClicked : function(t, data) {
                    window.axios.get(`/api/newsletters/${data.newsletter_id}/statistics/notification/${data.email}?type=delivered`)
                    .then(function(response) {
                        t.notificationData = response.data;
                        t.openNotificationsModal();
                    })
                },
            },{
                title : `<i class="fa fa-envelope-open" title="${this.transUF('open')}"></i>`,
                name: 'open',
                sortField: 'open',
                callback(data) { return data ? '<i class="fa fa-check"></i>' : ''; },
                dataClass: 'clickable',
                cellClicked : function(t, data) {
                    window.axios.get(`/api/newsletters/${data.newsletter_id}/statistics/notification/${data.email}?type=open`)
                    .then(function(response) {
                        t.notificationData = response.data;
                        t.openNotificationsModal();
                    })
                },
            },{
                title : `<i class="fa fa-hand-pointer" title="${this.transUF('clicked')}"></i>`,
                name: 'clicked',
                sortField: 'clicked',
                callback(data) { return data ? '<i class="fa fa-check"></i>' : ''; },
                dataClass: 'clickable',
                cellClicked : function(t, data) {
                    window.axios.get(`/api/newsletters/${data.newsletter_id}/statistics/notification/${data.email}?type=clicked`)
                    .then(function(response) {
                        t.notificationData = response.data;
                        t.openNotificationsModal();
                    })
                },
            },{
                title : `<i class="fa fa-ban" title="${this.transUF('rejected')}"></i>`,
                name: 'rejected',
                sortField: 'rejected',
                callback(data) { return data ? '<i class="fa fa-check"></i>' : ''; },
                dataClass: 'clickable',
                cellClicked : function(t, data) {
                    window.axios.get(`/api/newsletters/${data.newsletter_id}/statistics/notification/${data.email}?type=rejected`)
                    .then(function(response) {
                        t.notificationData = response.data;
                        t.openNotificationsModal();
                    })
                },
            },{
                title : `<i class="fa fa-thumbs-down" title="${this.transUF('complained')}"></i>`,
                name: 'complained',
                sortField: 'complained',
                callback(data) { return data ? '<i class="fa fa-check"></i>' : ''; },
                dataClass: 'clickable',
                cellClicked : function(t, data) {
                    window.axios.get(`/api/newsletters/${data.newsletter_id}/statistics/notification/${data.email}?type=complained`)
                    .then(function(response) {
                        t.notificationData = response.data;
                        t.openNotificationsModal();
                    })
                },
            },{
                title : `<i class="fa fa-undo" title="${this.transUF('bounced')}"></i>`,
                name: 'bounced',
                sortField: 'bounced',
                callback(data) { return data ? '<i class="fa fa-check"></i>' : ''; },
                dataClass: 'clickable',
                cellClicked : function(t, data) {
                    window.axios.get(`/api/newsletters/${data.newsletter_id}/statistics/notification/${data.email}?type=bounced`)
                    .then(function(response) {
                        t.notificationData = response.data;
                        t.openNotificationsModal();
                    })
                },
            }],
        }
    },
    methods : {
        getData() {
            var t = this
            let addr = '/api/newsletters/'+t.obj.id+'/statistics';
            window.axios.get(addr)
            .then(function(response) {
                t.chartData = response.data
            })
        },
        percentage(val, total) {
			if (!isNaN(val/total))
				return ((val/total)*100).toFixed(2)+'%'
			return 100+'%'
        },
        openNotificationsModal() {
            this.$refs.showNotifications.show();
        },
    }
};
</script>