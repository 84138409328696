<template>
    <div>

        <object-topbar 
            :obj="obj" 
        >
        </object-topbar>

        <div class="row" v-if="locked && obj.sent === 1">
            <div class="col-md-12">
                <div class="card text-center">
                    <div class="card-header">
                        Statistiche di invio
                    </div>
                    <div class="card-block">
                        <div style="text-align:left">
                            <div v-if="obj.send_start !== null && obj.sent === 0 && obj.in_sending === 0" >
                                <b>Invio programmato per il {{ obj.send_start | moment('DD/MM/YY HH:mm') }} </b>
                            </div>
                            <div v-else-if="obj.sent === 0 && obj.in_sending === 1" >
                                <b>Invio in corso. Attendere.</b>
                                <in-sending-data :obj="obj"></in-sending-data>
                            </div>
                            <div v-else>
                                <b>Invio effettuato il {{ obj.send_start | moment('DD/MM/YY HH:mm') }}</b><br>
                                <b>Invio terminato il {{ obj.send_end | moment('DD/MM/YY HH:mm') }}</b>
                                <newsletter-statistics v-if="obj.sent === 1"></newsletter-statistics>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" v-if="!composer">

            <div class="col-md-6">

                <div class="card text-center">
                    
                    <div class="card-header">
                        Informazioni
                    </div>
                    <div class="card-block">

                        <div class="form-group row m-b-15">
                            <label class="col-form-label col-md-3">{{ 'name' | transUF }}</label>
                            <div class="col-md-9">
                                <input type="text" 
                                    class="form-control m-b-5" 
                                    name="name" 
                                    placeholder="Descrizione breve per riconoscere la lista"
                                    v-debounce:600ms="updateObj"
                                    v-model="obj.name" />
                            </div>
                        </div>

                        <div class="form-group row m-b-15">
                            <label class="col-form-label col-md-3">{{ 'creator' | transUF }}</label>
                            <div class="col-md-9">
                                <select 
                                    style="height:34px;"
                                    :disabled="locked"
                                    class="form-control" 
                                    name="user" 
                                    @change="updateObj"
                                    v-model="obj.user">
                                        <option v-for="(user, k) in users" 
                                            :value="user.name" 
                                            :key="user.name" 
                                            v-text="user.name" />
                                </select>
                            </div>
                        </div>

                        <div class="form-group row m-b-15">
                            <label class="col-form-label col-md-3">Driver</label>
                            <div class="col-md-9">
                                <select 
                                    :disabled="locked"
                                    style="height:34px;"
                                    class="form-control" 
                                    name="driver" 
                                    @change="updateObj"
                                    v-model="obj.driver">
                                        <option value="Sparkpost">Sparkpost</option>
                                        <option value="Amazon">Amazon</option>
                                </select>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="card text-center">
                    
                    <div class="card-header">
                        Corpo del messaggio
                    </div>
                    <div class="card-block">

                        <select 
                            :disabled="locked"
                            style="height:34px;"
                            class="form-control" 
                            v-model="obj.has_custom_html"
                            @change="updateObj"
                            >
                                <option value="0">Contenuto dell'email da compositore visivo</option>
                                <option value="1">Contenuto dell'email da HTML custom</option>
                        </select>
                        <br>

                        <div v-if="obj.has_custom_html == 1">
                            <div v-if="!locked">
                                <prism-editor v-model="obj.custom_html" 
                                language="html" :lineNumbers="true" :emitEvents="true" @change="saveCustomHtml" :autoStyleLineNumbers="true"></prism-editor>
                                <br>
                                <div style="text-align:right;">
                                    <button class="btn btn-default btn-sm" @click.stop="preview"><i class="fa fa-external-link-alt"></i> Preview</button>
                                </div>
                            </div>
                            <div v-else>
                                <pre style="height:300px; overflow-y:auto; text-align:left; padding-right: 10px;" v-text="obj.custom_html">
                                </pre>
                            </div>
                        </div>

                        <div v-else class="card pointer" @click="obj.sent === 0 ? $parent.composer=true : false" title="CLICCA PER MODIFICARE">
                            <div style="height: 200px;
                                position:relative;
                                background-position: top center;
                                background-repeat: no-repeat;
                                background-size: cover;
                                border: 1px solid #cec;
                                border-bottom: 0px" :style="{ 'background-image': 'url('+obj.picture+')' }">
                                <button class="btn btn-default btn-sm" style="position:absolute; right:10px; bottom:50px;"><i class="fa fa-pen"></i> Modifica</button>
                                <button class="btn btn-default btn-sm" style="position:absolute; right:10px; bottom:10px;" @click.stop="preview"><i class="fa fa-external-link-alt"></i> Preview</button>
                                </div>
                        </div>
                    </div>
                </div>

                <br>

                <div class="card text-center" v-if="!locked">
                    
                    <div class="card-header">
                        {{ 'sending tests' | transUF }}
                    </div>

                    <div class="card-block">
                        <div style="text-align:right">  

                            <hr>
                            <div class="row">
                                <div class="col-md-8">

                                    <Multiselect 
                                        :disabled="locked"
                                        v-model="testMailinglists"
                                        :options="mailinglists"
                                        label="name"
                                        track-by="id"
                                        :groupSelect="false"
                                        :close-on-select="true"
                                        multiple
                                    ></Multiselect>
                                    
                                </div>
                                <div class="col-md-4">
                                    <button class="btn btn-info btn-sm height36 width100" @click="sendTestLists">
                                        <i class="fa fa-check"></i> {{ 'test to lists' | transUC }}
                                    </button>
                                </div>
                            </div>

                            <hr>
                            
                            <div class="row">
                                <div class="col-md-8">

                                    <input type="text" 
                                        class="form-control"
                                        :disabled="locked"
                                        v-model="testEmails"
                                        placeholder="email@email.it" />
                                    
                                </div>
                                <div class="col-md-4">
                                    <button class="btn btn-success btn-sm height36 width100" @click="sendTestEmails">
                                        <i class="fa fa-check"></i> {{ 'test to email' | transUC }}
                                    </button>
                                </div>
                            </div>

                            <br>
                            <br>

                        </div>
                    </div>
                </div>


                <div class="card text-center" v-if="!locked">
                    
                    <div class="card-header">
                        {{ 'sending' | transUF }}
                    </div>

                    <div class="card-block">
                        <div style="text-align:right">  

                            <br>
                            <button class="btn btn-danger btn-sm height36 width100" @click="sendNow">
                                <i class="fa fa-paper-plane"></i> {{ 'send now to the selected lists' | transUC }}
                            </button>
                            <br>
                            <br>
                                
                            <hr>

                            <p style="text-align:left">{{ 'or' | transUC }}:</p>

                            <div class="row">
                                <div class="col-md-6">
                                    <date-picker 
                                        class="height36"
                                        v-model="obj.send_start" 
                                        :config="datepickerConfig">
                                    </date-picker>
                                </div>
                                <div class="col-md-6">
                                    <button class="btn btn-warning btn-sm height36 width100" v-text="transUC('confirm scheduling')" @click="schedule"></button>
                                </div>
                            </div>

                            <br>
                            <br>
                            <br>
                        
                        </div>
                    </div>
                </div>

                <div class="card text-center" v-else>
                    
                    <div class="card-header">
                        Stato dell'invio
                    </div>
                    
                    <div class="card-block">
                        <div style="text-align:left">
                            <div v-if="obj.send_start !== null && obj.sent === 0 && obj.in_sending === 0" >
                                <b>Invio programmato per il {{ obj.send_start | moment('DD/MM/YY HH:mm') }} </b>
                            </div>
                            <div v-else-if="obj.sent === 0 && obj.in_sending === 1" >
                                <b>Invio in corso. Attendere.</b>
                                <in-sending-data :obj="obj"></in-sending-data>
                            </div>
                            <div v-else>
                                <b>Invio effettuato il {{ obj.send_start | moment('DD/MM/YY HH:mm') }}</b><br>
                                <b>Invio confermato il {{ obj.send_end | moment('DD/MM/YY HH:mm') }}</b>
                            </div>
                        </div>
                    </div>

                </div>

                <!-- <div v-if="isAdmin" :disabled="!locked" style="padding-right:20px">
                    <br>
                    <br>
                    <br>
                    <button class="btn btn-danger btn-sm" @click="resendNow" style="float:right">
                        <i class="fa fa-paper-plane"></i> FORZA REINVIO
                    </button>   
                </div> -->

            </div>

            <div class="col-md-6">

                <div class="card text-center">
                    <div class="card-header">
                        Email
                    </div>
                    <div class="card-block">

                        <div class="form-group row m-b-15">
                            <label class="col-form-label col-md-3">{{ 'subject' | transUF }}</label>
                            <div class="col-md-9">
                                <input type="text" 
                                    :disabled="locked"
                                    class="form-control m-b-5" 
                                    name="subject" 
                                    placeholder="Oggetto del messaggio"
                                    v-debounce:600ms="updateObj"
                                    v-model="obj.subject" />
                            </div>
                        </div>

                        <div class="form-group row m-b-15">
                            <label class="col-form-label col-md-3">Nome mittente</label>
                            <div class="col-md-9">
                                <input type="text" 
                                    :disabled="locked"
                                    class="form-control m-b-5" 
                                    name="sender_name" 
                                    placeholder="Nome del mittente"
                                    v-debounce:600ms="updateObj"
                                    v-model="obj.sender_name" />
                            </div>
                        </div>

                        <div class="form-group row m-b-15">
                            <label class="col-form-label col-md-3">Email mittente</label>
                            <div class="col-md-9">
                                <input type="email" 
                                    :disabled="locked"
                                    class="form-control m-b-5" 
                                    name="sender_email" 
                                    placeholder="Email del mittente"
                                    v-debounce:600ms="updateObj"
                                    v-model="obj.sender_email" />
                                <span v-show="!allowedEmailsMessage" class="warning" style="    float: left;
                                    font-size: 0.9em;
                                    color: darkred;
                                    font-style: italic;
                                }"> dominio non abilitato all'invio dei messaggi. Utilizzare {{ sendingDomain }}</span>
                            </div>
                        </div>

                        <div class="form-group row m-b-15">
                            <label class="col-form-label col-md-3">
                                {{ 'reply to name' | transUF }}
                            </label>
                            <div class="col-md-9">
                                <input type="text" 
                                    :disabled="locked"
                                    class="form-control m-b-5" 
                                    name="reply_to_name" 
                                    placeholder="Nome del mittente"
                                    v-debounce:600ms="updateObj"
                                    v-model="obj.reply_to_name" />
                            </div>
                        </div>

                        <div class="form-group row m-b-15">
                            <label class="col-form-label col-md-3">
                                {{ 'reply to address' | transUF }}
                            </label>
                            <div class="col-md-9">
                                <input type="email" 
                                    :disabled="locked"
                                    class="form-control m-b-5" 
                                    name="reply_to_email" 
                                    placeholder="Email per risposta"
                                    v-debounce:600ms="updateObj"
                                    v-model="obj.reply_to_email" />
                            </div>
                        </div>

                        <input type="file" id="file" ref="file" v-on:change="handleFileUpload()" v-show="false" />

                        <div class="form-group row m-b-15">
                            <label class="col-form-label col-md-3">{{ 'attachments' | transUF }}</label>
                            <div class="col-md-9">
                                <table class="table table-striped table-condensed">
                                    <tr v-for="(attachment, k) in obj.attachments" :key="k">
                                        <td style="text-align:left">
                                            <input type="text" 
                                            :disabled="locked"
                                            class="form-control m-b-5" 
                                            v-debounce:600ms="updateAttachment(k)"
                                            v-model="attachment.name" />
                                        </td>
                                        <td style="width:100px; vertical-align:middle">
                                            <i v-text="formatBytes(attachment.hashed_size)"></i>
                                        </td>
                                        <td style="width:50px; text-align:right">
                                            <button type="button" class="btn btn-default btn-xs" @click="downloadAttachment(k)">
                                                <i class="fa fa-download"></i>
                                            </button>
                                            <button type="button" class="btn btn-default btn-xs" @click="removeAttachment(k)" v-show="obj.sent === 0" >
                                                <i class="fa fa-ban"></i>
                                            </button>
                                        </td>
                                    </tr>
                                    <tr><td colspan="2" style="text-align:left">
                                        <button type="button" class="btn btn-default btn-xs" @click="addAttachment" v-show="obj.sent === 0" >
                                            <i class="fa fa-plus"></i>
                                        </button>
                                    </td></tr>
                                    <tr><td colspan="2" style="text-align:right">
                                        Totale allegati: <b :class="(allAttachmentsSize > 10000000)? 'red' : 'green'">{{ formatBytes(allAttachmentsSize) }}</b>
                                        <br>
                                        Dimensioni massime consigliate: <b>8.5 MB</b>
                                    </td></tr>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="card text-center" v-show="showCustomers">
                    <div class="card-header">
                        {{ 'customers' | transUF }}
                    </div>
                    <div class="card-block">

                        <div class="form-group row m-b-15">
                            <label class="col-form-label col-md-3">{{ 'customers' | transUF }}</label>
                            <div class="col-md-9">
                                <Multiselect 
                                    :disabled="locked"
                                    v-model="obj.customers"
                                    :options="customers"
                                    label="name"
                                    track-by="id"
                                    :groupSelect="false"
                                    :close-on-select="true"
                                    multiple
                                    @select="updateCustomers"
                                    @remove="updateCustomers"
                                ></Multiselect>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="card text-center">
                    <div class="card-header">
                        {{ 'mailing list' | transUF }}
                    </div>
                    <div class="card-block">

                        <div class="form-group row m-b-15">
                            <label class="col-form-label col-md-3">{{ 'mailing lists' | transUW }}</label>
                            <div class="col-md-9">
                                <Multiselect 
                                    :disabled="locked"
                                    v-model="obj.mailinglists"
                                    :options="mailinglists"
                                    label="name"
                                    track-by="id"
                                    :groupSelect="false"
                                    :close-on-select="true"
                                    multiple
                                    @select="updateMailinglists"
                                    @remove="updateMailinglists"
                                ></Multiselect>
                            </div>
                        </div>

                        <div class="form-group row m-b-15">
                            <label class="col-form-label col-md-3">
                                
                            </label>
                            <div class="col-md-9" style="text-align:left; padding-top:7px">
                                <div>
                                    <span 
                                        style="font-size:1.2em; line-height:14px; width:80px; border: 1px solid #b73962; background-color: white; margin-right:8px;" 
                                        class="btn btn-default"
                                        >{{ journalists_count }}</span> <b>Persone</b> - previsione
                                </div>
                                <br>
                                <div>
                                    <span 
                                        style="font-size:1.2em; line-height:14px; width:80px; border: 1px solid #b73962; background-color: white; margin-right:8px;" 
                                        class="btn btn-default"
                                        >{{ emails_count }}</span> <b>Email</b> - previsione
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="card text-center">
                    <div class="card-header">
                        {{ 'additional addresses' | transUF }}
                    </div>
                    <div class="card-block">

                        <div class="form-group row m-b-15">
                            <label class="col-form-label col-md-3">
                                {{ 'to include' | transUF }}
                            </label>
                            <div class="col-md-9">
                                <textarea 
                                    :disabled="locked"
                                    class="form-control m-b-5" 
                                    name="added_emails" 
                                    placeholder="indirizzi separati da virgola"
                                    v-debounce:600ms="updateObj"
                                    v-model="obj.added_emails" />
                            </div>
                        </div>

                        <div class="form-group row m-b-15">
                            <label class="col-form-label col-md-3">
                                {{ 'to ignore' | transUF }}
                            </label>
                            <div class="col-md-9">
                                <textarea
                                    :disabled="locked"
                                    class="form-control m-b-5" 
                                    name="subtracted_emails" 
                                    placeholder="indirizzi separati da virgola"
                                    v-debounce:600ms="updateObj"
                                    v-model="obj.subtracted_emails" />
                            </div>
                        </div>

                    </div>
                </div>

                <div class="row">
                    <duplicator @duplicateObj="duplicateObj"></duplicator>
                </div>

            </div>
        </div>
        <div class="row" v-else>
            <newsletter-composer 
                @updateObj="updateObj"
                :objs="templates"
            >
            </newsletter-composer>
        </div>

        <b-modal id="sentListModal" 
            ref="sentListModal"
            size="lg" 
            :title="transUF('sent list')"
        >
            <sent-list :type="sentListType" >
            </sent-list>
        </b-modal>

    </div>
</template>

<script>
import debounce from "lodash/debounce"
import newsletterComposer from './Composer.vue'
import datePicker from 'vue-bootstrap-datetimepicker';
import newsletterStatistics from './show/Statistics.vue'
import SentList from './show/SentList.vue'
import InSendingData from './show/InSendingData.vue'
export default {
    name : 'NewsletterShow',
    props : [ 'templates' ],
    components : {
        newsletterComposer,
        newsletterStatistics,
        datePicker,
        SentList,
        InSendingData
    },
    mounted() {
        var t = this;
        if (process.env.MIX_SINGLE_CUSTOMER === 'true') {
            this.updateCustomers([{
                id: 1,
                name: 'BPRESS'
            }]);
        }
        this.updateCounts()
        setInterval(function() {
            if (t.obj !== null) {
                if (
                    t.obj.in_sending === 1 ||
                    (t.obj.send_start !== null && t.obj.sent === 0)
                ) {
                    window.axios.get('/api/newsletters/'+t.obj.id)
                    .then(function(response) {
                        if (response.data.sent === 1)
                            t.obj.sent = 1
                        t.obj.sendingQueues = []
                    })
                }
            }
        }, 5000)

        for (var i =0; i<this.mailinglists.length; i++) {
            if (this.mailinglists[i].name.toLowerCase() === 'test' ||
            this.mailinglists[i].name.toLowerCase() === 'invio test')
                this.testMailinglists.push(this.mailinglists[i])
        }
            
    },
    computed : {
        obj : { 
            set(val) { this.$parent.obj = val }, 
            get() { return this.$parent.obj } 
        },
        allAttachmentsSize() {
            return this.$parent.obj.attachments.reduce((a, b) => +a + +b.hashed_size, 0);
        },
        composer() { return this.$parent.composer },
        mailinglists() { return this.$parent.mailinglists },
        users() { return this.$parent.users },
        customers() { return this.$parent.customers },
        canSendTest() {

            var ok = this.notNullOrEmpty(this.obj.subject)
                && this.notNullOrEmpty(this.obj.sender_name)
                && this.notNullOrEmpty(this.obj.sender_email)
                && this.notNullOrEmpty(this.obj.reply_to_name)
                && this.notNullOrEmpty(this.obj.reply_to_email)

            if (!ok)
                return false
            
            if (this.obj.has_custom_html && (this.obj.custom_html === null || this.obj.custom_html.trim() === '' )) {
                return false;
            }

            if (!this.obj.has_custom_html && (this.obj.html === null || this.obj.html.trim() === '' )) {
                return false
            }

            if (process.env.MIX_APP_ENV !== 'local' && !this.obj.sender_email.includes(this.sendingDomain) ) {
                return false;
            }
            
            return true
        },
        allowedEmailsMessage() {
            if (process.env.MIX_APP_ENV !== 'local' && !this.obj.sender_email.includes(this.sendingDomain))
                return false
            return true
        },
        canSend() {
            if (!this.canSendTest)
                return false
            
            if (this.obj.mailinglists === null || this.obj.mailinglists === undefined || this.obj.mailinglists.length === 0) {
                return false
            }
            
            return true
        },
        locked() {
            return this.obj.sent === 1 || this.obj.in_sending === 1
        },
        send_start() { return this.obj.send_start },
        isAdmin() {
            return window.auth_user.email === 'giovanni@cellie.it'
        },
    },
    methods : {
        duplicateObj() {
            var t = this;
            window.axios.post('/api/newsletters/'+t.obj.id+'/duplicate')
            .then(function(response) {
                window.notify('success', 'Oggetto duplicato')
            })
        },
        updateCustomers(data) {
            this.obj.customers = data
            this.updateObj()
        },
        updateMailinglists(data) {
            this.obj.mailinglists = data
            this.updateObj()
        },
        notNullOrEmpty(smth) {
            return smth !== null && smth !== '' && smth !== 0
        },
        preview() {
            window.open(this.obj.picture)
        },
        trimString(string, length) {
            return string.length > length ? string.substring(0, length - 3) + "..." : string.substring(0, length);
        },
        updateObj() {
            this.$emit('updateObj')
            this.updateCounts()
        },
        setObj(val) {
            this.$emit('setObj', val);
        },
        deleteObj() {
            var t = this;
            if (t.obj.in_sending) {
                window.notify( 'error',  t.transUF('is not possible to delete the items now, please try again later') )
                return false;
            }
            window.axios.post('/api/newsletters/'+t.obj.id+'/delete')
            .then( function(){
                t.$emit('setObj',null)
            })
        },
        saveCustomHtml: debounce(function(){
            this.$emit('updateObj', this.obj)
        }, 600),
        updateAttachment(k) {
            return () => {
                var attachment = this.obj.attachments[k]
                var attachment_id = attachment.id
                window.axios.post('/api/attachments/'+attachment_id, attachment)
                window.notify('success', 'Allegato aggiornato')
            }
        },
        downloadAttachment(k) {
            window.open('/attachments/'+this.obj.attachments[k].id)
        },
        removeAttachment(k) {
            var attachment_id = this.obj.attachments[k].id
            window.axios.post('/api/attachments/'+ attachment_id +'/delete')
            this.obj.attachments.splice(k, 1);
            window.notify('success', 'Allegato eliminato')
        },
        addAttachment() {
            this.$refs.file.click()
        },
        handleFileUpload() {
            var t = this;
            var newsletter_id = t.obj.id
            
            t.file = t.$refs.file.files[0]
            let formData = new FormData()
            formData.append('file', t.file)
            formData.append('newsletter_id', newsletter_id)

            window.axios.post( '/api/attachments/create',
                formData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                }
            ).then(function(response){
                if (response.data === 'KO EXTENSION')
                    window.notify('error', 'Non è possibile allegare files con questa estensione')
                else if (response.data === 'KO SIZE')
                    window.notify('error', 'Hai superato il limite di upload per questo invio')
                else {
                    t.obj.attachments.push(response.data)
                    window.notify('success', 'Allegato caricato')
                }
            })
        },
        updateCounts() {
            var t = this;
            setTimeout(function() {
                window.axios.get('/api/newsletters/'+t.obj.id+'/counts')
                .then(function(response) {
                    t.journalists_count = response.data.journalists_count
                    t.emails_count = response.data.emails_count
                })
            }, 500)
        },
        sendTestLists() {
            if (!this.canSendTest) {
                window.notify('error', 'Compilare prima tutti i campi inclusi il contenuto e la lista')
                return false
            }
            var t = this;
            window.axios.post('/api/newsletters/'+t.obj.id+'/test', {
                'mailinglists' : t.testMailinglists
            }).then(function(response) {
                if (response.data === 'KO BIG')
                    window.notify('error', 'Lista troppo grossa per essere utilizzata come test.')
                else if (response.data === 'KO SIZE') {
                    window.notify("error", 'Dimensioni della newsletter troppo grandi. Provare a ridurre gli allegati prima di reinviare.');
                }
                else if (response.data === 'KO')
                    window.notify('error', 'Errore generico nella consegna delle email')
                else if (response.data === 'OK')
                    window.notify('success', 'Email di test inviata/e')
            })
        },
        sendTestEmails() {
            if (!this.canSendTest) {
                window.notify('error', 'Compilare prima tutti i campi inclusi il contenuto e la lista')
                return false
            }
            var t = this;
            window.axios.post('/api/newsletters/'+t.obj.id+'/test', {
                'emails' : t.testEmails
            }).then(function(response) {
                if (response.data === 'KO BIG')
                    window.notify('error', 'Lista troppo grossa per essere utilizzata come test.')
                else if (response.data === 'KO SIZE') {
                    window.notify("error", 'Dimensioni della newsletter troppo grandi. Provare a ridurre gli allegati prima di reinviare.');
                }
                else if (response.data === 'KO')
                    window.notify('error', 'Errore generico nella consegna delle email')
                else if (response.data === 'OK')
                    window.notify('success', 'Email di test inviata.')
            })
        },
        sendNow() {
            var t = this;
            if (!this.canSend) {
                window.notify('error', 'Invio disabilitato: compilare prima tutti i campi inclusi il contenuto e la lista')
                return false
            }
            window.axios.post('/api/newsletters/'+t.obj.id+'/send')
            .then(function(response) {
                if (response.data === 'KO SIZE') {
                    window.notify("error", 'Dimensioni della newsletter troppo grandi. Provare a ridurre gli allegati prima di reinviare.');
                }
                else  if (response.data === 'KO')
                    window.notify('error', 'Non è possibile inviare: compilare prima tutti i campi inclusi il contenuto e la lista')
                else
                    t.$emit('setObj',response.data)
            })
        },
        schedule() {
            var t = this
            if (!this.canSend) {
                window.notify('error', 'Invio disabilitato: compilare prima tutti i campi inclusi il contenuto e la lista')
                return false;
            }
            if (this.obj.send_start === null)
                return false
            window.axios.post('/api/newsletters/'+t.obj.id+'/schedule', {
                date : t.obj.send_start
            })
            .then(function(response) {
                if (response.data === 'KO SIZE') {
                    t.obj.send_start = null;
                    window.notify("error", 'Dimensioni della newsletter troppo grandi. Provare a ridurre gli allegati prima di reinviare.');
                    return;
                }
                else if (response.data === 'KO')
                    t.obj.send_start = null
                else {
                    t.obj.send_start = response.data
                    window.notify("success", "Invio rischedulato per la data indicata")
                }
            })
        },
        formatBytes(bytes, decimals = 2) {
            if (bytes === 0) return '0 Bytes';
            const k = 1024;
            const dm = decimals < 0 ? 0 : decimals;
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
            const i = Math.floor(Math.log(bytes) / Math.log(k));
            return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
        }
    },
    data(){
        return {
            showCustomers: process.env.MIX_SINGLE_CUSTOMER === 'false',
            sendingDomain : window.__START.sendingDomain,
            testMailinglists : [],
            testEmails : window.auth_user.email,
            sentListType : 'sent[p]',
            journalists_count : 0,
            emails_count : 0,
            datepickerConfig : {
                format: 'YYYY-MM-DD HH:mm:ss',
                useCurrent: false,
                showClear: true,
                showClose: true,
                locale: window.locale,
            }
        }
    },
    watch : {
        send_start() {
            if (!this.canSend && this.obj.send_start !== null) {
                window.notify("error", "Compila tutti i campi prima di schedulare un invio")
            } /* else if (this.canSend && this.obj.send_start !== null) {
                window.notify("info", "Clicca su "+this.transUC('schedule to be sent')+" per confermare la data di invio")
            } */
        }
    }
}
</script>

<style scoped>
select.form-control {
    height:34px!important;
}
select.to-check {
    border:1px solid rgba(206, 0, 91, 0.66)
}
.green {
    color: green;
}
.red {
    color: red;
}
</style>
<style>
div.prism-editor-wrapper {
    height: 300px;
    overflow-y: auto;
}
div.prism-editor-wrapper * {
    font-size:13px!important;
}
.height36 {
    height:36px!important;
}
.width100 {
    width:100%;
}
</style>