<template>
    <div class="tab-pane fade" v-bind:class="{ 'show active': this.$props.tabs.pictures }">
        <h4 class="m-t-0 m-b-20">{{ 'pictures' | transUC }}</h4>

            <div class="row row-space-2">
                <div
                class="col-lg-1 col-sm-2 col-3"
                v-for="(image , k) in images"
                v-bind:key="k"
                v-tooltip="image.name"
                @click="$emit('setAvatar', image.url)"
                >
                    <div class="widget-card widget-card-rounded square m-b-2">
                        <div
                        class="widget-card-cover"
                        :style="{'background-image' : `url('${image.url}')` }"
                        :class="{ 'current-avatar' : (obj.avatar === image.url) }"
                        ></div>
                    </div>
                </div>

                <div
                class="col-lg-1 col-sm-2 col-3"
                @click="$emit('setAvatar', '/images/avatar/'+obj.gender+'.png')"
                >
                <div class="widget-card widget-card-rounded square m-b-2">
                    <div
                    class="widget-card-cover"
                    :style="{'background-image' : 'url(/images/avatar/'+obj.gender+'.png)' }"
                    :class="{ 'current-avatar' : (obj.avatar === '/images/avatar/'+obj.gender+'.png') }"
                    ></div>
                </div>
            </div>
        </div>
    </div>   
</template>

<script>
export default {
    name : 'Pictures',
    props : [ 'tabs', 'obj', 'images' ],
    
}
</script>