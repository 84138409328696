<template>
    <div>
        
        <object-topbar 
            :obj="obj" 
        >
        </object-topbar>

        <div class="profile">
            <div class="profile-header">
                <div class="profile-header-cover"></div>
                <div class="profile-header-content">
                <div class="profile-header-img">
                    <div
                    style="width:113px; height:113px; background-size:cover; background-position:top"
                    :style="{ backgroundImage : 'url('+avatar+')' }"
                    >
                    <button
                        type="button"
                        class="btn btn-primary btn-rounded btn-cancel"
                        v-show="toSetAvatar"
                        @click="cancelAvatarChange"
                    >
                        <i class="fa fa-ban"></i>
                    </button>
                    <button
                        type="button"
                        class="btn btn-primary btn-rounded btn-confirm"
                        v-show="toSetAvatar"
                        @click="confirmAvatarChange"
                    >
                        <i class="fa fa-check"></i>
                    </button>
                    </div>
                </div>
                <div class="profile-header-info">
                    <h4 class="m-t-10 m-b-5" v-text="obj.companyname"></h4>
                    <p class="m-b-10">
                        <br>
                    </p>
                </div>
                </div>
                <ul class="profile-header-tab nav nav-tabs">
                <li class="nav-item" v-for="(bool, tab) in tabs" v-bind:key="tab">
                    <a
                    href="javascript:;"
                    v-on:click="showTab(tab)"
                    v-bind:class="{ 'active': bool }"
                    class="nav-link"
                    data-toggle="tab"
                    >{{ tab | transUC }}</a>
                </li>
                </ul>
            </div>
            </div>
            <div class="profile-content">
            <div class="tab-content p-0">
                
                <About 
                    :tabs="tabs"
                    :obj="obj"
                    :provinces="provinces"
                    :regions="regions"
                    :countries="countries"
                    @updateObj="updateObj"
                ></About>

                <editorials-list 
                    :tabs="tabs"
                    :obj="obj"
                    @updateObj="updateObj"
                ></editorials-list>

                <journalists-list 
                    :tabs="tabs"
                    :obj="obj"
                    @updateObj="updateObj"
                ></journalists-list>

                <!-- <Pictures
                    :tabs="tabs"
                    :obj="obj"
                    :images="images"
                    @updateObj="updateObj"
                    @setAvatar="setAvatar"
                ></Pictures> -->

                <!-- <Web
                    :tabs="tabs"
                    :obj="obj"
                    :webPages="webPages"
                    @updateObj="updateObj"
                ></Web> -->
                
            </div>
        </div>
    </div>
</template>
<script>
import About from './show/About.vue'
import EditorialsList from './show/EditorialsList.vue'
import JournalistsList from './show/JournalistsList.vue'
// import Web from './show/Web.vue'
// import Pictures from './show/Pictures.vue'

const imageExists = require("image-exists");
export default {
    name: 'PublisherShow',
    props: [ 'provinces', 'regions', 'countries' ],
    components : {
        About,
        History,
        EditorialsList,
        JournalistsList
        // Web,
        // Pictures,
    },
    mounted() {
        // this.bingSearchImages( 
        //     this.obj.companyname, 
        //     this.pushToImages
        // ),
        // this.bingSearchWeb( 
        //     this.obj.companyname, 
        //     this.pushToWebPages
        // ) 
    },
    computed : {
        obj() { return this.$parent.obj },
        avatar() { return this.obj.avatar }
    },
    data() {
        return {
            images: [],
            webPages: [],
            toSetAvatar: false,
            tabs: {
                about: true,
                newspapers : false,
                journalists : false
                // web: false,
                // pictures : false,
            }
        };
    },
    methods: {
        setObj(data) {
            this.$parent.setObj(data)
        },
        updateObj(data) {
            this.$parent.updateObj(data)
        },
        deleteObj() {
            this.$parent.deleteObj()
        },
        pushToImages(images) {
            var t = this;
            images.forEach(function(im) {
                var image = {
                    url: im.thumbnailUrl,
                    name: im.name
                };
                imageExists(image.url, function(exists) {
                    if (exists) {
                        var can = true;
                        t.images.forEach(function(existent) {
                            if (existent.url == image.url) can = false;
                        });
                        if (can) t.images.push(image)
                    }
                });
            });
        },
        pushToWebPages(pages) {
            this.webPages = pages
        },
        setAvatar(imageUrl) {
            this.avatar = imageUrl;
            if (this.obj.avatar !== this.avatar)
                this.toSetAvatar = true
        },
        confirmAvatarChange() {
            this.$emit('updateObj', { avatar : this.avatar })
            this.toSetAvatar = false
        },
        cancelAvatarChange() {
            this.avatar = this.obj.avatar;
            this.toSetAvatar = false
        },
        showTab(x) {
            var t = this;
            Object.keys(t.tabs).map(function(key) {
                t.tabs[key] = false
            });
            t.tabs[x] = true;
        }
    }
};
</script>