<template>
    <g-vue-table
        ref="deletableVueTable"
        :apiIndexUrl="'/api/attributes/'+attribute"
        sortBy="name"
        :columns="columns"
        @newObj="$emit('newObj')"
        :actionsDropdown="actionsDropdown"
        ></g-vue-table>
</template>
<script>
export default {
    props : [ 'attribute' ],
    methods : {
        refresh() {
            this.$refs.deletableVueTable.refresh()
        }
    },
    computed : {
        actionsDropdown() {
            var t = this;
            return [{
                name : this.transUC('delete'),
                clicked : function(ids) {
                    t.$parent.$refs.deleteModal.show( ids );
                }
            }]
        }
    },
    data() {
        return {
            columns : [{
                name: '__checkbox',
                titleClass: 'text-center short-cell',
                dataClass: 'text-center short-cell',
                visible : true,
            },{
                title: '#',
                name: 'id',
                sortField: 'id',
                titleClass: 'text-right medium-cell',
                dataClass: 'text-right medium-cell',
                visible : true,
            },{
                title : this.transUC('name'),
                name: 'name',
                sortField: 'name',
                dataClass: '',
                visible : true,
            },/*{
                title : this.transUC('journalists'),
                name: 'journalists_count',
                sortField: 'journalists_count',
                dataClass: '',
                visible : true,
            },{
                title : this.transUC('newspapers'),
                name: 'editorials_count',
                sortField: 'editorials_count',
                dataClass: '',
                visible : true,
            },*/{
                title : this.transUC('updated'),
                name: 'updated_at',
                sortField: 'updated_at',
                titleClass: 'text-center updated-at-cell',
                dataClass: 'text-right updated-at-cell',
                visible : true
            }]
        }
    }
}

</script>
