<template>
    <div class="pagination-selector" style="float:left;">
        <select class="form-control" style="max-width:70px; height:38px;" v-model="perPage">
            <option v-for="(i,k) in [ 10, 15, 20, 30, 40, 50, 100 ]" :key="k" v-text="i" :value="i"></option>
        </select>
    </div>
</template>
<script>
export default {
    name : 'PaginatorSelector',
    computed : {
        perPage : {
            get() { return this.$parent.perPageTemp },
            set(data) { this.$parent.perPageTemp = data }
        }
    }
}
</script>