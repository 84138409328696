<template>
	<div class="row">
        <div class="col-12">
            <g-vue-table
            ref="bulkDeleteBbbbModal"
            :apiIndexUrl="'/api/customers/'+$parent.obj.id+'/blacklists'"
            sortBy="email"
            :columns="columns"
            :obj="null"
            @newObj="showAddBlacklistModal"
            :filtered="false"
            :actionsDropdown="actionsDropdown"
            ></g-vue-table>

            <bulk-delete-modal 
                ref="bulkDeleteModal" 
                :domain="'customers/'+$parent.obj.id+'/blacklists'"
                deletableRef="bulkDeleteBbbbModal"
            ></bulk-delete-modal>
            
        </div>
        <b-modal id="addBlacklistModal" 
            ref="addBlacklistModal"
            size="md" 
            :title="transUF('add in blacklist')"
            @ok="addBlacklist"
            :ok-disabled="newBlacklisted === ''"
        >
            <p style="text-align:left" v-text="transUF('email address')"></p>
            <input type="text" v-model="newBlacklisted" class="form-control" />
        </b-modal>
    </div>
</template>

<script>
export default {
    name : 'BlacklistsList',
    methods : {
        showAddBlacklistModal() {
            this.$refs.addBlacklistModal.show()
        },
        addBlacklist() {
            var t = this
            if (t.newBlacklisted !== '') {
                window.axios.post('/api/customers/'+t.obj.id+'/blacklisted', {
                    email : t.newBlacklisted
                })
                .then(function(response) {
                    if (response.data === 'OK')
                        window.notify('success', 'Email aggiunta alla blacklist');
                    else if (response.data === 'NOTHING')
                        window.notify('alert', 'Nessun giornalista corrispondente');
                    t.newBlacklisted = ''
                })
            }
        }
    },
    computed : {
        obj() { return this.$parent.obj },
        actionsDropdown() {
            var t = this;
            return [{
                name : this.transUC('delete'),
                clicked : function(ids) {
                    t.$refs.bulkDeleteModal.show( ids );
                }
            }]
        }
    },
    data() {
        return {
            newBlacklisted : '',
            columns : [{
                name: '__checkbox',
                titleClass: 'text-center short-cell',
                dataClass: 'text-center short-cell',
            },{
                title : '#',
                name: 'id',
                sortField: 'id',
                dataClass: 'clickable',
                cellClicked : function(t, data) {
                    window.open('/#/journalists/'+data.journalist.id)
                },
                visible : true,
            },{
                title : this.transUC('email'),
                name: 'email',
                sortField: 'email',
                dataClass: 'clickable',
                cellClicked : function(t, data) {
                    window.open('/#/journalists/'+data.journalist.id)
                },
                callback : function(data) {
                    if (data === null)
                        return null
                    data = (new String(data)).split(',')
                    var result = [];
                    for (var i=0;i<data.length;i++) {
                        result.push( '<a href="mailto:'+data[i]+'">'+data[i]+'</a>' )
                    }
                    return result.join(' + ')
                },
                visible : true,
            },{
                title : this.transUC('updated'),
                name: 'updated_at',
                sortField: 'updated_at',
                visible : true,
            }],
        }
    }
}
</script>