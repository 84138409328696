<template>
	<div>
        <g-vue-table
            v-if="filterId !== null"
            :apiIndexUrl="'/api/mailinglists/'+this.obj.id+'/filters/individuals?filterid='+filterId"
            sortBy="id"
            sortDirection="asc"
            :columns="columns"
            ref="FilterIndividualsTable"
            :obj="null"
            :creable="false"
        ></g-vue-table>
	</div>
</template>

<script>
export default {
    name : 'FilterIndividuals',
    mounted() {

    },
    methods : {
        toggleAction(action) {
            /* [
                toggled : !data.selected,
                id : [data.id]
            ] */
            if (this.filterId === null) return true;

            this.obj.filters[this.filterId]['ids'] = 
                Object.values(this.obj.filters[this.filterId]['ids'])

            if (action.toggled)
                this.obj.filters[this.filterId]['ids'].push(action.id)
            else {
                for(var i=0;i < this.obj.filters[this.filterId]['ids'].length; i++) 
                    if ( this.obj.filters[this.filterId]['ids'][i] === action.id)
                        this.obj.filters[this.filterId]['ids'].splice(i, 1); 
            }

            this.$parent.$parent.updateObj()
        }
    },
    computed : {
        actionsDropdown() {
            var t = this;
            return [{
                name : this.transUC('delete'),
                clicked : function(ids) {
                    t.$refs.bulkDeleteModal.show( ids );
                }
            }]
        },
        filter: {
            get() {
                return this.$parent.$parent.currentFilter
            },
            set(thisVal) {
                this.$parent.$parent.currentFilter = thisVal
            }
        },
        filterId() {
            return this.$parent.$parent.currentFilterId
        },
        filteredIds() {
            return this.obj.filters[this.filterId]['ids'];
        },
        obj : {
            get() { return this.$parent.$parent.obj },
            set(data) { this.$parent.$parent.obj = data }
        }
    },
    data(){
        return {
            columns : [{
                title : '<i class="fa fa-check"></i>',
                name: 'checked',
                // sortField: 'selected',
                dataClass: 'clickable',
                visible : true,
                callback : function(data) {
                    return data ? '<i class="fa fa-check"></i>' : ''
                },
                cellClicked : function(t, data) {
                    t.toggleAction({
                        toggled : !data.selected,
                        id : data.id
                    })
                },
            },{
                title : '#',
                name: 'id',
                sortField: 'id',
                dataClass: '',
                visible : true,
            },{
                title : this.transUC('name'),
                name: '_companyname',
                sortField: '_companyname',
                dataClass: '',
                visible : true,
            },{
                title : this.transUC('email'),
                name: '_emails',
                sortField: '_emails',
                dataClass: '',
                visible : true,
                callback : function(data) {
                    if (data === null)
                        return null
                    data = (new String(data)).split(',')
                    var result = [];
                    for (var i=0;i<data.length;i++) {
                        result.push( '<a href="mailto:'+data[i]+'">'+data[i]+'</a>' )
                    }
                    return result.join(' + ')
                }
            },{
                title : this.transUC('newspapers'),
                name: '_editorials',
                sortField: '_editorials',
                visible : true,
                callback : function(data) {
                    if (data === null)
                        return null
                    data = (new String(data)).split('||')
                    var result = [];
                    for (var i=0;i<data.length;i++) {
                        var pattern = /\[(.*?)\]/g;
                        var match;
                        while ((match = pattern.exec(data[i])) != null) {
                            // id = match[1]
                            var str = '<a href="/#/editorials/'+match[1]+'" target="_blank">'+data[i].replace(match[0], '')+'</a>'
                            result.push( str )
                        }
                    }
                    return result.join(' + ')
                }
            }],
        }
    }
}
</script>