<template>
    <div>

        <object-topbar 
            :obj="obj" 
        >
        </object-topbar>

        <div class="profile">
            <div class="profile-header">
                <div class="profile-header-cover"></div>
                <div class="profile-header-content">
                <div class="profile-header-img">
                    <div
                    style="width:113px; height:113px; background-size:cover; background-position:top"
                    :style="{ backgroundImage : 'url('+avatar+')' }"
                    >
                        <!-- <button
                            type="button"
                            class="btn btn-primary btn-rounded btn-cancel"
                            v-show="toSetAvatar"
                            @click="cancelAvatarChange"
                        >
                            <i class="fa fa-ban"></i>
                        </button>
                        <button
                            type="button"
                            class="btn btn-primary btn-rounded btn-confirm"
                            v-show="toSetAvatar"
                            @click="confirmAvatarChange"
                        >
                            <i class="fa fa-check"></i>
                        </button> -->
                    </div>
                </div>
                <div class="profile-header-info">
                    <h4 class="m-t-10 m-b-5" 
                    v-text="(obj.first_name !== null ? obj.first_name : '')+' '+(obj.last_name !== null ? obj.last_name : '')"></h4>
                    <p class="m-b-10">
                    <span
                        v-for="(office) in obj.offices"
                        v-bind:key="office.name"
                        v-text="' - '+office.name"
                    ></span>
                    <span
                        v-for="(editorial) in obj.editorials"
                        v-bind:key="editorial.id"
                        v-text="' - '+editorial.companyname"
                        @click="redirect('/editorials/'+editorial.id)"
                        style="cursor:pointer;"
                    ></span>
                    </p>
                </div>
                </div>
                <ul class="profile-header-tab nav nav-tabs">
                <li class="nav-item" v-for="(bool, tab) in tabs" v-bind:key="tab">
                    <a
                    href="javascript:;"
                    v-on:click="showTab(tab)"
                    v-bind:class="{ 'active': bool }"
                    class="nav-link"
                    data-toggle="tab"
                    >{{ tab | transUC }}</a>
                </li>
                </ul>
            </div>
            </div>
            <div class="profile-content">
            <div class="tab-content p-0">
                
                <about 
                    :tabs="tabs"
                    :obj="obj"
                    :provinces="provinces"
                    :regions="regions"
                    :countries="countries"
                    :nameHeaders="nameHeaders"
                    @updateObj="updateObj"
                ></about>

                <!-- <Pictures
                    :tabs="tabs"
                    :obj="obj"
                    :images="images"
                    @updateObj="updateObj"
                    @setAvatar="setAvatar"
                ></Pictures>    -->
                
                <!-- <Web
                    :tabs="tabs"
                    :obj="obj"
                    :webPages="webPages"
                    @updateObj="updateObj"
                ></Web> -->
                
                <relations
                    :tabs="tabs"
                    :obj="obj"
                    @updateObj="updateObj"
                ></relations>

                <history
                    :tabs="tabs"
                    :obj="obj"
                    @updateObj="updateObj"
                ></history> 

                <mailinglists
                    :tabs="tabs"
                    :obj="obj"
                    @updateObj="updateObj"
                    :mailinglists="mailinglists"
                ></mailinglists> 

                <blacklists
                    :tabs="tabs"
                    :obj="obj"
                    @updateObj="updateObj"
                    :customers="customers"
                ></blacklists> 

            </div>
        </div>
    </div>
</template>
<script>
import About from './show/About.vue'
import Relations from './show/Relations.vue'
import History from './show/History.vue'
import Mailinglists from './show/Mailinglists.vue'
import Blacklists from './show/Blacklists.vue'
// import Pictures from './show/Pictures.vue'
// import Web from './show/Web.vue'

const imageExists = require("image-exists");
export default {
    name: 'JournalistShow',
    props: [ 'provinces', 'regions', 'countries', 'nameHeaders', 'mailinglists', 'customers' ],
    components : {
        About,
        Relations,
        History,
        Mailinglists,
        Blacklists,
        // Pictures,
        // Web,
    },
    mounted() {
        this.getModifications()
        // this.bingSearchImages( 
        //     this.obj.first_name+' '+this.obj.last_name, 
        //     this.pushToImages
        // )
        // this.bingSearchWeb( 
        //     this.obj.first_name+' '+this.obj.last_name, 
        //     this.pushToWebPages
        // ) 
    },
    computed : {
        obj: {
            get() { return this.$parent.obj },
            set(val) { this.$parent.obj = val }
        },
        // avatar : { 
        //     get() { return this.$parent.obj.avatar },
        //     set(val) { this.$parent.obj = val }
        // }
        avatar() {
            return this.$parent.obj.gender === 'f' ? '/images/avatar/f.png' : '/images/avatar/m.png'
        }
    },
    data() {
        return {
            hideInJournalists: process.env.MIX_HIDE_IN_JOURNALISTS ? process.env.MIX_HIDE_IN_JOURNALISTS.split('|') : [],
            showAgency: process.env.MIX_JOURNALIST_SHOW_AGENCY,
            showLocale: process.env.MIX_JOURNALIST_SHOW_LOCALE,
            showType: process.env.MIX_JOURNALIST_SHOW_TYPE,
            modifications: [],
            images: [],
            webPages: [],
            toSetAvatar: false,
            tabs: {
                about: true,
                relations: false,
                history: false,
                mailinglists: false,
                blacklists: false,
                // web: false,
                // pictures: false,
            }
        };
    },
    methods: {
        redirect(url) {
            if ( this.$route.path !== url )
                this.$router.push({ path: url })
        },
        setObj(data) {
            this.$parent.setObj(data)
        },
        deleteObj() {
            this.$parent.deleteObj()
        },
        updateObj(data) {
            var t = this;
            // false = get back unmodified data from Server
            if (data === false) {
                window.axios.get('/api/journalists/'+t.obj.id)
                .then( function(response){
                    t.setObj(response.data)
                })
            } else {
                t.obj = Object.assign({}, t.obj, data)
                if ( Array.isArray(t.obj.email) ) {
                    t.obj.email.forEach((value,key) => {
                        if (value.indexOf(',') !== -1) {
                            t.obj.email = [ ...t.obj.email, ...value.split(',') ]
                            t.obj.email.splice(key, 1)
                        }
                        if (value.indexOf(';') !== -1) {
                            t.obj.email = [ ...t.obj.email, ...value.split(';') ]
                            t.obj.email.splice(key, 1)
                        }
                    })
                } else {
                    t.obj.email = t.obj.email.split(',')
                }
                t.obj.email = t.obj.email.map(s => s.trim());
                window.axios.post("/api/journalists/" + t.obj.id, t.obj)
                .then(function() {
                    t.getModifications()
                    window.notify('success', t.transUF('record updated') )
                })
            }
        },
        getModifications() {
            var t = this;
            window.axios.get('/api/journalists/'+t.obj.id+'/modifications')
            .then(function(response){
                t.modifications = response.data
            });
        },
        pushToImages(images) {
            var t = this;
            images.forEach(function(im) {
                var image = {
                    url: im.thumbnailUrl,
                    name: im.name
                };
                imageExists(image.url, function(exists) {
                    if (exists) {
                        var can = true;
                        t.images.forEach(function(existent) {
                            if (existent.url == image.url) can = false;
                        });
                        if (can) t.images.push(image)
                    }
                });
            });
        },
        pushToWebPages(pages) {
            this.webPages = pages
        },
        // setAvatar(imageUrl) {
        //     this.avatar = imageUrl;
        //     if (this.obj.avatar !== this.avatar)
        //         this.toSetAvatar = true
        // },
        // confirmAvatarChange() {
        //     this.$emit('updateObj', { avatar : this.avatar })
        //     this.toSetAvatar = false
        // },
        // cancelAvatarChange() {
        //     this.avatar = this.obj.avatar;
        //     this.toSetAvatar = false
        // },
        showTab(x) {
            var t = this;
            Object.keys(t.tabs).map(function(key) {
                t.tabs[key] = false
            });
            t.tabs[x] = true;
        }
    }
};
</script>

<style scoped>
    .profile-header-img .btn-cancel {
    color: green;
    position: absolute;
    left: 4px;
    bottom: 4px;
    background-color: white;
    border: 1px solid lightgrey;
    }

    .profile-header-img .btn-confirm {
    color: red;
    position: absolute;
    right: 4px;
    bottom: 4px;
    background-color: white;
    border: 1px solid lightgrey;
    }

    .current-avatar {
    border: 2px solid #c30057;
    }
</style>