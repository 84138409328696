<template>

    <div class="col-12 duplicator-container">
        <button 
            v-if="level === 0 | level === 2"
            type="button" 
            class="btn btn-warning btn-xs" 
            @click="level = 1"
        ><i class="fa fa-copy"></i>  {{ 'duplicate record' | transUF }}</button>
        <div style="font-size:1em; max-width:300px; float:right" v-if="level === 1" >
            <i>Sei sicuro? Questa azione creerà una copia di questo oggetto con tutte le relative associazioni. <br>Clicca "Conferma" per procedere.</i>
            <br><br>
            <button
                type="button"
                class="btn btn-default"
                @click="level--"
            ><i class="fa fa-undo"></i> {{ 'cancel' | transUF }}</button>
            <button
                type="button"
                class="btn btn-danger"
                @click="level++"
            ><i class="fa fa-copy"></i> Conferma</button>
        </div>
    </div>

</template>
<script>
export default {
    name: 'Duplicator',
    data() {
        return {
            level : 0
        }
    },
    watch : {
        level(val) {
            if (val === 2)
                this.$emit('duplicateObj')
        }
    }
}
</script>