<template>
    <b-dropdown variant="link" menu-class="dropdown-menu-right">
        <template slot="button-content">
            <div class="image image-icon bg-black text-grey-darker" >
                <img :src="this.$root.auth_user.img" />
            </div>
            <span class="d-none d-md-inline" v-text="this.$root.auth_user.name">
            </span>
            <b class="caret"></b>
        </template>
        <!-- <b-dropdown-divider></b-dropdown-divider> -->
        <b-dropdown-item href="/logout">
            {{ 'log out' | transUF }}
        </b-dropdown-item>
    </b-dropdown>
</template>

<script>
export default {
    name : 'UserMenu'
}
</script>