<template>
	<div style="width:100%; overflow-x:auto">
        <g-vue-table
        apiIndexUrl="/api/imports/medias/files"
        sortBy="name"
        sortDirection="asc"
        :filtered="false"
        :paginated="false"
        :searchable="false"
        :creable="false"
        :columns="columns"
        ></g-vue-table>
	</div>
</template>

<script>
export default {
    name : 'ImportsList',
    methods : {
    },
    data(){
        return {
            columns : [{
                title : this.transUC('name'),
                name: 'fileName',
                visible : true,
                dataClass: 'clickable',
                cellClicked : function(t, data) {
                    window.open(data.downloadPath)
                },
            },{
                title : 'FILESIZE',
                name: 'fileSize',
                visible : true,
                dataClass: 'clickable',
                cellClicked : function(t, data) {
                    window.open(data.downloadPath)
                },
            },{
                title : this.transUC('date'),
                name: 'date',
                visible : true,
            }],
        }
    }
}
</script>