<template>
    <div class="tab-pane fade" v-bind:class="{ 'show active': this.$props.tabs.about }">
        <div class="table-responsive">
            <table class="table table-profile">
                <thead>
                    <tr>
                        <th class="align-right">
                        </th>
                        <th>
                            <h4 style="margin-bottom:0px">

                                <span id="companyname" v-text="obj.companyname"></span>

                                <b-button size="xs" v-b-modal.jsonmodal class="btn-default"><i class="fa fa-code"></i></b-button>
                                &nbsp;
                                <b-button size="xs" v-b-modal.historymodal class="btn-default"><i class="fa fa-history"></i></b-button>
                            </h4>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="field valign-middle">
                            <i class="fa fa-user fa-sm m-r-5"></i> {{ 'name' | transUW }}
                        </td>
                        <td>
                            <b-input type="text" autocomplete="off" 
                                name="companyname"
                                v-model="obj.companyname" 
                                class="input-sm"
                                style="display:inline; max-width:300px"
                                v-debounce:700ms="updateObj"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td class="field valign-middle">
                            <i class="fa fa-envelope fa-sm m-r-5"></i> Email
                        </td>
                        <td>
                            <b-input type="text" autocomplete="off" 
                                name="email"
                                v-model="obj.email" 
                                class="input-sm"
                                style="display:inline; max-width:300px"
                                v-debounce:700ms="updateObj"
                            />
                        </td>
                    </tr>
                    <tr class="divider">
                        <td colspan="2"></td>
                    </tr>
                    <tr>
                        <td class="field valign-middle">
                            <i class="fa fa-phone fa-sm m-r-5"></i> {{ 'telephone' | transUW }}
                        </td>
                        <td>
                            <b-input type="text" autocomplete="off" 
                                name="telephone"
                                v-model="obj.telephone" 
                                class="input-sm"
                                style="display:inline; max-width:300px"
                                v-debounce:700ms="updateObj"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td class="field valign-middle">
                            <i class="fa fa-mobile fa-sm m-r-5"></i> {{ 'mobile' | transUW }}
                        </td>
                        <td>
                            <b-input type="text" autocomplete="off" 
                                name="mobile"
                                v-model="obj.mobile" 
                                class="input-sm"
                                style="display:inline; max-width:300px"
                                v-debounce:700ms="updateObj"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td class="field valign-middle">
                            <i class="fa fa-fax fa-sm m-r-5"></i> {{ 'fax' | transUW }}
                        </td>
                        <td>
                            <b-input type="text" autocomplete="off"
                                 name="fax"
                                v-model="obj.fax" 
                                class="input-sm"
                                style="display:inline; max-width:300px"
                                v-debounce:700ms="updateObj"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td class="field valign-middle">
                            <i class="fa fa-globe fa-sm m-r-5"></i> {{ 'website' | transUW }}
                        </td>
                        <td>
                            <b-input type="text" autocomplete="off" 
                                name="internet"
                                v-model="obj.internet" 
                                class="input-sm"
                                style="display:inline; max-width:300px"
                                v-debounce:700ms="updateObj"
                            />
                        </td>
                    </tr>
                    <tr class="divider">
                        <td colspan="2"></td>
                    </tr>
                    <tr>
                        <td class="field valign-middle">
                            {{ 'address' | transUW }}
                        </td>
                        <td>
                            <b-input type="text" autocomplete="off" 
                                name="address"
                                v-model="obj.address" 
                                class="input-sm"
                                style="display:inline; max-width:300px"
                                v-debounce:700ms="updateObj"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td class="field valign-middle">
                            {{ 'postcode' | transUW }}
                        </td>
                        <td>
                            <b-input type="text" autocomplete="off" 
                                name="postcode"
                                v-model="obj.postcode" 
                                class="input-sm"
                                style="display:inline; max-width:300px"
                                v-debounce:700ms="updateObj"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td class="field valign-middle">
                            {{ 'city' | transUW }}
                        </td>
                        <td>
                            <b-input type="text" autocomplete="off" 
                                name="city"
                                v-model="obj.city" 
                                class="input-sm"
                                style="display:inline; max-width:300px"
                                v-debounce:700ms="updateObj"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td class="field valign-middle">
                            {{ 'province' | transUW }}
                        </td>
                        <td>
                            <select class="form-control input-inline input-sm" v-model="obj.province_id" ref="province_id" @change="updateObj">
                                <option value="null" disabled selected>Seleziona</option>
                                <option v-for="province in provinces" v-bind:key="province.name" v-bind:value="province.id">
                                    {{ province.name }}
                                </option>
                            </select>
                            <br>
                        </td>
                    </tr>
                    <tr>
                        <td class="field valign-middle">
                            {{ 'country' | transUW }}
                        </td>
                        <td>
                            <select class="form-control input-inline input-sm" v-model="obj.country_id" @change="updateObj">
                                <option value="null" disabled selected>Seleziona</option>
                                <option v-for="country in countries" v-bind:key="country.name" v-bind:value="country.id">
                                    {{ country['name_'+locale] }}
                                </option>
                            </select>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <b-modal id="jsonmodal" 
            size="lg" 
            title="Realtime DB Record"
            ok-only
        >
                <vue-json-pretty
                :path="'res'"
                :data="obj">
                </vue-json-pretty>
        </b-modal>
        <b-modal id="historymodal" 
            size="lg" 
            :title="transUF('modifications history')"
            ok-only
        >
            <table class="table table-sm">
                <thead>
                    <tr>
                        <th>{{ 'date' | trans }}</th>
                        <th>{{ 'user' | trans }}</th>
                        <th>{{ 'field' | trans }}</th>
                        <th>{{ 'before' | trans }}</th>
                        <th style="width:20px;"></th>
                        <th>{{ 'after' | trans }}</th>
                        <th></th>
                    </tr>
                </thead>
                <tr v-for="(modification, k) in obj.modifications" :key="k">
                    <td>{{ modification.created_at | moment('DD/MM/YY HH:mm') }}</td>
                    <td>{{ modification.user }}</td>
                    <td><b>{{ modification.attribute }}</b></td>
                    <td>
                        <span style="opacity:0.7" v-html="$options.filters.modificationUrl(modification.before)" />
                    </td>
                    <td style="width:20px;">></td>
                    <td>
                        <span v-html="$options.filters.modificationUrl(modification.after)" />
                    </td>
                    <td>
                        <b-button size="xs"
                        @click="$emit('updateObj', { [modification.attribute] : modification.before } );"
                        :title="transUC('restore')+' '+modification.attribute+' > '+modification.before"
                        ><i class="fa fa-history"></i></b-button>
                        &nbsp;
                        <b-button class="btn-danger" size="xs"
                        @click="deleteModification(modification.id)"
                        :title="transUC('delete')"
                        ><i class="fa fa-trash"></i></b-button>
                    </td>
                </tr>
            </table>
        </b-modal>

    </div>
</template>
<script>
import VueJsonPretty from 'vue-json-pretty'
export default {
    name : 'About',
    props : [ 'tabs', 'provinces', 'regions', 'countries' ],
    components : {
        VueJsonPretty
    },
    computed : {
        obj() { return this.$parent.obj },
        locale() { return window.locale }
    },
    data() {
        return {
            inputVisible : {
                first_name : false,
                last_name :false,
                telephone : false,
                fax :false,
                email :false,
                internet:false,
                postcode:false,
                city:false,
                province:false,
                country:false,
                
            }
        }
    },
    methods: {
        showInputVisible(name) {
            this.inputVisible[name] = true;
        },
        hideInputVisible(name) {
            this.inputVisible[name] = false;
        },
        updateObj() {
            this.$emit('updateObj')
        },
        restoreObj: function(event, element) {
            var name = element.name;
            this.hideInputVisible(name);
            this.$emit('updateObj', false)
        },
        deleteModification(modification_id) {
            var t = this;
            window.axios.post('/api/modifications/'+modification_id+'/delete', {})
            .then(function() {
                for(var key in t.obj.modifications) {
                    if ( t.obj.modifications[key]['id'] == modification_id) {
                        t.$delete(t.obj.modifications, key)
                    }
                }
            })
        }
    },
    filters : {
        modificationUrl : function(string) {
            if (string === null)
                string = ''
            if(! (string.includes('http') || string.includes('/images/')) )
                return string;
            if (string.length > 16)
                return '<a href="'+string+'" target="_blank">'+
                string.substring(0,16)+
                '...'+
                '</a>';
            return string;
        }
    }
}
</script>