<template>
    <b-dropdown :text="'Filtra colonne'" variant="none">
        <b-dropdown-item 
            v-for="(column, k) in columns.filter(function(o) {
                return ['__checkbox', '__sequence'].indexOf(o.name) === -1
            })"
            :key="k"
            @click="toggleVisible(column)"
        >{{ column.title !== undefined ? column.title : column.name }}</b-dropdown-item>
    </b-dropdown>
</template>
<script>
export default {
    name : 'ColumnFilter',
    computed: {
        columns: {
            get() { return this.$parent.columns},
            set(val) { this.$parent.columns = val }
        }
    },
    methods: {
        toggleVisible(column) {
            column.visible = !column.visible
            this.$parent.$refs.vuetable.normalizeFields()
        }
    }
}
</script>
<style scoped>
.filter-visible,
.filter-invisible:hover {
    color: #000;
}
.filter-invisible,
.filter-visible:hover {
    color: #909090;
}
</style>