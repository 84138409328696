<template>
    <div class="pagination-selector" style="float:right;">
        <b-dropdown :text="transUF('actions')" variant="none">
            <b-dropdown-item 
                v-for="(action, k) in actionsDropdown" :key="k"
                @click="action.clicked(checked)"
            >{{ action.name }}</b-dropdown-item>
        </b-dropdown>
    </div>
</template>
<script>
export default {
    name : 'Actions',
    props : [ 'actionsDropdown', 'checked' ]
}
</script>