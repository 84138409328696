<template>
    <div class="tab-pane fade" v-bind:class="{ 'show active': this.$props.tabs.web }">
        <h4 class="m-t-0 m-b-20">{{ 'found online' | transUF }}:</h4>
        <div class="row row-space-2">
            <div class="col-md-12 m-b-2">
                <div class="p-10 bg-white">
                    <div class="search-result" v-for="(page, k) in webPages" v-bind:key="k">
                        <h6 class="search-title">
                            <a :href="page.url" target="_blank">{{ page.name }}</a>
                        </h6>
                        <p>
                            {{ page.snippet }}
                            <br>
                            <a :href="page.url" target="_blank">{{ page.displayUrl }}</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name : 'Web',
    props : [ 'tabs', 'obj', 'webPages' ],
    
}
</script>