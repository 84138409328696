<template>
	<div>

        <g-vue-table
        ref="deletableVueTable"
        :apiIndexUrl="'/api/sending-denieds/'"
        :sortBy="'email'"
        :columns="columns"
        @newObj="showAddBlacklistModal"
        :actionsDropdown="actionsDropdown"
        ></g-vue-table>

        <b-modal id="addBlacklistModal" 
            ref="addBlacklistModal"
            size="md" 
            :title="transUF('add in blacklist')+' GDPR'"
            @ok="addBlacklist"
            :ok-disabled="newBlacklisted === ''"
        >
            <p style="text-align:left" v-text="transUF('email address')"></p>
            <input type="text" v-model="newBlacklisted" class="form-control" />
        </b-modal>

        <bulk-delete-modal 
            ref="bulkDeleteModal" 
            domain="sending-denieds"
            deletableRef="deletableVueTable"
        ></bulk-delete-modal>
        
	</div>
</template>

<script>
export default {
    name : 'SendingDenied',
    computed : {
        actionsDropdown() {
            var t = this;
            return [{
                name : this.transUC('delete'),
                clicked : function(ids) {
                    t.$refs.bulkDeleteModal.show( ids );
                }
            }]
        }
    },
    methods : {
        showAddBlacklistModal() {
            this.$refs.addBlacklistModal.show()
        },
        addBlacklist() {
            var t = this
            if (t.newBlacklisted !== '') {
                window.axios.post('/api/sending-denieds/add-blacklisted', {
                    email : t.newBlacklisted
                })
                .then(function(response) {
                    if (response.data === 'OK')
                        window.notify('success', 'Email aggiunta alla blacklist GDPR');
                    else if (response.data === 'NOTHING')
                        window.notify('alert', 'Nessun giornalista corrispondente');
                    t.newBlacklisted = ''
                })
            }
        }
    },
    data(){
        return {
            newBlacklisted : '',
            columns : [{
                name: '__checkbox',
                titleClass: 'text-center short-cell',
                dataClass: 'text-center short-cell',
                visible : true,
            },{
                title: '#',
                name: 'id',
                sortField: 'id',
                visible : true,
            },{
                title : this.transUC('email'),
                name: 'email',
                sortField: 'email',
                dataClass: '',
                visible : true,
            },{
                title : this.transUC('inserted on'),
                name: 'created_at',
                sortField: 'created_at',
                dataClass: '',
                visible : true,
            }],
        }
    }
}
</script>