<template>
	<div style="width:100%; overflow-x:auto">
        <br>
        <h5> {{ modificationsCount }} Modifiche da controllare.</h5>
        <br>
        <g-vue-table
            ref="modificationsTable"
            apiIndexUrl="/api/imports/medias/modifications"
            sortBy="attribute"
            sortDirection="asc"
            :columns="columns"
            :obj="null"
            :creable="false"
            :searchable="true"
            :actionsDropdown="actionsDropdown"
        ></g-vue-table>
    </div>
</template>

<script>
export default {
    name : 'ImportsModifications',
    mounted() {
        var t = this;
        window.axios.get('/api/imports/medias/modifications/count')
        .then(function(response) {
            t.modificationsCount = response.data
        })
    },
    computed : {
        actionsDropdown() {
            var t = this;
            return [{
                name : this.transUC('confirm'),
                clicked : function(ids) {
                    t.confirmRows(ids)
                }
            },{
                name : this.transUC('ignore'),
                clicked : function(ids) {
                    t.ignoreRows(ids)
                }
            }]
        }
    },
    methods : {
        ignoreRows(ids) {
            var t = this;
            window.axios.post('/api/imports/medias/modifications/ignore', {
                'ids' : ids
            })
            .then(function() {
                t.$refs['modificationsTable'].refresh()
                t.modificationsCount = t.modificationsCount-ids.length
                window.notify('info', 'Modifica ignorata', 600)
            })
        },
        confirmRows(ids) {
            var t = this;
            window.axios.post('/api/imports/medias/modifications/confirm', {
                'ids' : ids
            })
            .then(function() {
                t.$refs['modificationsTable'].refresh()
                t.modificationsCount = t.modificationsCount-ids.length
                window.notify('success', 'Modifica accettata', 600)
            })
        },
        openWindow(mod) {
            if (mod.model === 'App\\Models\\Journalist')
                window.open('/#/journalists/'+mod.model_id)
            if (mod.model === 'App\\Models\\Editorial')
                window.open('/#/editorials/'+mod.model_id)
            if (mod.model === 'App\\Models\\Publisher')
                window.open('/#/publishers/'+mod.model_id)
        }
    },
    data() {
        return {
            modificationsCount : 0,
            columns : [{
                name: '__checkbox',
                titleClass: 'text-center short-cell',
                dataClass: 'text-center short-cell',
                visible : true,
            },{
                title: '#',
                name: 'id',
                sortField: 'id',
                titleClass: 'text-right medium-cell',
                dataClass: 'text-right medium-cell clickable',
                visible : true,
            },{
                title : this.transUC('model'),
                name: 'model',
                sortField: 'model',
                visible : true,
                callback(data) {
                    var name = data.replace('App\\Models\\', '').toLowerCase()
                    if (name === 'editorial')
                        name = 'newspaper'
                    return window.vm.transUF(name.toLowerCase())
                },
                // cellClicked : function(t, data) {
                //     if (!t.$root.env_local) {
                //         var arr = []; arr.push(data.id)
                //         t.confirmRows(arr)
                //     }
                // },
            },{
                title : 'Mod#',
                name: 'model_id',
                sortField: 'model_id',
                visible : true,
                // cellClicked : function(t, data) {
                //     if (!t.$root.env_local) {
                //         var arr = []; arr.push(data.id)
                //         t.confirmRows(arr)
                //     }
                // },
            },{
                title : this.transUC('name'),
                name: 'name',
                sortField: 'name',
                visible : true,
                // cellClicked : function(t, data) {
                //     if (!t.$root.env_local) {
                //         var arr = []; arr.push(data.id)
                //         t.confirmRows(arr)
                //     }
                // },
            },{
                title : this.transUC('what'),
                name : 'attribute',
                sortField : 'attribute',
                visible : true,
                callback(data) {
                    return window.vm.transUF(data.toLowerCase())
                }
            },{
                title : this.transUC('before'),
                name: 'before',
                sortField: 'before',
                visible : true,
                // cellClicked : function(t, data) {
                //     if (!t.$root.env_local) {
                //         var arr = []; arr.push(data.id)
                //         t.confirmRows(arr)
                //     }
                // },
            },{
                title : this.transUC('after'),
                name: 'after',
                sortField: 'after',
                visible : true,
                // cellClicked : function(t, data) {
                //     if (!t.$root.env_local) {
                //         var arr = []; arr.push(data.id)
                //         t.confirmRows(arr)
                //     }
                // },
            },{
                title : '',
                name: 'confirm',
                visible : true,
                dataClass: 'clickable',
                callback() {
                    return '<i class="fa fa-check" style="color:darkgreen"></i>'
                },
                cellClicked : function(t, data) {
                    var arr = []; arr.push(data.id)
                    t.confirmRows(arr)
                },
            },{
                title : '',
                name: 'delete',
                visible : true,
                dataClass: 'clickable',
                callback() {
                    return '<i class="fa fa-trash" style="color:darkred"></i>'
                },
                cellClicked : function(t, data) {
                    var arr = []; arr.push(data.id)
                    t.ignoreRows(arr)
                },
            }],
        }
    }
}
</script>