<script>
import Vue from 'vue'
require('../../plugins/vue-trans.js')
var tr = new Vue({})
/*
    use it like:
    trans._('hello world')
*/

/*
    Elements:
    - path
    - icon
    - title
    - children
    - badge
*/
const MainMenu = [
    {
        title: tr.transUF('home'),
        path: "/",
        route : "/",
        icon: "fa fa-th",
        blank : false,
    },
    { 
        title: tr.transUF('journalists'), 
        path: "/journalists", 
        route : "/journalists",
        icon: "fa fa-user", 
        blank : false,
        // children: [
        //     { 
        //         title: tr.trans('GDPR deleted'), 
        //         path: "/gdpr-deleted"
        //         blank : false,
        //     }
        // ]
        // badge: "10",
    },
    { 
        title: tr.transUF('newspapers'), 
        path: "/editorials", 
        route : "/editorials",
        icon: "fa fa-newspaper", 
        blank : false,
        // badge: "10",
    },
    { 
        title: tr.transUF('publishers'), 
        path: "/publishers", 
        route : "/publishers",
        icon: "fa fa-university", 
        blank : false,
        hide: process.env.MIX_SHOW_PUBLISHERS === 'false',
        // badge: "10",
    },
    // { 
    //     title: tr.transUF('attributes'), 
    //     path: "/attributes", 
    //     icon: "fa fa-code-branch", 
    //     blank : false,
    //     // badge: "10",
    // },
    { 
        title: tr.transUF('mailing lists'), 
        path: "/mailinglists", 
        route : "/mailinglists",
        icon: "fa fa-table", 
        blank : false,
        // badge: "10",
    },
    { 
        title: tr.transUF('newsletters'), 
        path: "/newsletters", 
        route : "/newsletters",
        icon: "fa fa-envelope", 
        blank : false,
        // badge: "10",
    }, { 
        title: tr.transUF('customers'), 
        path: "/customers", 
        route : "/customers",
        icon: "fa fa-handshake", 
        blank : false,
        // badge: "10",
    },
    { 
        title: tr.transUF('templates'), 
        path: "/templates", 
        route : "/templates",
        icon: "fa fa-pen", 
        blank : false,
        // badge: "10",
    },
    { 
        title: tr.transUF('file manager'), 
        path: "/public/media?page=login&action=login&nonajax=1&username="+process.env.MIX_FILERUN_USER+"&password="+process.env.MIX_FILERUN_PASS,
        icon: "fa fa-file-image", 
        blank : true,
        // badge: "10",
    },
    { 
        title: 'DB', 
        path: "/imports/medias",
        route : "/imports/medias",
        icon: "fa fa-database", 
        blank : false,
        children: [
            { 
                title: tr.transUF('imports'), 
                path: "/imports/medias",
                route : "/imports/medias",
                blank : false,
            },
            { 
                title: tr.transUF('attributes'), 
                path: "/attributes",
                route : "/attributes",
                blank : false,
            },
            { 
                title: tr.transUF('duplicates'), 
                path: "/journalists?duplicates=true",
                route : "/journalists-duplicates",
                blank : false,
            },
            { 
                title: tr.transUF('indicators'), 
                path: "/indicators",
                route : "/indicators",
                blank : false,
            },
            { 
                title: 'Account Blacklist', 
                path: "/email-verifications",
                route : "/email-verifications",
                blank : false,
            },
            { 
                title: 'Blacklist Logs', 
                path: "/email-verification-logs",
                route : "/email-verification-logs",
                blank : false,
            }, 
        ]
        // badge: "10",
    },
    { 
        title: tr.transUF('services'), 
        path: "/services", 
        route: "/services", 
        icon: "fa fa-cogs", 
        blank : false,
        // badge: "10",
    }
    /*
    { 
        title: "Menu Level",
        path: "/menu", 
        icon: "fa fa-align-left", 
        blank : false,
        children: [
            { 
                title: "Menu 1.1",
                path: "/menu/menu-1-1", 
                blank : false,
                children: [
                    { 
                        title: "Menu 2.1",
                        path: "/menu/menu-1-1/menu-2-1", 
                        blank : false,
                        children: [
                            { 
                                title: "Menu 3.1",
                                path: "/menu/menu-1-1/menu-2-1/menu-3-1"
                                blank : false,
                            },
                            { 
                                title: "Menu 3.2",
                                path: "/menu/menu-1-1/menu-2-1/menu-3-2"
                                blank : false,
                            }
                        ]
                    },
                    { 
                        title: "Menu 2.2",
                        path: "/menu/menu-1-1/menu-2-2"
                        blank : false,
                    },
                    { 
                        title: "Menu 2.3",
                        path: "/menu/menu-1-1/menu-2-3"
                        blank : false,
                    }
                ]
            }
        ]
    }
    */
]

export default MainMenu;
</script>
