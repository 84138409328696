// php artisan lang:js --quiet --no-lib

// use this like a Vue filter:
// <div>{{ 'login' | transUW }}</div>

import Vue from 'vue'
import translations from './../../../lang/translations.js';

var Lang = require('lang.js');
var lang = new Lang({
    messages : translations,
    locale : window.locale,
    fallback : 'en'
});
// '...args' allows us to use any number of parameters in our strings
Vue.filter('trans', (word) => {
    return lang.get( 'all.'+word );
});
Vue.filter('transUF', (word) => {
    var trans = lang.get( 'all.'+word );
    return trans.charAt(0).toUpperCase() + trans.slice(1);
});
Vue.filter('transUC', (word) => {
    return lang.get( 'all.'+word ).toUpperCase();
});
Vue.filter('transUW', (word) => {
    return lang.get( 'all.'+word ).toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
});

Vue.mixin({
    methods: {
        trans: function (...args) {
            return this.$options.filters.trans(...args)
        },
        transUF: function (...args) {
            return this.$options.filters.transUF(...args)
        },
        transUC: function (...args) {
            return this.$options.filters.transUC(...args)
        },
        transUW: function (...args) {
            return this.$options.filters.transUW(...args)
        }
    }
})