<template>
    <div class="col-12">
        <div>
            <button 
                style="float:left"
                @click="$parent.$parent.composer = false" 
                class="btn btn-xs btn-default m-r-5">
                <i class="fa fa-arrow-left"></i> Torna all'invio
            </button>

            <button 
                style="float:right"
                @click="$refs.showTemplates.show()" 
                class="btn btn-xs btn-info m-r-5">
                <i class="fa fa-image"></i> Mostra i templates
            </button>
            <br>
            <br>
            Hai a disposizione i seguenti shortcode: <code>[[NOME]]</code>, <code>[[COGNOME]]</code>, <code>[[UNSUBSCRIBE]]</code>.
            <br>Per lasciare scegliere a Mediabooster una parola in base al Genere, definire le opzioni con questa sintassi (femminile|maschile|neutro o non disponibile): <code>[[GEN]]Cara|Caro|Car*[[/GEN]]</code>
            <br>
            <br>
            <div v-if="embeddingTemplates">
                <b-modal id="showTemplates" 
                    ref="showTemplates"
                    title="Templates"
                    @setTemplate="$emit('setTemplate')"
                    size="xl"
                    hide-footer >

                    <p>
                        <i>Attenzione: cliccando su un template, questo sarà applicato al contenuto della email ed il contenuto precedente verrà sovrascritto.</i>
                    </p>
                    <templates-grid 
                    @setObj="setObj"
                    ></templates-grid>
                    
                </b-modal>
            </div>
        </div>
        <div v-if="error">
            <p>Errore nel comunicare con il server Bee Free per il compositore visivo. Riprovare più tardi. Se l'errore persiste contattare l'assistenza.</p>
        </div>
        <div v-else id="bee-plugin-container" style="width:100%; height:100vh;">
            <div v-for="(beePlugin, k) in beePlugins" :key="k" 
                v-show="k === beePlugins.length-1"
                class="bee-plugin"
                :id="'bee-plugin-'+String(k)" />
        </div>
        <b-modal id="sendModal" 
            ref="sendModal"
            :title="transUF('send test email')"
            @ok="sendTestEmail"
        >
            <label>Inserisci qui gli indirizzi email che riceveranno il messaggio di prova, separati da virgola</label>
            <b-input type="text" v-model="testEmail.emails"  />
        </b-modal>
    </div>
</template>

<script>
import Bee from 'bee-plugin'
import TemplatesGrid from './../templates/Grid.vue'
export default {
    name : 'NewsletterComposer',
    props : [ 'objs' ],
    components : {
        TemplatesGrid
    },
    mounted() {
        this.reloadBee()
    },
    computed : {
        obj: { 
            get() { return this.$parent.obj  },
            set(data) { this.$parent.obj = data }
        },
        testEmail() {
            return {
                emails : window.auth_user.email,
                content : this.obj.html
            }
        }
    },
    data() {
        return  {
            error : false,
            beePlugins : [],
            beeConfig : {
                uid: 'bpress-demo',
                container: 'bee-plugin',
                autosave: 0,
                language: window.locale === 'it' ? 'it-IT' : 'en-EN',
                onSave: (jsonFile, htmlFile) => {
                    this.BeeOnSave(jsonFile, htmlFile)
                },
                onAutoSave: (jsonFile) => {
                    // console.log('onAutoSave', jsonFile)
                },
                onSaveAsTemplate: (jsonFile) => {
                    this.BeeOnSaveAsTemplate(jsonFile)
                    // console.log('onSaveAsTemplate', jsonFile)
                },
                onSend: (htmlFile) => {
                    this.BeeOnSend(htmlFile)
                    // console.log('onSend', htmlFile)
                },
                onError: (errorMessage) => {
                    console.log('onError ', errorMessage)
                }
            },
            embeddingTemplates : true
        }
    },
    methods : {
        reloadBee(forcedTemplate = null) {
            this.beePlugins.push('new')
            var t = this
            var json = forcedTemplate === null ? JSON.parse(t.obj.json) : JSON.parse(forcedTemplate)
            window.axios.get('/api/newsletters/bee-token')
            .then(function(response) {
                if (response === 'KO') {
                    t.error = true
                }
                else {
                    window.BEE = new Bee(response.data)
                    t.beeConfig.container = 'bee-plugin-'+String(t.beePlugins.length-1)
                    window.BEE.start(t.beeConfig, json )
                }
            })
            // .then((bee) => bee.load(t.template.content))
        },
        updateObj() {
            this.$emit('updateObj')
        },
        BeeOnSave(jsonFile, htmlFile) {
            this.obj.json = jsonFile
            this.obj.html = htmlFile
            this.$emit('updateObj')
        },
        BeeOnSaveAsTemplate(jsonFile) {
            var t = this
            window.axios.post('/api/templates/create', {
                'name' : 'Nuovo template',
                'json' : jsonFile,
                'html' : t.obj.html,
            }).then(function() {
                window.notify('success', 'È stato creato un nuovo template')
            })
        },
        BeeOnSend(htmlFile) {
            this.testEmail.content = htmlFile
            this.$refs.sendModal.show()
        },
        sendTestEmail() {
            var t = this;
            window.axios.post('/api/newsletters/'+t.obj.id+'/test', {
                'emails' : t.testEmail.emails,
                'html' : t.testEmail.content
            }).then(function() {
                window.notify('success', 'Email di prova inviata')
            })
        },
        setObj(template) {
            var t = this;
            t.obj.json = template.json
            t.obj.html = template.html
            // t.$emit('updateObj', {
            //     json : template.json,
            //     html : template.html
            // })
            t.$refs.showTemplates.hide()
            t.reloadBee(template.json)
        }
    }
}
</script>

<style scoped>
    .bee-plugin {
        height:100vh;
    }
</style>