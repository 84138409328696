import VueRouter from 'vue-router';

// import Login from './pages/Login.vue'
// import Unsubscribe from './pages/Unsubscribe.vue'
import Dashboard from './pages/Dashboard.vue'
import Services from './pages/Services.vue'
import Journalists from './pages/Journalists.vue'
import Publishers from './pages/Publishers.vue'
import Editorials from './pages/Editorials.vue'
import Attributes from './pages/Attributes.vue'
import Templates from './pages/Templates.vue'
import Newsletters from './pages/Newsletters.vue'
import Mailinglists from './pages/Mailinglists.vue'
import Customers from './pages/Customers.vue'
import Imports from './pages/Imports.vue'
import Indicators from './pages/Indicators.vue'
import SendingDenied from './pages/SendingDenied.vue'
import EmailVerifications from './pages/EmailVerifications.vue'
import EmailVerificationLogs from './pages/EmailVerificationLogs.vue'

let routes = [
    {
        path: '/',
        component: Dashboard,
    },
    {
        path: '/attributes',
        component: Attributes,
    },
    {
        path: '/customers',
        component: Customers,
    },
    {
        path: '/customers/:id',
        component: Customers,
    },
    {
        path: '/editorials',
        component: Editorials,
    },
    {
        path: '/editorials/:id',
        component: Editorials,
    },
    {
        path: '/indicators',
        component: Indicators,
    },
    {
        path: '/imports/medias',
        component: Imports,
    },
    {
        path: '/journalists',
        component: Journalists,
    },
    {
        path: '/journalists/:id',
        component: Journalists,
    },
    {
        path: '/mailinglists',
        component: Mailinglists,
    },
    {
        path: '/mailinglists/:id',
        component: Mailinglists,
    },
    {
        path: '/newsletters',
        component: Newsletters,
    },
    {
        path: '/newsletters/:id',
        component: Newsletters,
    },
    {
        path: '/publishers',
        component: Publishers,
    },
    {
        path: '/publishers/:id',
        component: Publishers,
    },
    {
        path: '/sending-denied',
        component: SendingDenied,
    },
    {
        path: '/gdpr-denieds',
        component: SendingDenied,
    },
    {
        path: '/gdpr',
        component: SendingDenied,
    },
    {
        path: '/journalists-duplicates',
        component: Journalists,
    },
    {
        path: '/journalists-duplicates/:id',
        component: Journalists,
    },
    {
        path: '/email-verifications',
        component: EmailVerifications,
    },
    {
        path: '/email-verification-logs',
        component: EmailVerificationLogs,
    },
    {
        path: '/unsubscribed',
        component: Journalists,
    },
    {
        path: '/gdpr-denieds',
        component: Journalists,
    },
    {
        path: '/gdpr',
        component: Journalists,
    },
    {
        path: '/services',
        component: Services,
    },
    {
        path: '/templates',
        component: Templates,
    },
    {
        path: '/templates/:id',
        component: Templates,
    },
]

export default new VueRouter({
    routes,
    linkActiveClass: 'is-active',
})