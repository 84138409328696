<template>
	<div>
        <g-vue-table
        :apiIndexUrl="'/api/mailinglists/'+this.obj.id+'/filters/journalists?filterid='+filterId"
        sortBy="id"
        sortDirection="asc"
        :columns="columns"
        ref="FilterJournalistsTable"
        :obj="null"
        :creable="false"
        ></g-vue-table>
	</div>
</template>

<script>
export default {
    name : 'FilterJournalists',
    mounted() {
        // console.log(this.$refs.FilterJournalistsTable)
        // this.$refs.FilterJournalistsTable.selectedTo[x]
    },
    methods : {

    },
    computed : {
        filter() {
            return this.$parent.$parent.currentFilter
        },
        filterId() {
            return this.$parent.$parent.currentFilterId
        },
        obj() {
            return this.$parent.obj
        }
    },
    data(){
        return {
            columns : [{
                title : '#',
                name: 'id',
                sortField: 'id',
                dataClass: '',
                visible : true,
            },{
                title : this.transUC('name'),
                name: '_companyname',
                sortField: '_companyname',
                dataClass: '',
                visible : true,
            },{
                title : this.transUC('email'),
                name: '_emails',
                sortField: '_emails',
                dataClass: '',
                visible : true,
                callback : function(data) {
                    if (data === null)
                        return null
                    data = (new String(data)).split(',')
                    var result = [];
                    for (var i=0;i<data.length;i++) {
                        result.push( '<a href="mailto:'+data[i]+'">'+data[i]+'</a>' )
                    }
                    return result.join(' + ')
                }
            },{
                title : this.transUC('newspapers'),
                name: '_editorials',
                sortField: '_editorials',
                visible : true,
                callback : function(data) {
                    if (data === null)
                        return null
                    data = (new String(data)).split('||')
                    var result = [];
                    for (var i=0;i<data.length;i++) {
                        var pattern = /\[(.*?)\]/g;
                        var match;
                        while ((match = pattern.exec(data[i])) != null) {
                            // id = match[1]
                            var str = '<a href="/#/editorials/'+match[1]+'" target="_blank">'+data[i].replace(match[0], '')+'</a>'
                            result.push( str )
                        }
                    }
                    return result.join(' + ')
                }
            }],
        }
    }
}
</script>