<template>
	<div>

        <newsletters-list 
            v-if="obj === null && !$route.params.id" 
            :obj="obj"
            @setObj="setObj"
            @newObj="newObj"
            ref="NewslettersList"
        ></newsletters-list>
        <newsletter-show
            v-else-if="obj" 
            @setObj="setObj"
            @updateObj="updateObj"
            :templates="templates"
        >
        </newsletter-show>
        <div v-else v-text="transUF('loading')+'...'"></div>

	</div>
</template>

<script>
import NewslettersList from './newsletters/List.vue'
import NewsletterShow from './newsletters/Show.vue'
export default {
    name : 'Newsletters',
    components : {
        NewslettersList,
        NewsletterShow,
    },
    beforeMount() {
        if (this.$route.params.id) {
            window.axios.get('/api/newsletters/'+this.$route.params.id)
            .then(response => {
                this.setObj(response.data);
            })
        }
    },
    data() {
        return {
            obj : window.__START.obj,
            mailinglists : window.__START.mailinglists,
            templates : window.__START.templates,
            users : window.__START.users,
            customers : window.__START.customers,
            composer : false,
        }
    },
    methods : {
        setObj(data) {
            if (data === null) {
                this.obj = null;
                this.composer = false
                if ( this.$route.path !== '/newsletters' )
                    this.$router.push({ path: '/newsletters' /*, params: { id: '123' } */ })
            }
            else {
                this.obj = data;
                if ( this.$route.path !== '/newsletters/'+data.id )
                    this.$router.push({ path: '/newsletters/'+data.id })
            }
        },
        updateObj(data) {
            var t = this;
            // false = get back unmodified data from Server
            if (data === false) {
                window.axios.get('/api/newsletters/'+t.obj.id)
                .then( function(response) {
                    t.setObj(response.data)
                })
            } else {
                t.obj = Object.assign({}, t.obj, data)
                if (t.obj.has_custom_html == 1 && t.obj.custom_html === '')
                    t.obj.custom_html = ' '
                window.axios.post("/api/newsletters/" + t.obj.id, t.obj)
                .then(function() {
                    var randomized = "?"+ Math.floor(Math.random() * 99999);
                    t.obj.picture = t.obj.picture.includes("?") ? 
                        ( t.obj.picture.substring(0, t.obj.picture.lastIndexOf("?") ) + randomized )
                        : (t.obj.picture + randomized)
                    window.notify('success', t.transUF('record updated') )
                })
            }
        },
        newObj() {
            var t = this;
            t.$refs.NewslettersList.$refs.GVueTable.loaded = false
            window.axios.post("/api/newsletters/create")
            .then(function(response) {
                t.setObj(response.data)
            })
        }
    },
}
</script>