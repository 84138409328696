<template>
	<div>
        <g-vue-table
        ref="deletableVueTable"
        :apiIndexUrl="'/api/customers'"
        sortBy="name"
        :columns="columns"
        :obj="obj"
        @newObj="$emit('newObj')"
        :actionsDropdown="actionsDropdown"
        ></g-vue-table>
        
        <bulk-delete-modal 
            ref="bulkDeleteModal" 
            domain="customers"
            deletableRef="deletableVueTable"
        ></bulk-delete-modal>
	</div>
</template>

<script>
export default {
    name : 'CustomersList',
    props : ['obj'],
    methods : {
    },
    computed : {
        actionsDropdown() {
            var t = this;
            return [{
                name : this.transUC('delete'),
                clicked : function(ids) {
                    t.$refs.bulkDeleteModal.show( ids );
                }
            }]
        }
    },
    data(){
        return {
            deleting : [],
            columns : [{
                name: '__checkbox',
                titleClass: 'text-center short-cell',
                dataClass: 'text-center short-cell',
                visible : true,
            },{
                title: '#',
                name: 'id',
                sortField: 'id',
                titleClass: 'text-right medium-cell',
                dataClass: 'text-right medium-cell',
                visible : true,
            },{
                title : this.transUC('name'),
                name: 'name',
                sortField: 'name',
                dataClass: 'clickable',
                cellClicked : function(t, data, field, event) {
                    window.axios.get('/api/customers/'+data.id)
                    .then(function(response) {
                        t.$emit('setObj', response.data);
                    })
                },
                visible : true,
            },{
                title : this.transUC('blacklists'),
                name: '_blacklists_count',
                sortField: '_blacklists_count',
                visible : true,
                callback(data) {
                    return data !== 0 ? data : ''
                }
            },{
                title : this.transUC('updated'),
                name: 'updated_at',
                sortField: 'updated_at',
                titleClass: 'text-center updated-at-cell',
                dataClass: 'text-right updated-at-cell',
                visible : true
            }]
        }
    }
}
</script>