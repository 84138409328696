<template>

    <div style="text-align:right; display:inline-block; float:right">
        <button 
            v-if="level === 0"
            type="button" 
            class="btn btn-danger btn-xs" 
            @click="level++"
        ><i class="fa fa-trash"></i>  {{ 'delete record' | transUF }}</button>
        <div style="font-size:1em; max-width:300px; float:right" v-if="level === 1" >
            <i>Sei sicuro? Questa azione eliminerà definitivamente l'oggetto dal database e le sue relazioni con altri oggetti del sistema. <br>Clicca "Conferma" per procedere.</i>
            <br><br>
            <button
                type="button"
                class="btn btn-default btn-xs"
                @click="level--"
            ><i class="fa fa-undo"></i> {{ 'cancel' | transUF }}</button>
            <button
                type="button"
                class="btn btn-danger btn-xs"
                @click="level++"
            ><i class="fa fa-trash"></i> Conferma</button>
        <br>
        <br>
        </div>
    </div>

</template>
<script>
export default {
    name: 'Deleter',
    data() {
        return {
            level : 0
        }
    },
    watch : {
        level(val) {
            if (val === 2) {
                this.$parent.deleteObj()
            }
        }
    }
}
</script>