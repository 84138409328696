<template>
	<div>
        <b-modal id="bulkCreator" 
            ref="bulkCreator"
            size="md" 
            :title="transUF('confirm')"
            @ok="create"
        >
        <div class="clearfix">
            <div class="form-group">
                <label>Nome del nuovo attributo:</label>
                <input type="text" class="form-control" v-model="newAttribute" /> 
            </div>
        </div>
        <div style="clear:both; margin-top:20px">
            Clicca "OK" per confermare la creazione.
        </div>
        </b-modal>
	</div>
</template>

<script>
export default {
    data() {
        return {
            newAttribute : ''
        }
    },
    methods : {
        show() { 
            this.$refs.bulkCreator.show()
        },
        create() {
            if (this.newAttribute !== null && this.newAttribute !== '') {
                this.$parent.createChildAttribute(this.newAttribute)
                this.newAttribute = ''
            }
        }
    },
}
</script>