<template>
	<div>
        <div class="row">
            <div class="col-md-4">
                <div class="clearfix">
                    <b-select v-model="model" class="data-model-selector margined">
                        <option value="journalist">{{ 'journalists' | transUF }}</option>
                        <option value="editorial">{{ 'newspapers' | transUF }}</option>
                    </b-select>
                </div>
                <br>
                <div class="clearfix">
                    <table class="table table-condensed">
                        <tbody>
                            <tr v-for="(name, key) in attributes" :key="key">
                                <td>
                                    <div class="input-group mb-2" style="margin-bottom:0px!important">
                                        <div class="input-group-prepend" style="width:40%;">
                                            <div class="input-group-text" v-text="key" style="width:100%"></div>
                                        </div>
                                        <input type="text" class="form-control" :disabled="untouchable.indexOf(key) !== -1" v-model="attributes[key]" v-debounce:700ms="updateNames"/>
                                    </div>
                                </td>
                                <td style="vertical-align:middle; padding:0px; width:70px">
                                    <button class="btn btn-sm btn-default" 
                                    @click="selectAttribute(key)">
                                        <i class="fa fa-arrow-right"></i>
                                    </button>
                                    <button class="btn btn-sm btn-default" 
                                    v-if="untouchable.indexOf(key) === -1"
                                    @click="deleteAttributes([key])">
                                        <i class="fa fa-trash"></i>
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <input type="text" class="form-control" v-model="newAttribute" />
                                </td>
                                <td style="vertical-align:middle; padding:0px; width:70px">
                                    <button class="btn btn-sm btn-default" 
                                    @click="createAttribute()">
                                        <i class="fa fa-plus"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-md-8" v-if="attribute !== null">

                <attributes-list 
                    ref="attributesListTable"
                    :attribute="attribute" 
                    @newObj="newObj(attribute)"></attributes-list>

            </div>

            <bulk-delete-modal
                ref="deleteModal" 
                domain="attributes"
                :additionalParameters="{ 'parent' : attribute }"
                deletableRef="attributesListTable"
                @callbackFunction="deletedCallback"
            ></bulk-delete-modal>

             <create-modal 
                ref="createModal" 
            ></create-modal>

        </div>
	</div>
</template>

<script>
import AttributesList from './attributes/List.vue'
import CreateModal from './attributes/CreateModal.vue'
export default {
    name : 'Attributes',
    mounted() {
        this.getAttributes()
    },
    components: {
        AttributesList,
        CreateModal
    },
    data() {
        return {
            model : 'journalist',
            attributes : [],
            newAttribute : [],
            attribute : null,
            untouchable : [
                'roles',
                'genres',
                'categories',
                'periodicities',
                'offices'
            ],
        }
    },
    computed : {
        actionsDropdown() {
            var t = this;
            return [{
                name : this.transUC('delete'),
                clicked : function(ids) {
                    t.$refs.bulkDeleteModal.show( ids );
                }
            }]
        }
    },
    methods : {
        getAttributes() {
            var t = this
            window.axios.get('/api/attributes?model='+t.model)
            .then(function(response) {
                t.attributes = response.data
            })
        },
        selectAttribute(key) {
            this.attribute = key
        },
        updateAttribute(key) {
            if (this.untouchable.indexOf(key) !== -1)
                return false;

            var t = this
            var name = t.attributes[key]
            window.axios.post('/api/attributes/'+key, {
                model : t.model,
                name : name
            })
            .then(function() {
                window.notify('success', 'Attributo aggiornato')
            })
        },
        createAttribute() {
            if (this.newAttribute == '' || this.newAttribute == null)
                return false;
            var t = this
            window.axios.post('/api/attributes/create', {
                model : t.model,
                attribute : t.newAttribute
            })
            .then(function(response) {
                t.attributes = response.data
                t.newAttribute = ''
                window.notify('success', 'Attributo padre creato')
            })
        },
        createChildAttribute(name) {
            var t = this
            window.axios.post('/api/attributes/create', {
                model : t.model,
                parent : t.attribute,
                name : name
            })
            .then(function() {
                t.$refs.attributesListTable.refresh()
                window.notify('success', 'Attributo creato')
            })
        },
        newObj() {
            this.$refs.createModal.show()
        },
        deleteAttributes(arr) {
            this.$refs.deleteModal.show(arr)
        },
        updateNames() {
            var t = this
            window.axios.post('/api/attributes/update', {
                attributes : t.attributes,
            })
            .then(function() {
                window.notify('success', 'Attributo aggiornato')
            })
        },
        deletedCallback() {
            this.getAttributes()
        }
    },
    watch : {
        model() {
            this.getAttributes()
        }
    }
}
</script>