<template>
	<div>
        <g-vue-table
        :apiIndexUrl="detailsURL"
        sortBy="email"
        :columns="columns"
        ref="GVueTable"
        :filtered="false"
        :creable="false"
        :obj="false"
        ></g-vue-table>
	</div>
</template>

<script>
export default {
    name : 'StatisticsDetails',
    props : [ 'detailsURL' ],
    methods : {

    },
    data(){
        return {
            columns : [{
                title : this.transUC('email'),
                name: 'email',
                titleClass: 'text-left',
                dataClass: 'text-left',
                visible : true,
            },{
                title : this.transUC('verified'),
                name: 'status',
                titleClass: 'text-left',
                dataClass: 'text-left',
                visible : true,
                callback(data) {
                    if (data === true)
                        return '<i class="fa fa-circle" style="color:green"></i>';
                    if (data === false)
                        return '<i class="fa fa-circle" style="color:red"></i>';
                    if (data === null)
                        return '<i class="fa fa-circle" style="color:grey"></i>';
                }
            },{
                title : 'NOTE',
                name: 'note',
                titleClass: 'text-left',
                dataClass: 'text-left',
                visible : true,
            }],
        }
    }
}
</script>