<template>
    <div class="tab-pane fade" v-bind:class="{ 'show active': this.$props.tabs.about }">
        <div class="table-responsive">
            <table class="table table-profile">
                <thead>
                    <tr>
                        <th class="align-right">
                        </th>
                        <th>
                            <h4 style="margin-bottom:0px">

                                <span id="first_name" v-text="obj.first_name"></span>
                                <span id="last_name" v-text="obj.last_name"></span>

                                <b-button size="xs" v-b-modal.jsonmodal class="btn-default"><i class="fa fa-code"></i></b-button>
                                &nbsp;
                                <b-button size="xs" v-b-modal.historymodal class="btn-default"><i class="fa fa-history"></i></b-button>
                                &nbsp;
                            </h4>
                        </th>
                    </tr>
                    <tr><td colspan="2"></td></tr>
                </thead>
                <tbody>
                     <tr>
                        <td class="field valign-middle">
                            {{ 'name header' | transUW }}
                        </td>
                        <td>
                            <select class="form-control input-inline input-sm" v-model="$parent.$parent.obj.name_header_id" ref="name_header_id"
                            @change="updateObj">
                                <option value="null" disabled selected>Seleziona</option>
                                <option v-for="(nameHeader,k) in nameHeaders" :key="k" v-bind:value="nameHeader.id" v-text="nameHeader.name" />
                            </select>
                            <br>
                        </td>
                    </tr>
                     <tr>
                        <td class="field valign-middle">
                            {{ 'gender' | transUW }}
                        </td>
                        <td>
                            <select class="form-control input-inline input-sm" v-model="$parent.$parent.obj.gender" ref="gender" @change="updateObj">
                                <option :value="null">Non definito</option>
                                <option value="f" v-text="transUF('woman')" />
                                <option value="m" v-text="transUF('man')" />
                            </select>
                            <br>
                        </td>
                    </tr>
                    <tr class="divider" v-show="$parent.hideInJournalists.indexOf('agency') < 0 || $parent.hideInJournalists.indexOf('locale') < 0 || $parent.hideInJournalists.indexOf('type') < 0">
                        <td colspan="2"></td>
                    </tr>
                     <tr v-show="$parent.hideInJournalists.indexOf('agency') < 0">
                        <td class="field valign-middle">
                            {{ 'agency' | transUW }}
                        </td>
                        <td>
                            <select class="form-control input-inline input-sm" v-model="$parent.$parent.obj.agency" ref="agency"
                            @change="updateObj">
                                <option value="null" disabled selected>Seleziona</option>
                                <option value="BPress">BPress</option>
                                <option value="NowPR!">NowPR!</option>
                            </select>
                            <br>
                        </td>
                    </tr>
                    <tr v-show="$parent.hideInJournalists.indexOf('locale') < 0">
                        <td class="field valign-middle">
                            {{ 'language' | transUW }}
                        </td>
                        <td>
                            <select class="form-control input-inline input-sm" v-model="$parent.$parent.obj.locale" ref="locale" @change="updateObj">
                                <option value="null" disabled selected>Seleziona</option>
                                <option value="it">Italiano</option>
                                <option value="en">Inglese</option>
                            </select>
                            <br>
                        </td>
                    </tr>
                    <tr v-show="$parent.hideInJournalists.indexOf('type') < 0">
                        <td class="field valign-middle">
                            {{ 'type' | transUW }}
                        </td>
                        <td>
                            <select class="form-control input-inline input-sm" v-model="$parent.$parent.obj.journalist_type" ref="journalist_type" @change="updateObj">
                                <option value="null" disabled selected>Seleziona</option>
                                <option value="Cliente">Cliente</option>
                                <option value="Ex Cliente">Ex Cliente</option>
                                <option value="Prospect">Prospect</option>
                                <option value="Partner">Partner</option>
                                <option value="Candidato">Candidato</option>
                                <option value="Altro Contatto">Altro Contatto</option>
                                <option value="Community at large">Community at large</option>
                            </select>
                            <br>
                        </td>
                    </tr>
                    <tr class="divider">
                        <td colspan="2"></td>
                    </tr>
                    <tr>
                        <td class="field valign-middle">
                            <i class="fa fa-user fa-sm m-r-5"></i> {{ 'first name' | transUW }}
                        </td>
                        <td>
                            <b-input type="text" autocomplete="off" 
                                name="first_name"
                                v-debounce:700ms="updateObj"
                                v-model="$parent.$parent.obj.first_name" 
                                class="input-sm"
                                style="display:inline; max-width:300px"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td class="field valign-middle">
                            <i class="fa fa-user fa-sm m-r-5"></i> {{ 'last name' | transUW }}
                        </td>
                        <td>
                            <b-input type="text" autocomplete="off" 
                                name="last_name"
                                v-debounce:700ms="updateObj"
                                v-model="$parent.$parent.obj.last_name" 
                                class="input-sm"
                                style="display:inline; max-width:300px"
                            />
                        </td>
                    </tr>
                    <tr class="divider">
                        <td colspan="2"></td>
                    </tr>
                    <tr v-for="(email, k) in obj.email" :key="k">
                        <td class="field valign-middle">
                            <i class="fa fa-envelope fa-sm m-r-5"></i> Email ({{ k+1 }})
                        </td>
                        <td>
                            <b-input type="text" autocomplete="off" 
                                name="email"
                                v-debounce:700ms="updateObj"
                                v-model="$parent.$parent.obj.email[k]" 
                                class="input-sm"
                                style="display:inline; max-width:300px"
                            />
                            <button v-if="k === obj.email.length-1" class="btn btn-xs btn-default" @click="addEmail" title="AGGIUNGI EMAIL">
                                <i class="fa fa-plus"></i>
                            </button>
                            <button v-if="k > 0" class="btn btn-xs btn-default" @click="removeEmail(k)" title="RIMUOVI EMAIL">
                                <i class="fa fa-minus"></i>
                            </button>
                        </td>
                    </tr>
                    <tr class="divider">
                        <td colspan="2"></td>
                    </tr>
                    <tr v-show="$parent.hideInJournalists.indexOf('custom_role') < 0">
                        <td class="field valign-middle">
                            <i class="fa fa-user fa-sm m-r-5"></i> {{ 'custom role' | transUW }}
                        </td>
                        <td>
                            <b-input type="text" autocomplete="off" 
                                name="custom_role"
                                v-debounce:700ms="updateObj"
                                v-model="$parent.$parent.obj.custom_role" 
                                class="input-sm"
                                style="display:inline; max-width:300px"
                            />
                        </td>
                    </tr>
                    <tr v-show="$parent.hideInJournalists.indexOf('custom_office') < 0">
                        <td class="field valign-middle">
                            <i class="fa fa-user fa-sm m-r-5"></i> {{ 'custom office' | transUW }}
                        </td>
                        <td>
                            <b-input type="text" autocomplete="off" 
                                name="custom_office"
                                v-debounce:700ms="updateObj"
                                v-model="$parent.$parent.obj.custom_office" 
                                class="input-sm"
                                style="display:inline; max-width:300px"
                            />
                        </td>
                    </tr>
                    <tr class="divider">
                        <td colspan="2"></td>
                    </tr>
                    <tr v-show="$parent.hideInJournalists.indexOf('telephone') < 0">
                        <td class="field valign-middle">
                            <i class="fa fa-phone fa-sm m-r-5"></i> {{ 'telephone' | transUW }}
                        </td>
                        <td>
                            <b-input type="text" autocomplete="off" 
                                name="telephone"
                                v-debounce:700ms="updateObj"
                                v-model="$parent.$parent.obj.telephone" 
                                class="input-sm"
                                style="display:inline; max-width:300px"
                            />
                        </td>
                    </tr>
                    <tr v-show="$parent.hideInJournalists.indexOf('mobile') < 0">
                        <td class="field valign-middle">
                            <i class="fa fa-mobile fa-sm m-r-5"></i> {{ 'mobile' | transUW }}
                        </td>
                        <td>
                            <b-input type="text" autocomplete="off" 
                                name="mobile"
                                v-debounce:700ms="updateObj"
                                v-model="$parent.$parent.obj.mobile" 
                                class="input-sm"
                                style="display:inline; max-width:300px"
                            />
                        </td>
                    </tr>
                    <tr v-if="$parent.hideInJournalists.indexOf('fax') < 0">
                        <td class="field valign-middle">
                            <i class="fa fa-fax fa-sm m-r-5"></i> {{ 'fax' | transUW }}
                        </td>
                        <td>
                            <b-input type="text" autocomplete="off"
                                name="fax"
                                v-debounce:700ms="updateObj"
                                v-model="$parent.$parent.obj.fax" 
                                class="input-sm"
                                style="display:inline; max-width:300px"
                            />
                        </td>
                    </tr>
                    <tr v-show="$parent.hideInJournalists.indexOf('website') < 0">
                        <td class="field valign-middle">
                            <i class="fa fa-globe fa-sm m-r-5"></i> {{ 'website' | transUW }}
                        </td>
                        <td>
                            <b-input type="text" autocomplete="off" 
                                name="internet"
                                v-debounce:700ms="updateObj"
                                v-model="$parent.$parent.obj.internet" 
                                class="input-sm"
                                style="display:inline; max-width:300px"
                            />
                        </td>
                    </tr>
                    <tr class="divider">
                        <td colspan="2"></td>
                    </tr>
                    <tr v-show="$parent.hideInJournalists.indexOf('address') < 0">
                        <td class="field valign-middle">
                            {{ 'address' | transUW }}
                        </td>
                        <td>
                            <b-input type="text" autocomplete="off" 
                                name="address"
                                v-debounce:700ms="updateObj"
                                v-model="$parent.$parent.obj.address" 
                                class="input-sm"
                                style="display:inline; max-width:300px"
                            />
                        </td>
                    </tr>
                    <tr v-show="$parent.hideInJournalists.indexOf('postcode') < 0">
                        <td class="field valign-middle">
                            {{ 'postcode' | transUW }}
                        </td>
                        <td>
                            <b-input type="text" autocomplete="off" 
                                name="postcode"
                                v-debounce:700ms="updateObj"
                                v-model="$parent.$parent.obj.postcode" 
                                class="input-sm"
                                style="display:inline; max-width:300px"
                            />
                        </td>
                    </tr>
                    <tr v-show="$parent.hideInJournalists.indexOf('city') < 0">
                        <td class="field valign-middle">
                            {{ 'city' | transUW }}
                        </td>
                        <td>
                            <b-input type="text" autocomplete="off" 
                                name="city"
                                v-debounce:700ms="updateObj"
                                v-model="$parent.$parent.obj.city" 
                                class="input-sm"
                                style="display:inline; max-width:300px"
                            />
                        </td>
                    </tr>
                    <tr v-show="$parent.hideInJournalists.indexOf('province') < 0">
                        <td class="field valign-middle">
                            {{ 'province' | transUW }}
                        </td>
                        <td>
                            <select class="form-control input-inline input-sm" v-model="$parent.$parent.obj.province_id" ref="province_id" @change="updateObj">
                                <option value="null" disabled selected>Seleziona</option>
                                <option v-for="province in provinces" v-bind:key="province.name" v-bind:value="province.id">
                                    {{ province.name }}
                                </option>
                            </select>
                            <br>
                        </td>
                    </tr>
                    <tr v-show="$parent.hideInJournalists.indexOf('country') < 0">
                        <td class="field valign-middle">
                            {{ 'country' | transUW }}
                        </td>
                        <td>
                            <select class="form-control input-inline input-sm" v-model="$parent.$parent.obj.country_id" @change="updateObj">
                                <option value="null" disabled selected>Seleziona</option>
                                <option v-for="country in countries" v-bind:key="country.name" v-bind:value="country.id">
                                    {{ country['name_'+locale] }}
                                </option>
                            </select>
                        </td>
                    </tr>
                    <tr class="divider">
                        <td colspan="2"></td>
                    </tr>
                    <tr>
                        <td></td>
                        <td>
                            <div>
                                <br>
                                <small><i>Per confermare le modifiche sui campi di testo, premere il testo INVIO. Per annullare, premere il testo ESC.</i></small>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <b-modal id="jsonmodal" 
            size="lg" 
            title="Realtime DB Record"
            ok-only
        >
                <vue-json-pretty
                :path="'res'"
                :data="obj">
                </vue-json-pretty>
        </b-modal>
        <b-modal id="historymodal" 
            size="lg" 
            :title="transUF('modifications history')"
            ok-only
        >
            <table class="table table-sm">
                <thead>
                    <tr>
                        <th>{{ 'date' | trans }}</th>
                        <th>{{ 'user' | trans }}</th>
                        <th>{{ 'field' | trans }}</th>
                        <th>{{ 'before' | trans }}</th>
                        <th style="width:20px;"></th>
                        <th>{{ 'after' | trans }}</th>
                        <th></th>
                    </tr>
                </thead>
                <tr v-for="(modification, k) in modifications" :key="k">
                    <td>{{ modification.created_at | moment('DD/MM/YY HH:mm') }}</td>
                    <td>{{ modification.user }}</td>
                    <td><b>{{ modification.attribute }}</b></td>
                    <td>
                        <span style="opacity:0.7" v-html="$options.filters.modificationUrl(modification.before)" />
                    </td>
                    <td style="width:20px;">></td>
                    <td>
                        <span v-html="$options.filters.modificationUrl(modification.after)" />
                    </td>
                    <td>
                        <b-button size="xs"
                        @click="$emit('updateObj', { [modification.attribute] : modification.before } );"
                        :title="transUC('restore')+' '+modification.attribute+' > '+modification.before"
                        ><i class="fa fa-history"></i></b-button>
                        &nbsp;
                        <b-button class="btn-danger" size="xs"
                        @click="deleteModification(modification.id)"
                        :title="transUC('delete')"
                        ><i class="fa fa-trash"></i></b-button>
                    </td>
                </tr>
            </table>
        </b-modal>

    </div>
</template>
<script>
import VueJsonPretty from 'vue-json-pretty'
export default {
    name : 'About',
    props : [ 'tabs', 'provinces', 'regions', 'countries', 'nameHeaders' ],
    components : {
        VueJsonPretty
    },
    computed : {
        obj: {
            get() { return this.$parent.obj },
            set(val) { this.$parent.obj = val }
        },
        modifications : {
            get() { return this.$parent.modifications },
            set(val) { this.$parent.modifications = val }
        },
        locale() {
            return window.locale
        }
    },
    methods: {
        addEmail() {
            this.$parent.obj.email.push('')
            this.$emit('updateObj')
        },
        removeEmail(k) {
            this.$parent.obj.email.splice(k, 1)
            this.$emit('updateObj')
        },
        updateObj() {
            this.$emit('updateObj')
        },
        // restoreObj(event, element) {
        //     var name = element.name;
        //     this.$emit('updateObj', false)
        // },
        deleteModification(modification_id) {
            var t = this;
            window.axios.post('/api/modifications/'+modification_id+'/delete', {})
            .then(function() {
                for(var key in t.modifications) {
                    if ( t.modifications[key]['id'] == modification_id) {
                        t.$delete(t.modifications, key)
                    }
                }
            })
        }
    },
    filters : {
        modificationUrl : function(string) {
            if (string === null)
                string = ''
            if(! (string.includes('http') || string.includes('/images/')) )
                return string;
            if (string.length > 16)
                return '<a href="'+string+'" target="_blank">'+
                string.substring(0,16)+
                '...'+
                '</a>';
            return string;
        }
    }
}
</script>