<template>
	<div>
        <g-vue-table
        ref="deletableVueTable"
        :apiIndexUrl="'/api/publishers'"
        sortBy="id"
        :columns="columns"
        :obj="obj"
        @newObj="$emit('newObj')"
        :actionsDropdown="actionsDropdown"
        ></g-vue-table>

        <bulk-delete-modal 
            ref="bulkDeleteModal" 
            domain="publishers"
            deletableRef="deletableVueTable"
        ></bulk-delete-modal>

        <publishers-merge-modal 
            ref="merger" 
            mergebleRef="deletableVueTable"
            :regions="regions"
            :provinces="provinces"
            :countries="countries"
        ></publishers-merge-modal>

	</div>
</template>

<script>
import PublishersMergeModal from './list/MergeModal.vue'
export default {
    name : 'PublishersList',
    props : [ 'obj', 'regions', 'provinces', 'countries' ],
    components : {
        PublishersMergeModal
    },
    computed : {
        actionsDropdown() {
            var t = this;
            return [{
                name : this.transUC('delete'),
                clicked : function(ids) {
                    t.$refs.bulkDeleteModal.show( ids );
                }
            },{
                name : this.transUC('merge'),
                clicked : function(ids) {
                    if (ids.length > 1)
                        t.$refs.merger.show( ids )
                }
            }]
        }
    },
    data(){
        return {
            columns : [{
                name: '__checkbox',
                titleClass: 'text-center short-cell',
                dataClass: 'text-center short-cell',
                visible : true
            },{
                title: '#',
                name: 'id',
                sortField: 'id',
                titleClass: 'text-right medium-cell',
                dataClass: 'text-right medium-cell',
                visible : true
            },{
                title : this.transUC('name'),
                name: 'companyname',
                sortField: 'companyname',
                dataClass: 'clickable',
                cellClicked : function(t, data) {
                    window.axios.get('/api/publishers/'+data.id)
                    .then(function(response) {
                        t.$emit('setObj', response.data);
                    })
                },
                visible : true
            },{
                title : this.transUC('telephone'),
                name: 'telephone',
                sortField: 'telephone',
                visible : true
            },{
                title : this.transUC('email'),
                name: 'email',
                sortField: 'email',
                callback(data) {
                    if (data !== null)
                        return '<a href="mailto:'+data+'">'+data+'</a>'
                },
                visible : true
            },{
                title : this.transUC('city'),
                name: 'city',
                sortField: 'city',
                visible : true
            },{
                title : this.transUC('newsp'),
                name: '_editorials_count',
                sortField: '_editorials_count',
                visible : true
            },{
                title : this.transUC('journ'),
                name: '_journalists_count',
                sortField: '_journalists_count',
                visible : true
            },{
                title : this.transUC('updated'),
                name: 'updated_at',
                sortField: 'updated_at',
                titleClass: 'text-center updated-at-cell',
                dataClass: 'text-right updated-at-cell',
                visible : true
            }],
        }
    }
}
</script>