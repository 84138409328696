<template>
    <div>
        <div class="row">
            <div class="col-6" style="text-align:left">
                <select class="form-control" 
                style="max-width: 100px;height: 30px;padding: 4px;" 
                v-model="filterShow">
                    <option value="1" v-text="transUF('relations')" />
                    <option value="2" v-text="transUF('individuals')" />
                </select>
                <br>
                <br>
            </div>
            <div class="col-6" style="text-align:right">
                <button 
                    @click="filterShow = filterShow !== 3 ? 3 : (isIdsFilter ? 2 : 1)" 
                    class="btn btn-xs btn-default m-r-5">
                    <i :class="filterShow == 3 ? 'fa fa-table' : 'fa fa-users'"></i> 
                    <span v-text="filterShow == 3 ? 'FILTRA' : 'MOSTRA SELEZIONATI'" ></span>
                </button>
                    <br>
                    <br>
            </div>
        </div>

        <div v-show="filterShow == 1">
            <table class="table table-striped table-condensed" v-if="allRelations !== null">
                <tr v-for="(relation,k) in allRelations" :key="k" v-show="allRelations[k][0] !== undefined && allRelations[k][0].children.length">
                    <td style="width:200px" v-text="trans(k)+' ('+ (allRelations[k][0] !== undefined ? allRelations[k][0].type : 'x' )+')'"></td>
                    <td class="multiselect-td">
                        <Multiselect 
                            v-model="thisFilter.relations[k]" 
                            :options="relation"
                            label="name"
                            track-by="id"
                            groupValues="children"
                            groupLabel="name"
                            :groupSelect="false"
                            :searchable="true"
                            :close-on-select="true"
                            multiple
                            @select="updateObj"
                            @remove="updateObj"
                        ></Multiselect>
                    </td>
                </tr>
            </table>
            <b>I</b> = Intersezione (il risultato appartiene SIA ad <i>x</i> CHE a <i>y</i>). <br>
            <b>U</b> = Unione (il risultato appartiene a <i>x</i> OPPURE a <i>y</i>)
            <hr>
            <strong>Descrizione:</strong> <span v-html="thisFilter.description"></span>

        </div>

        <div v-if="filterShow == 2">
            <filter-individuals ref="filterIndividuals" ></filter-individuals>
        </div>

        <div v-if="filterShow == 3">
            <filter-journalists ref="filterJournalists" ></filter-journalists>
        </div>

    </div>
</template>

<script>
import FilterJournalists from './FilterJournalists.vue'
import FilterIndividuals from './FilterIndividuals.vue'
export default {
    name : 'FilterShow',
    props : ['emptyFilter'],
    components : {
        FilterJournalists,
        FilterIndividuals
    },
    computed : {
        obj: {
            get() { return this.$parent.$parent.obj },
            set(data) { this.$parent.$parent.obj = data }
        },
        filterId() {
            return this.$parent.currentFilterId
        },
        thisFilter: {
            get() {
                if (this.$parent.currentFilterId === null)
                    return this.emptyFilter
                else
                    return this.obj.filters[this.$parent.currentFilterId]
            },
            set(data) {
                if (this.$parent.currentFilterId === null)
                    this.emptyFilter = data
                else
                    this.obj.filters[this.$parent.currentFilterId] = data
            }
        },
        isIdsFilter() {
            return Object.values(this.obj.filters[this.$parent.currentFilterId].ids).length > 0
        }
    },
    data() {
        return  {
            allRelations : this.$parent.$parent.allRelations,
            filterShow : 1,
            /*
            1 = relations
            2 = individuals
            3 = list
            */
        }
    },
    methods : {
        updateObj() {
            this.$emit('updateObj')
        }
    }
}
</script>

<style scoped>
.multiselect-td {
    padding: 2px 10px;
}
.multiselect {
    min-height: 28px;
}
</style>
