<template>
	<div>
        <g-vue-table
        :apiIndexUrl="'/api/mailinglists/'+$parent.obj.id+'/journalists'"
        :sortBy="'first_name'"
        :columns="columns"
        ref="GVueTable"
        :filtered="true"
        :creable="false"
        :obj="false"
        :actionsDropdown="actionsDropdown"
        ></g-vue-table>
	</div>
</template>

<script>
export default {
    name : 'JournalistsList',
    props : ['obj'],
    computed : {
        actionsDropdown() {
            var t = this;
            return [{
                name : this.transUC('remove from lists'),
                clicked : function(ids) {
                    t.removeFromList(ids)
                }
            }]
        }
    },
    methods : {
        updateObj() {
            return this.$parent.$parent.updateObj()
        },
        removeFromList(ids) {
            var t = this
            window.axios.post('/api/mailinglists/'+t.obj.id+'/filters/new')
            .then(function(response) {
                var data = response.data
                data['add_subtract'] = 'subtract'
                data['ids'] = ids
                t.obj.filters.push(data)
                t.updateObj()
            })
        }
    },
    data(){
        return {
            columns : [{
                name: '__checkbox',
                titleClass: 'text-center short-cell',
                dataClass: 'text-center short-cell',
                visible : true,
            },{
                title: '#',
                name: 'id',
                sortField: 'id',
                titleClass: 'text-right medium-cell',
                dataClass: 'text-right medium-cell clickable',
                cellClicked : function(t, data, field, event) {
                    window.axios.get('/api/journalists/'+data.id)
                    .then(function(response) {
                        t.$emit('setObj', response.data);
                    })
                },
                visible : true,
            },{
                title : this.transUC('name'),
                name: '_companyname',
                sortField: '_companyname',
                dataClass: 'clickable',
                cellClicked : function(t, data, field, event) {
                    window.axios.get('/api/journalists/'+data.id)
                    .then(function(response) {
                        t.$emit('setObj', response.data);
                    })
                },
                visible : true,
            },{
                title : this.transUC('email'),
                name: '_emails',
                sortField: '_emails',
                dataClass: '',
                visible : true,
                callback : function(data) {
                    if (data === null)
                        return null
                    data = (new String(data)).split(',')
                    var result = [];
                    for (var i=0;i<data.length;i++) {
                        result.push( '<a href="mailto:'+data[i]+'">'+data[i]+'</a>' )
                    }
                    return result.join(' + ')
                }
            },{
                title : this.transUC('newspapers'),
                name: '_editorials',
                sortField: '_editorials',
                visible : true,
                callback : function(data) {
                    if (data === null)
                        return null
                    data = (new String(data)).split('||')
                    var result = [];
                    for (var i=0;i<data.length;i++) {
                        var pattern = /\[(.*?)\]/g;
                        var match;
                        while ((match = pattern.exec(data[i])) != null) {
                            // id = match[1]
                            var str = '<a href="/#/editorials/'+match[1]+'" target="_blank">'+data[i].replace(match[0], '')+'</a>'
                            result.push( str )
                        }
                    }
                    return result.join(' + ')
                }
            },{
                title : this.transUC('offices'),
                name: '_offices',
                sortField: '_offices',
                visible : true,
                callback : function(data) {
                    if (data === null)
                        return null
                    data = (new String(data)).split('||')
                    var result = [];
                    for (var i=0;i<data.length;i++) {
                        var pattern = /\[(.*?)\]/g;
                        var match;
                        while ((match = pattern.exec(data[i])) != null) {
                            // id = match[1]
                            var str = data[i].replace(match[0], '')
                            result.push( str )
                        }
                    }
                    return result.join(' + ')
                },
            },{
                title : this.transUC('city'),
                name: 'city',
                sortField: 'city',
                dataClass: '',
                visible : true,
            },{
                title : this.transUC('updated'),
                name: 'updated_at',
                sortField: 'updated_at',
                titleClass: 'text-center updated-at-cell',
                dataClass: 'text-right updated-at-cell',
                visible : true
            }],
        }
    }
}
</script>