<template>
    <div style="text-align:left">
        Utilizza questo modulo per importare le blacklist in formato Excel
        <br>
        <form v-if="!wait" ref="form" class="form" method="POST" :action="'/customers/'+obj.id+'/blacklists/import'" enctype="multipart/form-data">
            <div class="form-control">
                <input type="file" name="file[]" multiple />
            </div>
            <br>
            <button class="btn btn-danger" type="submit">Invia</button>
        </form>
        <h1 v-if="wait" :style="{ 'color' : waitColor }">Attendere...</h1>
    </div>
</template>

<script>
export default {
    name : 'BlacklistsImporter',
    mounted() {
        setInterval(function() {
            if (this.wait) {
                if (this.waitColor === 'red')
                    this.waitColor = 'black'
                else
                    this.waitColor = 'red'
            }
        }, 600)
    },
    computed : {
        obj() { return this.$parent.obj }
    },
    methods : {
    },
    data() {
        return {
           wait : false,
           waitColor : 'red'
        }
    }
}
</script>