<template>
    <div>
        
    </div>
</template>
<script>
export default {
    props: ['obj'],
    methods: {
        getStatus(status) {
            if (status == 0)
                return '<button class="btn btn-default btn-xs"><i class="fa fa-clock"></i> In coda</button>'
            if (status == 1)
                return '<button class="btn btn-warning btn-xs"><i class="fa fa-paper-plane"></i> In corso</button>'
            if (status == 2)
                return '<button class="btn btn-success btn-xs"><i class="fa fa-check"></i> Completato</button>'

            return '?';
        }
    }
}
</script>