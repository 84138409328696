<template>
	<div>
        <journalists-list 
            v-if="obj === null && !$route.params.id"
            :obj="obj"
            @setObj="setObj"
            @newObj="newObj"
            :regions="regions"
            :provinces="provinces"
            :countries="countries"
            :mailinglists="mailinglists"
        ></journalists-list>
        <journalist-show
            v-else-if="obj" 
            :regions="regions"
            :provinces="provinces"
            :countries="countries"
            :nameHeaders="nameHeaders"
            @setObj="setObj"
            :mailinglists="mailinglists"
            :blacklists="blacklists"
            :customers="customers"
        >
        </journalist-show>
        <div v-else v-text="transUF('loading')+'...'"></div>
	</div>
</template>

<script>
import JournalistsList from './journalists/List.vue'
import JournalistShow from './journalists/Show.vue'
export default {
    name : 'Journalists',
    beforeMount() {
        if (this.$route.params.id) {
            window.axios.get('/api/journalists/'+this.$route.params.id)
            .then(response => {
                this.setObj(response.data);
            })
        }
    },
    components : {
        JournalistsList,
        JournalistShow,
    },
    data() {
        return {
            obj : window.__START.obj,
            regions : window.__START.regions,
            provinces : window.__START.provinces,
            countries : window.__START.countries,
            mailinglists : window.__START.mailinglists,
            blacklists : window.__START.blacklists,
            customers : window.__START.customers,
            nameHeaders : window.__START.nameHeaders,
        }
    },
    methods : {
        setObj(data) {
            if (data === null) {
                this.obj = null;
                if ( this.$route.path !== '/journalists' )
                    this.$router.push({ path: '/journalists' /*, params: { id: '123' } */ })
            }
            else {
                this.obj = data;
                if ( this.$route.path !== '/journalists/'+data.id )
                    this.$router.push({ path: '/journalists/'+data.id })
            }
        },
        newObj() {
            var t = this;
            window.axios.post('/api/journalists/create')
            .then( function(response) {
                t.setObj(response.data)
                window.notify('success', t.transUF('new record created') )
            })
        },
        deleteObj() {
            var t = this;
            window.axios.post('/api/journalists/'+t.obj.id+'/delete')
            .then( function(){
                t.setObj(null)
                window.notify('success', t.transUF('record deleted') )
            })
        },
    }
}
</script>