<template>
	<div class="row">

        <div class="col-12">
            <object-topbar 
                :obj="obj" 
            >
            </object-topbar>
        </div>

        <div class="col-md-6">

            <div class="card text-center">
                
                <div class="card-header">
                    Informazioni
                </div>
                <div class="card-block">

                    <div class="form-group row m-b-15">
                        <label class="col-form-label col-md-3">{{ 'name' | transUF }}</label>
                        <div class="col-md-9">
                            <input type="text" 
                                class="form-control m-b-5" 
                                name="name" 
                                placeholder=""
                                v-debounce:700ms="updateObj"
                                v-model="obj.name" />
                        </div>
                    </div>

                    <div class="form-group row m-b-15">
                        <label class="col-form-label col-md-3">{{ 'creator' | transUF }}</label>
                        <div class="col-md-9">
                            <select 
                                style="height:34px;"
                                class="form-control" 
                                name="user" 
                                @change="updateObj"
                                v-model="obj.user">
                                    <option v-for="(user, k) in users" 
                                        :value="user.name" 
                                        :key="user.name" 
                                        v-text="user.name" />
                            </select>
                        </div>
                    </div>

                </div>

            </div>

            <div class="card text-center">
                
                <div class="card-header">
                    Blacklisted
                </div>
                <div class="card-block">

                    <blacklists-list>
                    </blacklists-list>

                    <blacklists-importer>
                    </blacklists-importer>

                </div>

            </div>
            
        </div>

        <div class="col-md-6">

            <div class="card text-center">
                
                <div class="card-header">
                    {{ 'newsletters' | transUF }}
                </div>
                <div class="card-block">

                    <newsletters-list class="clearfix">
                    </newsletters-list>

                </div>
            </div>

        </div>

	</div>
</template>

<script>
import NewslettersList from './show/NewslettersList'
import BlacklistsList from './show/BlacklistsList'
import BlacklistsImporter from './show/BlacklistsImporter'
export default {
    name : 'CustomerShow',
    components : {
        NewslettersList,
        BlacklistsList,
        BlacklistsImporter,
    },
    computed : {
        obj() { return this.$parent.obj },
        users() { return this.$parent.users }
    },
    methods : {
        updateObj() {
            this.$emit('updateObj')
        },
        setObj(data) {
            this.$emit('setObj', data)
        },
        deleteObj() {
            var t = this;
            window.axios.post('/api/customers/'+t.obj.id+'/delete')
                .then( function(){
                    t.setObj(null)
                })
        }
    },
    data(){
        return {
        }
    },
}
</script>
<style scoped>
.card .card-block {
    background-color:white!important;
    border:1px solid #e3e3e3!important;
    border-radius:4px!important;
    border-top:0px!important;
}
.card .card-header {
    background-color:#b51663!important;
    color:white!important;
}
.card .card-block * {
    color:#444!important;
}
</style>