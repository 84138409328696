<template>
	<div>
        <editorials-list 
            v-if="obj === null && !$route.params.id" 
            :obj="obj"
            @setObj="setObj"
            @newObj="newObj"
            :regions="regions"
            :provinces="provinces"
            :countries="countries"
            :mailinglists="mailinglists"
        ></editorials-list>
        <editorial-show
            v-else-if="obj" 
            :regions="regions"
            :provinces="provinces"
            :countries="countries"
            :publishers="publishers"
            :periodicities="periodicities"
            @setObj="setObj"
            @updateObj="updateObj"
        >
        </editorial-show>
        <div v-else v-text="transUF('loading')+'...'"></div>
	</div>
</template>

<script>
import EditorialsList from './editorials/List.vue'
import EditorialShow from './editorials/Show.vue'
export default {
    name : 'Editorials',
    components : {
        EditorialsList,
        EditorialShow,
    },
    beforeMount() {
        if (this.$route.params.id) {
            window.axios.get('/api/editorials/'+this.$route.params.id)
            .then(response => {
                this.setObj(response.data);
            })
        }
    },
    data() {
        return {
            regions : window.__START.regions,
            provinces : window.__START.provinces,
            publishers : window.__START.publishers,
            periodicities : window.__START.periodicities,
            mailinglists : window.__START.mailinglists,
            countries : window.__START.countries,
            obj : window.__START.obj,
        }
    },
    methods : {
        getModifications() {
            var t = this;
            window.axios.get('/api/editorials/'+t.obj.id+'/modifications')
            .then(function(response){
                t.obj.modifications = response.data
            });
        },
        setObj(data) {
            if (data === null) {
                this.obj = null;
                if ( this.$route.path !== '/editorials' )
                    this.$router.push({ path: '/editorials' /*, params: { id: '123' } */ })
            }
            else {
                this.obj = data;
                this.getModifications();
                if ( this.$route.path !== '/editorials/'+data.id )
                    this.$router.push({ path: '/editorials/'+data.id })
            }
        },
        updateObj(data) {
            var t = this;
            // false = get back unmodified data from Server
            if (data === false) {
                window.axios.get('/api/editorials/'+t.obj.id)
                .then( function(response){
                    t.setObj(response.data)
                })
            } else {
                t.obj = Object.assign({}, t.obj, data)
                if ( Array.isArray(t.obj.email) ) {
                    t.obj.email.forEach((value,key) => {
                        if (value.indexOf(',') !== -1) {
                            t.obj.email = [ ...t.obj.email, ...value.split(',') ]
                            t.obj.email.splice(key, 1)
                        }
                        if (value.indexOf(';') !== -1) {
                            t.obj.email = [ ...t.obj.email, ...value.split(';') ]
                            t.obj.email.splice(key, 1)
                        }
                    })
                } else {
                    t.obj.email = t.obj.email.split(',')
                }
                t.obj.email = t.obj.email.map(s => s.trim());
                window.axios.post("/api/editorials/" + t.obj.id, t.obj)
                .then(function() {
                    t.getModifications()
                    window.notify('success', t.transUF('record updated') )
                })
            }
        },
        newObj() {
            var t = this;
            window.axios.post('/api/editorials/create')
            .then( function(response) {
                t.setObj(response.data)
                window.notify('success', t.transUF('new record created') )
            })
        },
        deleteObj() {
            var t = this;
            window.axios.post('/api/editorials/'+t.obj.id+'/delete')
            .then( function(){
                t.setObj(null)
                window.notify('success', t.transUF('record deleted') )
            })
        },
    }
}
</script>