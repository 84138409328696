<template>
	<div class="row">


        <div class="col-12">

            <object-topbar 
                :obj="obj" 
                :showDuplicator="true"
            >
            </object-topbar>

            <div class="card text-center">
                <div class="card-header">
                    Informazioni
                </div>
                <div class="card-block">

                    <div class="row">

                        <div class="col-md-4">

                            <div class="form-group row m-b-15">
                                <label class="col-form-label col-md-3">{{ 'name' | transUF }}</label>
                                <div class="col-md-9">
                                    <input type="text" 
                                        class="form-control m-b-5" 
                                        name="name" 
                                        placeholder="Descrizione breve per riconoscere la lista"
                                        v-debounce:700ms="updateObj"
                                        v-model="obj.name" />
                                </div>
                            </div>

                            <div class="form-group row m-b-15">
                                <label class="col-form-label col-md-3">{{ 'creator' | transUF }}</label>
                                <div class="col-md-9">
                                    <select 
                                        style="height:34px;"
                                        class="form-control" 
                                        name="user" 
                                        @change="updateObj"
                                        v-model="obj.user">
                                            <option v-for="(user, k) in users" 
                                                :value="user.name" 
                                                :key="user.name" 
                                                v-text="user.name" />
                                    </select>
                                </div>
                            </div>
                            
                        </div>

                        <div class="col-md-8">

                            <div class="form-group row m-b-15 m-l-20">
                                <h5 style="text-align:left">

                                    <span 
                                        v-if="this.$parent.filteringInProgress"
                                        style="font-size:1.2em; line-height:14px; width:80px; border: 1px solid grey; background-color: white; margin-right:8px;" 
                                        class="btn btn-default"
                                        >Ricalcolo..</span>

                                    <span 
                                        v-else
                                        style="font-size:1.2em; line-height:14px; width:80px; border: 1px solid #b73962; background-color: white; margin-right:8px;" 
                                        class="btn btn-default"
                                        >{{ this.editorials_count }}</span>
                                    
                                    {{ 'newspapers' | transUF }} {{ 'in this list' | trans }}.
                                </h5>
                            </div>

                            <div class="form-group row m-b-15 m-l-20">
                                <h5 style="text-align:left">

                                    <span 
                                        v-if="this.$parent.filteringInProgress"
                                        style="font-size:1.2em; line-height:14px; width:80px; border: 1px solid grey; background-color: white; margin-right:8px;" 
                                        class="btn btn-default"
                                        >Ricalcolo..</span>

                                    <span 
                                        v-else
                                        style="font-size:1.2em; line-height:14px; width:80px; border: 1px solid #b73962; background-color: white; margin-right:8px;" 
                                        class="btn btn-default"
                                        >{{ this.journalists_count }}</span>
                                    
                                    {{ 'journalists' | transUF }} {{ 'in this list' | trans }}.
                                </h5>
                            </div>
                            
                        </div>

                    </div>

                </div>
            </div>
        
        </div>

        <div class="col-md-12">

            <div class="card text-center">
                <div class="card-header">
                    Filtri
                </div>
                <div class="card-block">
                    <filters-list 
                        @newFilter="newFilter" 
                        @editFilter="editFilter" 
                        @updateObj="updateObj" 
                        @removeFilter="removeFilter"
                        ref="filtersList"
                    >
                    </filters-list>
                </div>
            </div>

            <transition name="fade">
                <div class="card text-center" v-show="currentFilterId !== null">
                    <div class="card-header">
                        Filtro # {{ currentFilterId }}
                        <span 
                            @click="currentFilterId = null; currentFilter = null;"
                            class="label bg-white pull-right" 
                            style="color: black;
                                height: 18px;
                                width: 18px;
                                padding: 4px;
                                cursor: pointer;">
                            <i class="fa fa-times"></i>
                        </span>
                    </div>
                    <div class="card-block clearfix">
                        <filter-show
                        :emptyFilter="$parent.emptyFilter"
                        @updateObj="updateObj"
                        ref="filterShow"
                        ></filter-show>
                    </div>
                </div>
            </transition>

        </div>
    
        <div class="col-md-12">

            <div class="card text-center">
                <div class="card-header">
                    Elenco totale
                </div>
                <div class="card-block">

                    <div class="row">
                        <div class="col-6">
                            <button class="btn btn-sm" :class="{'btn-success' : (journalists_editorials === 'editorials') }" style="width:100%" @click="journalists_editorials = 'editorials'">
                                {{ 'newspapers' | transUF }}
                            </button>
                            <br>
                            <br>
                        </div>
                        <div class="col-6">
                            <button class="btn btn-sm" :class="{'btn-success' : (journalists_editorials === 'journalists') }" style="width:100%" @click="journalists_editorials = 'journalists'" >
                                {{ 'journalists' | transUF }}
                            </button>
                            <br>
                            <br>
                        </div>
                    </div>

                    <div v-show="journalists_editorials === 'editorials'" class="clearfix">
                        <editorials-list 
                            ref="EditorialsList"
                            :obj="obj"
                        ></editorials-list>
                    </div>

                    <div v-show="journalists_editorials === 'journalists'" class="clearfix">
                        <journalists-list 
                            ref="JounalistsList"
                            :obj="obj"
                        ></journalists-list>
                    </div>
                    
                </div>
            </div>

        </div>

        <div class="col-md-12">

            <div class="card text-center">
                <div class="card-header">
                    {{ 'newsletters' | transUF }}
                </div>
                <div class="card-block">
                    <div class="row"><div class="col-12">
                    <div class="colspan">
                        <newsletters-list
                            :obj="obj"
                            @newObj="createNewsletter"
                        ></newsletters-list>
                    </div>
                    </div></div>
                </div>
            </div>

        </div>

	</div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import NewslettersList from './NewslettersList.vue'
import JournalistsList from './JournalistsList.vue'
import EditorialsList from './EditorialsList.vue'
import FiltersList from './FiltersList.vue'
import FilterShow from './FilterShow.vue'

export default {
    name : 'MailinglistsShow',
    props : ['journalists_count', 'editorials_count'],
    components: { 
        JournalistsList,
        EditorialsList,
        Multiselect, 
        NewslettersList, 
        FiltersList,
        FilterShow,
    },
    computed : {
        obj: { 
            get() { return this.$parent.obj },
            set(data) { this.$parent.obj = data }
        },
        users() { return this.$parent.users },
        newspapers_attributes() { return this.$parent.newspapers_attributes }
    },
    methods : {
        updateObj(data) {
            this.$emit('updateObj', data)
        },
        setObj(data) {
            this.$parent.setObj(data)
        },
        deleteObj() {
            this.$parent.deleteObj()
        },
        duplicateObj() {
            this.$parent.duplicateObj()
        },
        createNewsletter() {
            var t = this
            window.axios.post("/api/newsletters/create", {
                'mailinglist_id' : t.obj.id
            })
            .then(function(response) {
                if ( t.$route.path !== '/newsletters/'+response.data.id )
                    t.$router.push({ path: '/newsletters/'+response.data.id })
            })
        },
        newFilter() {
            var t = this
            t.currentFilter = null
            t.currentFilterId = null
            window.axios.post('/api/mailinglists/'+t.obj.id+'/filters/new')
            .then(function(response) {
                t.obj.filters.push(response.data)
                t.updateObj()
            })
        },
        refreshTables() {
            this.$refs.JounalistsList.$refs.GVueTable.refresh()
            this.$refs.EditorialsList.$refs.GVueTable.refresh()
            if (this.$refs.filterShow !== undefined && 
                this.$refs.filterShow.$refs.filterJournalists !== undefined)
                this.$refs.filterShow.$refs.filterJournalists.$refs.FilterJournalistsTable.refresh()
            if (this.$refs.filterShow !== undefined && 
                this.$refs.filterShow.$refs.filterIndividuals !== undefined && 
                this.$refs.filterShow.$refs.filterIndividuals.$refs.FilterIndividualsTable !== undefined )
                this.$refs.filterShow.$refs.filterIndividuals.$refs.FilterIndividualsTable.refresh()
        },
        editFilter(k) {
            if (k === null) {
                this.currentFilter = null
                this.currentFilterId = null
            } else {
                this.currentFilter = this.obj.filters[k]
                this.currentFilterId = k
            }
        },
        removeFilter(k) {
            this.obj.filters.splice(k, 1)
            this.$parent.updateObj(this.obj)
            this.currentFilter = null
            this.currentFilterId = null
        }
    },
    data() {
        return {
            currentFilter: null,
            currentFilterId : null,
            journalists_editorials : 'editorials'
        }
    },
    watch : {
    }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.multiselect__tags {
    font-size:13px;
    padding:4px 12px;
    min-height:34px;
}
.multiselect__select {
    min-height:34px;
    padding: 4px 12px;
}
.multiselect__option {
    min-height:30px;
    height:26px;
    font-size: 13px;
}
.multiselect {
    box-sizing: content-box;
    display: block;
    position: relative;
    width: 100%;
    min-height: 40px;
    text-align: left;
    color: #35495e
}
.multiselect__input,
.multiselect__single {
    min-height: 20px;
    line-height: 20px;
    padding: 0 0 0 5px;
}
.multiselect__input::-webkit-input-placeholder {
    color: #35495e
}
.multiselect__input:-ms-input-placeholder {
    color: #35495e
}
.multiselect__input::placeholder {
    color: #35495e
}
.multiselect__tag {
    padding: 4px 26px 4px 10px;
}
.multiselect__tag-icon:after {
    color: #A41A67;
}
.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
    background: #A41A67;
    color:white!important;
}
.multiselect__current {
    min-height: 26px;
    padding: 4px 12px 0;
    padding-right: 30px;
}
.multiselect__current,
.multiselect__select {
    line-height: 20px;
}
.multiselect__select:before {
    position: relative;
    right: 0;
    top: 65%;
    color: #999;
    margin-top: 4px;
    border-style: solid;
    border-width: 5px 5px 0;
    border-color: #999 transparent transparent;
    content: ""
}
.multiselect--active .multiselect__placeholder {
    display: none
}
.multiselect__option {
    padding: 4px 12px;
    min-height: 26px;
    line-height: 10px;
}
.multiselect__option:after {
    line-height: 20px;
}
.multiselect__option--highlight {
    background: #A41A67;
    color:white!important;
}
.multiselect__option--highlight:after {
    background: #A41A67;
    color:white!important;
}
[dir=rtl] .multiselect__tags {
    padding: 8px 8px 0 40px
}
.card .card-block {
    background-color:white!important;
    border:1px solid #e3e3e3!important;
    border-radius:4px!important;
    border-top:0px!important;
}
</style>