<template>
	<div>
        <div class="login login-v2" data-pageload-addclass="animated fadeIn" style="margin:100px 0 0 -300px; width:600px">
            <div class="login-header" style="width:600px; text-align:center;">
                <div class="brand">
                    <img src="/images/logo/BPRESS_MEDIABOOSTER_horizontal.svg" style="max-width:300px" />
                </div>
            </div>
            <div class="login-content" style="width:600px; text-align:left;">
                <br>

                <div v-if="!alreadyUnsubscribedAll">
                
                    <div v-if="customersName !== null">
                        <p>Gentile utente. Hai raggiunto questa pagina perché hai richiesto la cancellazione dalla nostra mailing list relativa a:</p>
                        <h4 v-text="customersName"></h4>
                        <br>
                        <p>
                            SEI SICURO DI NON VOLER PIÙ RICEVERE NEWS DI “
                            <b v-text="customersName"/>
                            ”?
                        </p>
                        <hr>
                    </div>
                    <div v-if="alreadyUnsubscribed">
                        <h4 style="color:#c64386"><i>Non si più iscritto a questa lista.</i></h4>
                        <p>Non è necessaria alcuna azione da parte tua.</p>
                    </div>

                    <div v-else>
                        <div class="form-group">
                            <input type="text" v-model="userEmail" class="form-control" @keyup.enter="checkEmail" placeholder="Inserisci il tuo indirizzo email">
                        </div>
                        <button type="button" class="btn btn-danger btn-sm" style="width:100%; text-align:center; height:40px" :disabled="!correspondentEmails" @click="checkEmail">CONFERMA LA CANCELLAZIONE</button>
                    </div>

                    <br>
                    <hr>
                    <br>

                </div>
                
                <div v-if="!alreadyUnsubscribedAll">
                    VUOI ESSERE RIMOSSO DEFINITIVAMENTE <b>DAL DATABASE BPRESS</b>?<br>
                    <br>
                </div>

                <div v-if="alreadyUnsubscribedAll">
                    <div v-if="nonExistentMessage">
                        <h4 style="color:#c64386"><i>Contatto non trovato.</i></h4>
                        <p>Utilizza il link presente in una email che hai ricevuto in precedenza da parte nostra.</p>
                    </div>
                    <div v-else>
                        <h4 style="color:#c64386"><i>Non si più iscritto al database BPress.</i></h4>
                        <p>Non è necessaria alcuna azione da parte tua.</p>
                    </div>
                </div>

                <div v-else>
                    <div class="form-group">
                        <input type="text" v-model="userEmailAll" class="form-control" @keyup.enter="checkEmailAll" placeholder="Inserisci il tuo indirizzo email">
                    </div>
                    <button type="button" class="btn btn-danger btn-sm" style="width:100%; text-align:center; height:40px" :disabled="!correspondentEmailsAll" @click="checkEmailAll">CANCELLAMI DA TUTTE LE LISTE</button>
                </div>
                
            </div>

        </div>
	</div>
</template>

<script>
export default {
    name : 'Unsubscribe',
    components : {
    },
    computed : {
        customersName() {
            if (this.customers === null)
                return null;

            var names = [];
            for (var i = 0; i < this.customers.length; i++)
                names.push(this.customers[i].name.toUpperCase())
            return names.join(' + ')
        },
        correspondentEmails() {
            return this.email.toLowerCase() === this.userEmail.toLowerCase()
        },
        correspondentEmailsAll() {
            return this.email.toLowerCase() === this.userEmailAll.toLowerCase()
        }
    },
    data() {
        return {
            customers : window.__START.customers,
            alreadyUnsubscribed : window.__START.alreadyUnsubscribed,
            alreadyUnsubscribedAll : window.__START.alreadyUnsubscribedAll,
            nonExistentMessage : window.__START.nonExistentMessage,
            email : window.__START.email,
            hash : window.__START.hash,
            userEmail : '',
            userEmailAll : '',
        }
    },
    methods : {
        checkEmail() {
            var t = this
            if (!this.correspondentEmails) {
                window.notify('error', "Attenzione. L'indirizzo inserito non corrisponde al destinatario di questa richiesta di disiscrizione")
            } else {
                window.axios.post('/'+t.hash+'/unsubscribe')
                .then(function() {
                    t.alreadyUnsubscribed = true
                })
            }
        },
        checkEmailAll() {
            var t = this
            if (!this.correspondentEmailsAll) {
                window.notify('error', "Attenzione. L'indirizzo inserito non corrisponde al destinatario di questa richiesta di disiscrizione")
            } else {
                window.axios.post('/'+t.hash+'/unsubscribe-all')
                .then(function() {
                    t.alreadyUnsubscribedAll = true
                })
            }
        }
    }
}
</script>

<style scoped>
.login .login-content {
    color:#525252
}
.login.login-v2 .form-control {
    background: rgb(255, 255, 255);
    border: 1px solid #b9b9b9;
    color:black;
    font-size:14px;
    height: 40px;
}
</style>