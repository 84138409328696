<template>
    <div class="tab-pane fade" v-bind:class="{ 'show active': this.$props.tabs.relations }">
        <h4 class="m-t-0 m-b-20">{{ 'relations' | transUC }}</h4>
        <hr>
        <table class="table table-striped">
            <thead>
                <tr>
                    <th style="width:160px"></th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td style="width:160px; vertical-align:middle">{{ 'categories' | transUF }}</td>
                    <td style="max-width:500px">
                        <Multiselect 
                            v-model="relations.categories"
                            :options="allRelations.categories"
                            label="name"
                            track-by="id"
                            :groupSelect="false"
                            :close-on-select="true"
                            multiple
                            @select="updateRelations"
                            @remove="updateRelations"
                        ></Multiselect>
                    </td>
                </tr>
                <tr>
                    <td style="width:160px; vertical-align:middle">{{ 'genres' | transUF }}</td>
                    <td style="max-width:500px">
                        <Multiselect 
                            v-model="relations.genres"
                            :options="allRelations.genres"
                            label="name"
                            track-by="id"
                            :groupSelect="false"
                            :close-on-select="true"
                            multiple
                            @select="updateRelations"
                            @remove="updateRelations"
                        ></Multiselect>
                    </td>
                </tr>
                <tr v-for="(attribute, k) in allRelations.attributes" :key="k">
                    <td style="width:160px; vertical-align:middle" v-text="attribute.name"></td>
                    <td style="max-width:500px">
                        <Multiselect 
                            v-model="relations.attributes[attribute.id]"
                            :options="attribute.children"
                            label="name"
                            track-by="id"
                            :groupSelect="false"
                            :close-on-select="true"
                            multiple
                            @select="updateRelations"
                            @remove="updateRelations"
                        ></Multiselect>
                    </td>
                </tr>
            </tbody>
        </table>
        
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
export default {
    name : 'Relations',
    props : [ 'tabs' ],
    computed : {
        obj() { return this.$parent.obj }
    },
    components : {
        Multiselect,
    },
    mounted() {
        var t = this
        window.axios.get('/api/editorials/all-relations')
        .then(function(response) {
            t.allRelations = response.data
        })
        window.axios.get('/api/editorials/'+t.obj.id+'/relations')
        .then(function(response) {
            t.relations = response.data
        })
    },
    data() {
        return {
            allRelations : {
                'categories' : [],
                'genres' : [],
                'attributes' : []
            },
            relations : {
                categories : [],
                genres : [],
                attributes : [],
            }
        }
    },
    methods : {
        updateRelations() {
            var t = this
            window.axios.post('/api/editorials/'+t.obj.id+'/relations', t.relations)
        }
    }
    
}
</script>

<style scoped>
.semichecked { 
    color:red!important;
}
.multiselect-td {
    padding: 2px 10px;
}
.multiselect {
    min-height: 28px;
}
.multiselect__tag {
    display:block;
}
</style>