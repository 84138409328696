import Vue from 'vue'
var vue = new Vue({})
window.notify = function(type, message, duration = 2000) {
    var notifyClass = 'default';
    
    if (type === 'warning')
        notifyClass = 'warn';
    if (type === 'error')
        notifyClass = 'error'
    if (type === 'success')
        notifyClass = 'success'
    if (type === 'info')
        notifyClass = 'info'
    
    Vue.notify({
        group: 'main',
        type : notifyClass,
        title: vue.transUF(type),
        text: message+'.',
        duration: duration
    });
}