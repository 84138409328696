window._ = require('lodash');
window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
let token = document.head.querySelector('meta[name="csrf-token"]');
if (token)
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
else
    alert('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');

import Vue from 'vue'
import Routes from './routes.js';

import VueRouter from 'vue-router'
Vue.use(VueRouter)

// if (process.env.MIX_APP_ENV === 'production') {
//     Vue.config.devtools = false;
//     Vue.config.debug = false;
//     Vue.config.silent = true; 
// }

window.$ = window.jQuery = require('jquery');
window.jQuery.extend(true, window.jQuery.fn.datetimepicker.defaults, {
    icons: {
      time: 'far fa-clock',
      date: 'far fa-calendar',
      up: 'fas fa-arrow-up',
      down: 'fas fa-arrow-down',
      previous: 'fas fa-chevron-left',
      next: 'fas fa-chevron-right',
      today: 'fas fa-calendar-check',
      clear: 'far fa-trash-alt',
      close: 'far fa-times-circle'
    }
});

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import 'nvd3/build/nv.d3.min.css'
import 'vue-event-calendar/dist/style.css'
import 'vue-hljs/dist/vue-hljs.min.css'
import '@agametov/vueditor/dist/style/vueditor.min.css'
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
// import 'vue-good-table/dist/vue-good-table.css'
import 'fullcalendar/dist/fullcalendar.css'

import './../../css/default/style.css'
import './../../css/default/style-responsive.css'
import './../../css/default/theme/default.css'
import './../../css/style.css'

import VueBootstrap from 'bootstrap-vue'
import VueSweetalert2 from 'vue-sweetalert2'
import VueNotification from 'vue-notification'
import VuePanel from './plugins/panel/'
// import VueGoodTable from 'vue-good-table';
import VTooltip from 'v-tooltip'
import VueMoment from 'vue-moment'
import VueInputAutowidth from 'vue-input-autowidth'

import vueDebounce from 'vue-debounce'
Vue.use(vueDebounce, { lock: false, listenTo: ['onkeyup', 'oninput'], defaultTime: '700ms' })

Vue.use(VueBootstrap)
Vue.use(VueSweetalert2)
Vue.use(VueNotification)
Vue.use(VuePanel)
// Vue.use(VueGoodTable)
Vue.use(VTooltip)
Vue.use(VueMoment)
Vue.use(VueInputAutowidth)

require('./plugins/vue-trans.js')
require('./plugins/vue-lazyload-component.js')
require('./plugins/bing-search.js')
// notify: window.notify(type, message). types = [warning, error, success, info]
require('./plugins/notify.js')

import "prismjs";
import VuePrismEditor from "vue-prism-editor";
import "prismjs/themes/prism-tomorrow.css";
import "vue-prism-editor/dist/VuePrismEditor.css";
Vue.component("prism-editor", VuePrismEditor);
import VueSelect from 'vue-select'

Vue.component('v-select', VueSelect);
import Deleter from './components/Deleter.vue'
Vue.component("deleter", Deleter);
import Duplicator from './components/Duplicator.vue'
Vue.component("duplicator", Duplicator);
import Multiselect from "./components/Multiselect.vue";
Vue.component("Multiselect", Multiselect);
import GVueTable from './components/GVueTable.vue'
Vue.component("g-vue-table", GVueTable);
import ObjectTopbar from './components/ObjectTopbar.vue'
Vue.component("object-topbar", ObjectTopbar)
import SentInPeriod from './components/charts/SentInPeriod.vue'
Vue.component("sent-in-period", SentInPeriod);
import NewsletterPie from './components/charts/NewsletterPie.vue'
Vue.component("newsletter-pie", NewsletterPie);
import BulkDeleteModal from './components/BulkDeleteModal.vue'
Vue.component("bulk-delete-modal", BulkDeleteModal);

import PageHeader from './components/header/Header.vue'
import Sidebar from './components/sidebar/Sidebar.vue'
import Footer from './components/footer/Footer.vue'
import PageOptions from './config/PageOptions.vue'
import Breadcrumb from './components/header/Breadcrumb.vue'

import Login from './pages/Login.vue'
// import Dashboard from './pages/Dashboard.vue'
// import Journalists from './pages/Journalists.vue'
// import Publishers from './pages/Publishers.vue'
import Unsubscribe from './pages/Unsubscribe.vue'
// import Editorials from './pages/Editorials.vue'
// import Attributes from './pages/Attributes.vue'
// import Templates from './pages/Templates.vue'
// import Newsletters from './pages/Newsletters.vue'
// import Mailinglists from './pages/Mailinglists.vue'
// import Customers from './pages/Customers.vue'
// import Imports from './pages/Imports.vue'
// import Indicators from './pages/Indicators.vue'
// import SendingDenied from './pages/SendingDenied.vue'
// import EmailVerifications from './pages/EmailVerifications.vue'

window.vm = new Vue({
    data : {
        pageOptions : PageOptions,
        pageTitle : '',
        env_local : process.env.MIX_APP_URL.includes('local'),
        auth_user : window.auth_user,
        loader : 1,
    },
    router: Routes,
    components : {
        PageHeader,
        Breadcrumb,
        Sidebar,
        Footer,

        Login,
        Unsubscribe,
        // Dashboard,
        // Journalists,
        // Publishers,
        // Unsubscribe,
        // Editorials,
        // Attributes,
        // Templates,
        // Newsletters,
        // Mailinglists,
        // Customers,
        // Imports,
        // Indicators,
        // SendingDenied,
        // EmailVerifications
    },
}).$mount('#business-press')