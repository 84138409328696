<template>
    <div class="tab-pane fade" v-bind:class="{ 'show active': this.$props.tabs.journalists }">
        
        <g-vue-table
        :apiIndexUrl="'/api/publishers/'+obj.id+'/journalists'"
        sortBy="first_name"
        sortDirection="asc"
        :columns="columns"
        :obj="obj"
        ></g-vue-table>

    </div>
</template>

<script>
export default {
    name : 'JournalistsList',
    props : [ 'tabs', 'obj' ],
    methods : {
        redirect(url) {
            if ( this.$route.path !== url )
                this.$router.push({ path: url })
        }
    },
    data(){
        return {
            columns : [{
                title: '#',
                name: 'id',
                sortField: 'id',
                titleClass: 'text-right medium-cell',
                dataClass: 'text-right medium-cell clickable',
                cellClicked : function(t, data) {
                    window.open('/#/journalists/'+data.id)
                },
                visible : true,
            },{
                title : this.transUC('name'),
                name: '_companyname',
                sortField: '_companyname',
                dataClass: 'clickable',
                cellClicked : function(t, data) {
                    window.open('/#/journalists/'+data.id)
                },
                visible : true,
            },{
                title : this.transUC('email'),
                name: '_emails',
                sortField: '_emails',
                dataClass: '',
                visible : true,
                callback : function(data) {
                    if (data === null)
                        return null
                    data = (new String(data)).split(',')
                    var result = [];
                    for (var i=0;i<data.length;i++) {
                        result.push( '<a href="mailto:'+data[i]+'">'+data[i]+'</a>' )
                    }
                    return result.join(' + ')
                }
            },{
                title : this.transUC('telephone'),
                name: 'telephone',
                sortField: 'telephone',
                dataClass: '',
                visible : true,
            },{
                title : this.transUC('mobile'),
                name: 'mobile',
                sortField: 'mobile',
                dataClass: '',
                visible : true,
            },{
                title : this.transUC('newspapers'),
                name: '_editorials',
                sortField: '_editorials',
                visible : true,
                callback : function(data) {
                    if (data === null)
                        return null
                    data = (new String(data)).split('||')
                    var result = [];
                    for (var i=0;i<data.length;i++) {
                        var pattern = /\[(.*?)\]/g;
                        var match;
                        while ((match = pattern.exec(data[i])) != null) {
                            // id = match[1]
                            var str = '<a href="/#/editorials/'+match[1]+'" target="_blank">'+data[i].replace(match[0], '')+'</a>'
                            result.push( str )
                        }
                    }
                    return result.join(' + ')
                }
            },{
                title : this.transUC('offices'),
                name: '_offices',
                sortField: '_offices',
                visible : true,
                callback : function(data) {
                    if (data === null)
                        return null
                    data = (new String(data)).split('||')
                    var result = [];
                    for (var i=0;i<data.length;i++) {
                        var pattern = /\[(.*?)\]/g;
                        var match;
                        while ((match = pattern.exec(data[i])) != null) {
                            // id = match[1]
                            var str = data[i].replace(match[0], '')
                            result.push( str )
                        }
                    }
                    return result.join(' + ')
                },
            },{
                title : this.transUC('roles'),
                name: '_roles',
                sortField: '_roles',
                visible : true,
                callback : function(data) {
                    if (data === null)
                        return null
                    data = (new String(data)).split('||')
                    var result = [];
                    for (var i=0;i<data.length;i++) {
                        var pattern = /\[(.*?)\]/g;
                        var match;
                        while ((match = pattern.exec(data[i])) != null) {
                            // id = match[1]
                            var str = data[i].replace(match[0], '')
                            result.push( str )
                        }
                    }
                    return result.join(' + ')
                },
            },{
                title : this.transUC('city'),
                name: 'city',
                sortField: 'city',
                dataClass: '',
                visible : true,
            },{
                title : this.transUC('updated'),
                name: 'updated_at',
                sortField: 'updated_at',
                titleClass: 'text-center updated-at-cell',
                dataClass: 'text-right updated-at-cell',
                visible : true
            }],
        }
    }
}
</script>