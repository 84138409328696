<template>
	<div>
        <g-vue-table
        :apiIndexUrl="'/api/customers/'+obj.id+'/newsletters'"
        sortBy="name"
        :columns="columns"
        :obj="obj"
        ></g-vue-table>
	</div>
</template>

<script>
export default {
    name : 'NewslettersList',
    computed : {
        obj() { return this.$parent.obj }
    },
    methods : {
    },
    data(){
        return {
            columns : [{
                title: '#',
                name: 'id',
                sortField: 'id',
                titleClass: 'text-right medium-cell',
                dataClass: 'text-right medium-cell',
                visible : true,
            },{
                title : this.transUC('name'),
                name: 'name',
                sortField: 'name',
                dataClass: 'clickable',
                cellClicked : function(t, data, field, event) {
                    window.open('/#/newsletters/'+data.id)
                },
                visible : true,
            },{
                title : this.transUC('updated'),
                name: 'updated_at',
                sortField: 'updated_at',
                titleClass: 'text-center updated-at-cell',
                dataClass: 'text-right updated-at-cell',
                visible : true,
            }],
        }
    }
}
</script>