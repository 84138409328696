<template>
    <div class="tab-pane fade" v-bind:class="{ 'show active': this.$props.tabs.history }">
        <ul class="timeline" v-if="obj.messages !== undefined && obj.messages.length > 0">
            <li v-for="(message, k) in obj.messages" :key="k">
                <div class="timeline-time">
                    <span class="time">{{ message.createdAt | moment('DD/MM/YY') }}</span>
                    <span class="date">{{ message.createdAt | moment('HH:mm') }}</span>
                </div>
                <div class="timeline-icon">
                    <a href="javascript:;">&nbsp;</a>
                </div>
                <div class="timeline-body">
                    <div class="timeline-header">
                        <span class="username">
                            <small v-html="messageAttributes(message)"></small>
                        </span>
                    </div>
                </div>
            </li>
        </ul>
        <div v-else>
            <i>Nessun messaggio è stato ancora inviato a questo utente</i>
        </div>
    </div>
</template>


<script>
export default {
    name : 'History',
    props : [ 'tabs' ],
    computed : {
        obj() { return this.$parent.obj }
    },
    methods : {
        messageAttributes(message) {
            var text = '<h5 style="cursor:pointer;" onclick="window.open(\'/#/newsletters/'+message.newsletter_id+'\')">'+message.newsletter_name+'</h5>'

            if (message.delivered == 1)
                text += '<div style="margin-top:-10px"><i class="fa fa-check-square"></i> Consegnato</div>'
            // else
                // text += '<div style="margin-top:-10px"><i class="fa fa-square-full"></i> Consegnato</div>'

            else if (message.rejected == 1)
                text += '<div style="margin-top:-10px"><i class="fa fa-check-square"></i> Rifiutato</div>'
            // else
                // text += '<div style="margin-top:-10px"><i class="fa fa-square-full"></i> Rifiutato</div>'

            else if (message.open == 1)
                text += '<div style="margin-top:-10px"><i class="fa fa-check-square"></i> Aperto</div>'
            // else
                // text += '<div style="margin-top:-10px"><i class="fa fa-square-full"></i> Aperto</div>'

            else if (message.clicked == 1)
                text += '<div style="margin-top:-10px"><i class="fa fa-check-square"></i> Cliccato</div>'
            // else
                // text += '<div style="margin-top:-10px"><i class="fa fa-square-full"></i> Cliccato</div>'

            else if (message.complained == 1)
                text += '<div style="margin-top:-10px"><i class="fa fa-check-square"></i> Spam</div>'
            // else
                // text += '<div style="margin-top:-10px"><i class="fa fa-square-full"></i> Spam</div>'

            else if (message.bounced == 1)
                text += '<div style="margin-top:-10px"><i class="fa fa-check-square"></i> Errore</div>'
            // else
                // text += '<div style="margin-top:-10px"><i class="fa fa-square-full"></i> Errore</div><br>'
            else
                text += '<div style="margin-top:-10px"><i class="fa fa-check-square"></i> Inviato</div>'

            return text;
        }
    }
}
</script>