<template>
	<div>
        <g-vue-table
            :apiIndexUrl="'/api/mailinglists/'+obj.id+'/newsletters'"
            sortBy="name"
            :columns="columns"
            ref="GVueTableNewsletters"
            :obj="null"
            :filtered="false"
            @newObj="$emit('newObj')"
        ></g-vue-table>
	</div>
</template>

<script>
export default {
    name : 'NewslettersList',
    computed: {
        obj() { return this.$parent.$parent.obj }
    },
    methods : {
        onPaginationData (paginationData) {
            this.$refs.pagination.setPaginationData(paginationData)
            this.$refs.paginationInfo.setPaginationData(paginationData)
        },
        onChangePage (page) {
            this.$refs.vuetable.changePage(page)
        },
        setFilter (filterText) {
            if (filterText === '')
                this.moreParams = {}
            else
                this.moreParams = { filter: filterText }
            
            Vue.nextTick( () => this.$refs.vuetable.refresh() )
        }
    },
    data(){
        return {
            columns : [{
                name: '__checkbox',
                titleClass: 'text-center short-cell',
                dataClass: 'text-center short-cell',
            },{
                title: '#',
                name: 'id',
                titleClass: 'text-right medium-cell',
                dataClass: 'text-right medium-cell'
            },{
                title : this.transUC('name'),
                name: 'name',
                sortField: 'name',
                dataClass: 'clickable'
            },{
                title : this.transUC('subject'),
                name: 'subject',
                sortField: 'subject',
                dataClass: 'clickable'
            },{
                title : this.transUC('delivery'),
                name: 'send_start',
                sortField: 'send_start',
                dataClass: 'clickable'
            }],
            moreParams : {}
        }
    }
}
</script>