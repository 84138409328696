<template>
    <div>
        
        <object-topbar 
            :obj="obj" 
        >
        </object-topbar>

        <div class="profile">
            <div class="profile-header">
                <div class="profile-header-cover"></div>
                <div class="profile-header-content">
                <div class="profile-header-img">
                    <div
                    style="width:113px; height:113px; background-size:cover; background-position:top"
                    :style="{ backgroundImage : 'url('+avatar+')' }"
                    >
                    <button
                        type="button"
                        class="btn btn-primary btn-rounded btn-cancel"
                        v-show="toSetAvatar"
                        @click="cancelAvatarChange"
                    >
                        <i class="fa fa-ban"></i>
                    </button>
                    <button
                        type="button"
                        class="btn btn-primary btn-rounded btn-confirm"
                        v-show="toSetAvatar"
                        @click="confirmAvatarChange"
                    >
                        <i class="fa fa-check"></i>
                    </button>
                    </div>
                </div>
                <div class="profile-header-info">
                    <h4 class="m-t-10 m-b-5" v-text="obj.companyname"></h4>
                    <p class="m-b-10">
                        <br>
                    </p>
                </div>
                </div>
                <ul class="profile-header-tab nav nav-tabs">
                    <li class="nav-item" v-show="hideInEditorials.indexOf('about_all') < 0">
                        <a
                        href="javascript:;"
                        v-on:click="showTab('about')"
                        v-bind:class="{ 'active': tabs['about'] }"
                        class="nav-link"
                        data-toggle="tab"
                        >{{ 'about' | transUC }}</a>
                    </li>
                    <li class="nav-item" v-show="hideInEditorials.indexOf('relations_all') < 0">
                        <a
                        href="javascript:;"
                        v-on:click="showTab('relations')"
                        v-bind:class="{ 'active': tabs['relations'] }"
                        class="nav-link"
                        data-toggle="tab"
                        >{{ 'relations' | transUC }}</a>
                    </li>
                    <li class="nav-item" v-show="hideInEditorials.indexOf('journalists_all') < 0">
                        <a
                        href="javascript:;"
                        v-on:click="showTab('journalists')"
                        v-bind:class="{ 'active': tabs['journalists'] }"
                        class="nav-link"
                        data-toggle="tab"
                        >{{ 'journalists' | transUC }}</a>
                    </li>
                </ul>
            </div>
            </div>
            <div class="profile-content">
            <div class="tab-content p-0">
                
                <about 
                    :tabs="tabs"
                    :provinces="provinces"
                    :regions="regions"
                    :countries="countries"
                    :publishers="publishers"
                    :periodicities="periodicities"
                    @updateObj="updateObj"
                ></about>

                <!-- <Pictures
                    :tabs="tabs"
                    :obj="obj"
                    :images="images"
                    @updateObj="updateObj"
                    @setAvatar="setAvatar"
                ></Pictures> -->

                <!-- <Web
                    :tabs="tabs"
                    :obj="obj"
                    :webPages="webPages"
                    @updateObj="updateObj"
                ></Web> -->
                
                <journalists-list
                    :tabs="tabs"
                    :obj="obj"
                    @updateObj="updateObj"
                ></journalists-list>

                <Relations
                    :tabs="tabs"
                    :obj="obj"
                    @updateObj="updateObj"
                     v-show="hideInEditorials.indexOf('relations_all') < 0">
                ></Relations>

            </div>
        </div>
    </div>
</template>
<script>
import About from './show/About.vue'
import Web from './show/Web.vue'
import Pictures from './show/Pictures.vue'
import Relations from './show/Relations.vue'
import JournalistsList from './show/JournalistsList.vue'

const imageExists = require("image-exists");
export default {
    name: 'EditorialShow',
    props: [ 'provinces', 'regions', 'countries', 'publishers', 'periodicities' ],
    components : {
        About,
        Relations,
        History,
        Web,
        Pictures,
        JournalistsList,
    },
    computed : {
        obj: {
            get() { return this.$parent.obj },
            set(val) { this.$parent.obj = val }
        },
        avatar: {
            get() { return this.$parent.obj.avatar },
            set(val) { this.$parent.obj.avatar = val }
        }
    },
    mounted() {
        // this.bingSearchImages( 
        //     this.obj.companyname, 
        //     this.pushToImages
        // ),
        // this.bingSearchWeb( 
        //     this.obj.companyname, 
        //     this.pushToWebPages
        // ) 
    },
    data() {
        return {
            hideInEditorials: process.env.MIX_HIDE_IN_EDITORIALS ? process.env.MIX_HIDE_IN_EDITORIALS.split('|') : [],
            showArea: process.env.MIX_EDITORIAL_SHOW_AREA,
            images: [],
            webPages: [],
            toSetAvatar: false,
            tabs: {
                about: true,
                relations: false,
                journalists: false,
                // web: false,
                // pictures : false,
            }
        };
    },
    methods: {
        updateObj(data) {
            this.$emit('updateObj', data);
        },
        setObj(data) {
            this.$parent.setObj(data)
        },
        deleteObj() {
            this.$parent.deleteObj()
        },
        pushToImages(images) {
            var t = this;
            images.forEach(function(im) {
                var image = {
                    url: im.thumbnailUrl,
                    name: im.name
                };
                imageExists(image.url, function(exists) {
                    if (exists) {
                        var can = true;
                        t.images.forEach(function(existent) {
                            if (existent.url == image.url) can = false;
                        });
                        if (can) t.images.push(image)
                    }
                });
            });
        },
        pushToWebPages(pages) {
            this.webPages = pages
        },
        setAvatar(imageUrl) {
            this.avatar = imageUrl;
            if (this.obj.avatar !== this.avatar)
                this.toSetAvatar = true
        },
        confirmAvatarChange() {
            this.$emit('updateObj', { avatar : this.avatar })
            this.toSetAvatar = false
        },
        cancelAvatarChange() {
            this.avatar = this.obj.avatar;
            this.toSetAvatar = false
        },
        showTab(x) {
            var t = this;
            Object.keys(t.tabs).map(function(key) {
                t.tabs[key] = false
            });
            t.tabs[x] = true;
        }
    }
};
</script>