<template>
	<div>
        <b-modal id="modalRemoveFromLister" 
            ref="modalRemoveFromLister"
            size="md" 
            :title="transUF('remove from lists')"
            @ok="removeFromList"
        >
            <Multiselect 
                v-model="selectedMailinglists"
                :options="mailinglists"
                label="name"
                track-by="id"
                :groupSelect="false"
                :close-on-select="true"
                multiple
            ></Multiselect>
        </b-modal>
	</div>
</template>

<script>
export default {
    name : 'RemoveFromLister',
    props : [ 'removableRef', 'mailinglists' ],
    methods : {
        show(ids) {
            this.ids = ids
            this.$refs.modalRemoveFromLister.show()
        },
        removeFromList() {
            var t = this
            var mailinglists = []
            for (var i = 0; i<t.selectedMailinglists.length; i++)
                mailinglists.push(t.selectedMailinglists[i].id)
            window.axios.post('/api/journalists/remove-from-lists', {
                ids : t.ids,
                mailinglists : mailinglists
            }).then(function() {
                if (t.ids.length === 1)
                    window.notify('success', 'Oggetto rimosso dalle liste selezionate')
                else
                    window.notify('success', 'Oggetti rimossi dalle liste selezionate')
                t.ids = []
                t.selectedMailinglists = []
                t.$parent.$refs[t.removableRef].refresh()
            })
        }
    },
    data() {
        return {
            ids : [],
            selectedMailinglists : []
        }
    }
}
</script>