<template>
    <div>
        <div class="card">
            <div class="card-header" v-text="transUF('services monitoring')"></div>
            <div class="card-block">
                <div class="table">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Stato</th>
                                <th>Servizio</th>
                                <th>Info</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(service,k) in services" :key="k">
                                <td style="width:60px; vertical-align:middle;">
                                    <img v-if="service.status === null" src="/images/loading.gif" style="width:20px; height:20px;" />
                                    <a v-if="service.status === 1" href="javascript:;" class="btn btn-success btn-icon btn-circle btn-lg" title="IN LINEA"><i class="fa fa-check"></i></a>
                                    <a v-if="service.status === -1" href="javascript:;" class="btn btn-warning btn-icon btn-circle btn-lg" title="IN PAUSA"><i class="fa fa-minus"></i></a>
                                    <a v-if="service.status === 0" href="javascript:;" class="btn btn-danger btn-icon btn-circle btn-lg" title="NON RAGGIUNGIBILE"><i class="fa fa-times"></i></a>
                                </td>
                                <td class="text-left" style="vertical-align:middle;"><h4 style="margin-bottom:0px;">{{ service.name }}</h4></td>
                                <td class="text-left" style="vertical-align:middle" v-html="service.info"></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name : 'Services',
    mounted() {
        this.getServerInfo()
    },
    data() {
        return {
            services : {
                'ram' : { name : 'RAM', status : null },
                'database' : { name : 'Database', status : null },
                'backups' : { name : 'Backups', status : null },
                'storage' : { name : 'Storage', status : null },
                'scheduled_tasks' : { name : 'Ottimizzazioni pianificate', status : null },
                'verify_email' : { name : 'Verify-Email', status : null },
                'emails_amazon' : { name : 'Email Amazon', status : null },
                'emails_sendgrid' : { name : 'Email Sendgrid', status : null },
                'emails_sparkpost' : { name : 'Email Sparkpost', status : null }
            }
        }
    },
    methods : {
        getServerInfo() {
            window.axios.get('/server-info')
            .then(response => {
                for (var key in response.data) {
                    this.services[key].status = response.data[key].status,
                    this.services[key].info = response.data[key].info
                }
            })
        }
    }
}
</script>