<template>
    <div class="col-12">

        <object-topbar 
            :obj="obj" 
        >
        </object-topbar>

        <div>
            <input 
                type="text" 
                class="form-control" 
                style="max-width:300px"
                v-model="obj.name" 
                placeholder="Nome template" 
                v-debounce:600ms="updateObj"
            />
            <br>
        </div>
        
        <div id="bee-plugin-container" style="width:100%; height:100vh;">
            <div id="bee-plugin"></div>
        </div>
        <b-modal id="sendModal" 
            ref="sendModal"
            :title="transUF('send test email')"
            @ok="sendTestEmail"
        >
            <label>Inserisci qui gli indirizzi email che riceveranno il messaggio di prova, separati da virgola</label>
            <b-input type="text" v-model="testEmail.emails"  />
        </b-modal>
        <br><br>
    </div>
</template>

<script>
import Bee from 'bee-plugin'
export default {
    name: 'TemplateShow',
    components : {
    },
    mounted() {
        var t = this
        var json = JSON.parse(t.obj.json)
        var BEE = new Bee(window.__START.bee_token)
        BEE.start(this.beeConfig, json )
        // .then((bee) => bee.load(t.obj.content))
    },
    computed : {
        obj: {
            get() { return this.$parent.obj },
            set(val) { this.$parent.obj = val}
        },
        testEmail() {
            return {
                emails : window.auth_user.email,
                content : this.obj.html
            }
        }
    },
    data() {
        return {
            beeConfig : {
                uid: 'bpress-demo',
                container: 'bee-plugin',
                autosave: 0,
                language: window.locale === 'it' ? 'it-IT' : 'en-EN',
                onSave: (jsonFile, htmlFile) => {
                    this.BeeOnSave(jsonFile, htmlFile)
                },
                onAutoSave: (jsonFile) => {
                    // console.log('onAutoSave', jsonFile)
                },
                onSaveAsTemplate: (jsonFile) => {
                    this.BeeOnSaveAsTemplate(jsonFile)
                    // console.log('onSaveAsTemplate', jsonFile)
                },
                onSend: (htmlFile) => {
                    this.BeeOnSend(htmlFile)
                    // console.log('onSend', htmlFile)
                },
                onError: (errorMessage) => {
                    console.log('onError ', errorMessage)
                }
            }
        };
    },
    methods: {
        updateObj() {
            this.$emit('updateObj')
        },
        BeeOnSave(jsonFile, htmlFile) {
            this.obj.json = jsonFile
            this.obj.html = htmlFile
            this.$emit('updateObj')
        },
        BeeOnSaveAsTemplate(jsonFile) {
            var t = this;
            window.axios.post('/api/templates/create', {
                'name' : t.obj.name,
                'json' : jsonFile
            }).then(function(response) {
                window.notify('success', 'Nuovo template creato.')
                t.$emit('setObj', null)
                t.$emit('updateTemplates')
            })
        },
        BeeOnSend(htmlFile) {
            this.testEmail.content = htmlFile
            this.$refs.sendModal.show()
        },
        sendTestEmail() {
            var t = this;
            window.axios.post('/api/templates/'+t.obj.id+'/test', {
                'emails' : t.testEmail.emails,
                'html' : t.testEmail.content
            }).then(function() {
                window.notify('success', 'Email di prova inviata')
            })
        },
        deleteObj() {
            this.$emit('deleteObj')
        },
        setObj(data) {
            this.$emit('setObj', data)
        }
    }
};
</script>

<style scoped>
    #bee-plugin {
        height:100vh;
    }
</style>