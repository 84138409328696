import Vue from 'vue'

Vue.mixin({
    methods: {
        bingSearchImages(text, callback) {
            window.axios
                .get("https://api.cognitive.microsoft.com/bing/v7.0/images/search", {
                    params: {
                        q: text,
                        //imageType: "Photo",
                        license: "Any",
                        minHeight: 200,
                        minWidth: 200,
                        count: 30
                    },
                    headers: {
                        "Ocp-Apim-Subscription-Key": "190a56a3bb154ae1812dbc9d9b95f3c4"
                    }
                })
                .then(function(response) {
                    if (response.data.value !== undefined) 
                        return callback(response.data.value);
                    else
                        return callback([]);
                })
        },
        bingSearchWeb(text, callback) {
            window.axios
                .get("https://api.cognitive.microsoft.com/bing/v7.0/search", {
                    params: {
                        q: text,
                        count: 30
                    },
                    headers: {
                        "Ocp-Apim-Subscription-Key": "190a56a3bb154ae1812dbc9d9b95f3c4"
                    }
                })
                .then(function(response) {
                    if (response.data.webPages !== undefined)
                        return callback(response.data.webPages.value);
                    else
                        return callback([]);
                });
        }
    }
})