<template>
	<div>
        <h4>{{ 'logs' | transUF }}</h4>
        <g-vue-table
            ref="emailVerificationLogsTable"
            :apiIndexUrl="'/api/email-verification-logs'"
            sortBy="email"
            :columns="columns"
            :creable="false"
            :actionsDropdown="actionsDropdown"
        ></g-vue-table>
	</div>
</template>

<script>
export default {
    name : 'EmailVerificationLogs',
    data(){
        return {
            columns : [{
                title: '#',
                name: 'id',
                sortField: 'id',
                titleClass: 'text-right medium-cell',
                dataClass: 'text-right medium-cell',
                visible : true,
            },{
                title : 'EMAIL',
                name: 'email',
                sortField: 'email',
                visible : true,
            },{
                title : this.transUC('action'),
                name: 'action',
                sortField: 'action',
                visible : true,
            },{
                title : this.transUC('user'),
                name: 'user_name',
                sortField: 'user_name',
                visible : true,
            },{
                title : this.transUC('description'),
                name: 'description',
                sortField: 'description',
                visible : true,
            },{
                title : this.transUC('created'),
                name: 'created_at',
                sortField: 'created_at',
                titleClass: 'text-center created-at-cell',
                dataClass: 'text-right created-at-cell',
                visible : true,
            }]
        }
    },
}
</script>