<template>
    <div>
        <div class="row">

			<div class="col-md-12">
				<div class="row">
					<div class="col-3">
						<div class="widget widget-stats bg-gradient-purple" @click="redirect('/journalists')" style="cursor:pointer">
							<div class="stats-icon stats-icon-lg"><i class="fa fa-users fa-fw"></i></div>
							<div class="stats-content">
								<div class="stats-title">{{ 'tot. journalists' | transUC }}</div>
								<div class="stats-number" v-text="stats1.journalistsCount"></div>
							</div>
						</div>
					</div>
					<div class="col-3">
						<div class="widget widget-stats bg-gradient-blue" @click="redirect('/newsletters')" style="cursor:pointer">
							<div class="stats-icon stats-icon-lg"><i class="fa fa-envelope fa-fw"></i></div>
							<div class="stats-content">
								<div class="stats-title">{{ 'from Medias' | transUC }}</div>
								<div class="stats-number" v-text="stats1.mediasJournalistsCount"></div>
							</div>
						</div>
					</div>
                    <div class="col-3">
						<div class="widget widget-stats bg-gradient-teal" @click="redirect('/newsletters')" style="cursor:pointer">
							<div class="stats-icon stats-icon-lg"><i class="fa fa-envelope fa-fw"></i></div>
							<div class="stats-content">
								<div class="stats-title">{{ 'not Medias' | transUC }}</div>
								<div class="stats-number" v-text="stats1.notMediasJournalistsCount"></div>
							</div>
						</div>
					</div>
                    <div class="col-3">
						<div class="widget widget-stats bg-gradient-black" @click="redirect('/newsletters')" style="cursor:pointer">
							<div class="stats-icon stats-icon-lg"><i class="fa fa-envelope fa-fw"></i></div>
							<div class="stats-content">
								<div class="stats-title">Crediti verify-email residui</div>
								<div class="stats-number" v-text="verifyEmail"></div>
							</div>
						</div>
					</div>
				</div>
			</div>

            <div class="col-md-12">
				<div class="row">
					<div class="col-3">
						<div class="widget widget-stats bg-gradient-purple" @click="redirect('/journalists')" style="cursor:pointer">
							<div class="stats-icon stats-icon-lg"><i class="fa fa-users fa-fw"></i></div>
							<div class="stats-content">
								<div class="stats-title">{{ 'tot. emails' | transUC }}</div>
								<div class="stats-number" v-text="stats2.emailsCount"></div>
								<div class="stats-desc">
									<table class="stats-table">
										<tbody>
											<tr>
												<td>{{ 'usable[p]' | transUF }}</td>
												<td>{{ stats2.emailsCleanCount }}</td>
											</tr>
											<tr>
												<td>{{ 'excluded[p]' | transUF }}</td>
												<td>{{ stats2.emailsExcludedCount }}</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
					<div class="col-3">
						<div class="widget widget-stats bg-gradient-blue" @click="redirect('/newsletters')" style="cursor:pointer">
							<div class="stats-icon stats-icon-lg"><i class="fa fa-envelope fa-fw"></i></div>
							<div class="stats-content">
								<div class="stats-title">{{ 'from Medias' | transUC }}</div>
								<div class="stats-number" v-text="stats2.mediasEmailsCount"></div>
								<div class="stats-desc">
									<table class="stats-table">
										<tbody>
											<tr>
												<td>{{ 'usable[p]' | transUF }}</td>
												<td>{{ stats2.mediasEmailsCleanCount }}</td>
											</tr>
											<tr>
												<td>{{ 'excluded[p]' | transUF }}</td>
												<td>{{ stats2.mediasEmailsExcludedCount }}</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
                    <div class="col-3">
						<div class="widget widget-stats bg-gradient-teal" @click="redirect('/newsletters')" style="cursor:pointer">
							<div class="stats-icon stats-icon-lg"><i class="fa fa-envelope fa-fw"></i></div>
							<div class="stats-content">
								<div class="stats-title">{{ 'not Medias' | transUC }}</div>
								<div class="stats-number" v-text="stats2.notMediasEmailsCount"></div>
								<div class="stats-desc">
									<table class="stats-table">
										<tbody>
											<tr>
												<td>{{ 'usable[p]' | transUF }}</td>
												<td>{{ stats2.notMediasEmailsCleanCount }}</td>
											</tr>
											<tr>
												<td>{{ 'excluded[p]' | transUF }}</td>
												<td>{{ stats2.notMediasEmailsExcludedCount }}</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
                    <div class="col-3">
					</div>
				</div>
			</div>

		</div>
    </div>
</template>

<script>
export default {
    name : 'Indicators',
    mounted() {
        this.getStats1()
		this.getStats2()
		this.getVerifyEmail()
    },
    data() {
        return  {
			verifyEmail : 'loading..',
            stats1 : {
                journalistsCount : 'loading..',
                mediasJournalistsCount : 'loading..',
                notMediasJournalistsCount : 'loading..',
            },
            stats2 : {
				emailsCount : 'loading..',
                mediasEmailsCount : 'loading..',
				notMediasEmailsCount : 'loading..',
				emailsCleanCount : '-',
				emailsExcludedCount : '-',
				mediasEmailsCleanCount : '-',
				mediasEmailsExcludedCount : '-',
				notMediasEmailsCleanCount : '-',
				notMediasEmailsExcludedCount : '-',
            }
        }
    },
    methods : {
		redirect(url) {
			if ( this.$route.path !== url )
                this.$router.push({ path: url })
		},
        getStats1() {
            var t = this
            window.axios.get('/api/indicators/stats1')
            .then(function(response) {
                Object.keys(response.data).map(item => {
                    t.stats1[item] = Number(response.data[item]).toLocaleString('it-IT', {minimumFractionDigits : 0})
                });
            })
        },
        getStats2() {
            var t = this
            window.axios.get('/api/indicators/stats2')
            .then(function(response) {
                Object.keys(response.data).map(item => {
                    t.stats2[item] = Number(response.data[item]).toLocaleString('it-IT', {minimumFractionDigits : 0})
                });
            })
		},
		getVerifyEmail() {
			var t = this
            window.axios.get('/api/indicators/verify-email')
            .then(function(response) {
				t.verifyEmail = response.data
            })
		}
    }
}
</script>