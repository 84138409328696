<template>
	<div>
        <div class="card text-center">
            <div class="card-header" v-text="transUC('monthly sending')"></div>
            <div class="card-block">
                <sent-in-period
                v-if="graphs.monthlysent !== undefined"
                :labels="graphs.monthlysent.labels"
                :datasets="graphs.monthlysent.datasets"
                ></sent-in-period>
            </div>
        </div>
        <div class="card text-center">
            <div class="card-header" v-text="transUC('weekly sending')"></div>
            <div class="card-block">
                <sent-in-period
                v-if="graphs.weeklysent !== undefined"
                :labels="graphs.weeklysent.labels"
                :datasets="graphs.weeklysent.datasets"
                ></sent-in-period>
            </div>
        </div>
	</div>
</template>

<script>
export default {
    name : 'NewslettersGraphs',
    computed : {
        graphs() { return this.$parent.graphs }
    },
    methods : {
        getRandomInt () {
            return Math.floor(Math.random() * (50 - 5 + 1)) + 5
        }
    }
}
</script>