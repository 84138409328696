<template>
    <div>
        <table class="table table-condensed table-filters">
            <draggable :list="obj.filters" tag="tbody" handle=".handle" @end="updateOrder">
                <tr v-for="(filter, k) in obj.filters" :key="k">
                    <td style="width:70px; text-align:right">
                        <button class="btn btn-default btn-xs" @click="$emit('removeFilter', k)">
                            <i class="fa fa-trash"></i>
                        </button>
                        <button 
                            v-if="$parent.currentFilterId != k"
                            style="width:24px"
                            class="btn btn-default btn-xs" @click="$emit('editFilter', k)">
                            <i class="fa fa-pen"></i>
                        </button>
                        <button 
                            v-else
                            style="width:24px"
                            class="btn btn-default btn-xs" @click="$emit('editFilter', null)">
                            <i class="fa fa-times"></i>
                        </button>
                    </td>
                    <td style="min-width:50px">
                        <select v-model="filter.add_subtract" class="form-control" 
                            style="padding: 2px; max-width: 36px;"
                            @change="updateObj"
                        >
                            <option value="add">+</option>
                            <option value="subtract">-</option>
                        </select>
                    </td>
                    <td v-html="filter.description" style="text-align:left"></td>
                    <td style="width:50px" class="handle"><i class="fa fa-align-justify"></i></td>
                </tr>
            </draggable>
            <tfoot>
                <tr><td colspan="4">
                    <button class="btn btn-default btn-xs"
                    @click="$emit('newFilter')">
                        <i class="fa fa-plus"></i> Nuovo filtro
                    </button>
                </td></tr>
            </tfoot>
        </table>
    </div>
</template>

<script>
import draggable from 'vuedraggable'
export default {
    name : 'FiltersList',
    components : {
        draggable
    },
    computed : {
        obj: { 
            get() { return this.$parent.obj },
            set(data) { this.$parent.obj = data },
        }
    },
    data() {
        return  {
        }
    },
    methods : {
        updateOrder() {
            this.$emit('updateObj')
        },
        updateObj(data) {
            this.$emit('updateObj', data)
        },
    }
}
</script>
<style scoped>
.table-filters td {
    padding: 8px; 
}
</style>
