<template>
    <div>
    </div>
</template>

<script>
export default {
    name : 'SentList',
    
};
</script>