<template>
    <div style="height:300px">
        <bar-chart :chart-data="datacollection" :styles="styles" :options="options"></bar-chart>
    </div>
</template>

<script>
import BarChart from './BarChart.js'
export default {
    components: {
        BarChart
    },
    props : [ 'labels', 'datasets' ],
    computed : {
        datacollection() {
            return {
                labels : this.$props.labels,
                datasets : this.$props.datasets
            }
        }
    },
    methods : {
        getRandomInt () {
            return Math.floor(Math.random() * (50 - 5 + 1)) + 5
        }
    },
    data() {
        return {
            styles : {
                height: '100%',
                width: '100%',
                position: 'relative'
            },
            options: {
				scales: {
					yAxes: [{
						ticks: {
							beginAtZero: true
						},
						gridLines: {
							display: true
						}
					}],
					xAxes: [{
						ticks: {
							beginAtZero: true
						},
						gridLines: {
							display: false
						}
					}]
				},
				legend: {
					display: false
				},
				tooltips: {
					enabled: true,
					mode: 'single',
					callbacks: {
						label: function(tooltipItems, data) {
							return tooltipItems.yLabel;
						}
					}
				},
				responsive: true,
				maintainAspectRatio: false,
				// height: 200
			}
        }
    }
}
</script>