<template>
	<div>
        <b-modal id="modalAddToLister" 
            ref="modalAddToLister"
            size="md" 
            :title="transUF('add to lists')"
            @ok="addToList"
        >
            <Multiselect 
                v-model="selectedMailinglists"
                :options="mailinglists"
                label="name"
                track-by="id"
                :groupSelect="false"
                :close-on-select="true"
                multiple
            ></Multiselect>
        </b-modal>
	</div>
</template>

<script>
export default {
    name : 'AddToLister',
    props : [ 'addableRef', 'mailinglists', 'obj' ],
    methods : {
        show() {
            this.$refs.modalAddToLister.show()
        },
        addToList() {
            var t = this
            var mailinglists = []
            var journalist_ids = []
            journalist_ids.push(t.obj.id)
            for (var i = 0; i<t.selectedMailinglists.length; i++)
                mailinglists.push(t.selectedMailinglists[i].id)
            window.axios.post('/api/journalists/add-to-lists', {
                ids : journalist_ids,
                mailinglists : mailinglists
            }).then(function() {
                window.notify('success', 'Oggetti inseriti nelle liste selezionate')
                t.selectedMailinglists = []
                t.$parent.$refs[t.addableRef].refresh()
            })
        }
    },
    data() {
        return {
            selectedMailinglists : []
        }
    }
}
</script>