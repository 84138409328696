<template>
    <div>
        <ol class="breadcrumb pull-right">
            <li v-for="segment in segments" :key="segment.name" class="breadcrumb-item" :class="{ 'active' : segment.active}">
                <a :href="segment.path" class="text-uppercase">{{ segment.name }}</a>
            </li>
        </ol>
    </div>
</template>

<script>
export default {
    name: 'Breadcrumb',
    data() {
        return {
            url : window.location.pathname.split('/').filter(Boolean)
        }
    },
    computed : {
        segments() {
            var segments = [];
            var path = '';
            for (var key of Object.keys(this.url)) {
                path += ('/'+this.url[key])
                segments.push({
                    name : this.url[key],
                    path : path,
                    active : key == (this.url.length-1) ? true : false
                })
            }
            return segments;
        }
    }
}
</script>