<template>
	<div>
        <b-modal id="bulkDeleter" 
            ref="bulkDeleter"
            size="md" 
            :title="transUF('confirm')"
            @ok="deleteIds"
        >
        Stai per eliminare in maniera irreversibile {{ ids.length === 1 ? 'la linea' : 'le linee' }}: 
        <br>
        <br>
        <div class="clearfix">
            <div 
                v-for="(id, k) in ids" :key="k"
                style="border: 1px solid grey;
                    padding: 4px;
                    float: left;
                    display: inline-block;
                    margin: 4px 4px 0px 0px;"
            ><b v-text="id"></b></div>
        </div>
        <div style="clear:both; margin-top:20px">
            Clicca "OK" per confermare l'eliminazione.
        </div>
        </b-modal>
	</div>
</template>

<script>
export default {
    name : 'BulkDeleteModal',
    props : [ 'deletableRef', 'domain', 'additionalParameters' ],
    methods : {
        show(ids) { 
            this.ids = ids
            this.$refs.bulkDeleter.show()
        },
        deleteIds() {            
            var t = this

            t.$parent.$refs[t.deletableRef].loaded = false

            if (t.ids.length > 0) {
                
                var ids = { 'ids' : t.ids }
                var params = {}

                if (t.additionalParameters !== undefined)
                    params = { ...ids, ...t.additionalParameters  }
                else
                    params = ids

                window.axios.post('/api/'+t.domain+'/bulk-delete', params)
                .then(response => {

                    if (response.data === 'KO') {
                        window.notify( 'error',  t.transUF('is not possible to delete the items now, please try again later') )
                        
                    } else {
                        window.notify( 'success',  t.transUF('records deleted') )

                        if (t.deletableRef !== undefined && 
                            t.$parent.$refs[t.deletableRef] !== undefined
                        ) { t.$parent.$refs[t.deletableRef].refresh() }
                    
                        t.$emit('callbackFunction')
                    }

                })
                .catch(function () {
                    window.notify( 'error',  'Non è stato possibile eliminare questi record' );
                });
            }
        }
        // this.$refs.bulkDeleter.show()
    },
    data() {
        return {
            ids : []
        }
    }
}
</script>