<template>
	<div>
        <mailinglists-list 
            v-if="obj === null && !$route.params.id" 
            :obj="obj"
            @setObj="setObj"
            @newObj="newObj"
        ></mailinglists-list>
        <mailinglist-show
            v-else-if="obj" 
            @setObj="setObj"
            @updateObj="updateObj"
            ref="mailinglistShow"
            :journalists_count="journalists_count"
            :editorials_count="obj.editorials_count"
        >
        </mailinglist-show>
        <div v-else v-text="transUF('loading')+'...'"></div>
	</div>
</template>

<script>
import MailinglistsList from './mailinglists/List.vue'
import MailinglistShow from './mailinglists/Show.vue'
export default {
    name : 'Mailinglists',
    components : {
        MailinglistShow,
        MailinglistsList,
    },
    beforeMount() {
        if (this.$route.params.id) {
            window.axios.get('/api/mailinglists/'+this.$route.params.id)
            .then(response => {
                this.setObj(response.data);
            })
        }
    },
    data() {
        return {
            obj : window.__START.obj,
            allRelations : window.__START.allRelations,
            users : window.__START.users,
            emptyFilter : window.__START.emptyFilter,
            filteringInProgress : false,
        }
    },
    computed : {
        journalists_count() {
            return this.obj ? this.obj.journalist_ids.length : 0
        }
    },
    methods : {
        setObj(data) {
            if (data === null) {
                this.obj = null;
                if ( this.$route.path !== '/mailinglists' )
                    this.$router.push({ path: '/mailinglists' /*, params: { id: '123' } */ })
            }
            else {
                this.obj = data;
                if ( this.$route.path !== '/mailinglists/'+data.id )
                    this.$router.push({ path: '/mailinglists/'+data.id })
            }
        },
        updateObj(data) {
            var t = this;
            t.filteringInProgress = true
            if (data === false) {
                window.axios.get('/api/mailinglists/'+t.obj.id)
                .then( function(response){
                    t.setObj(response.data)
                    window.notify('success', t.transUF('record updated') )
                    t.$refs.mailinglistShow.refreshTables()
                    t.filteringInProgress = false
                })
            } else {
                if (data !== null && data !== undefined)
                    t.obj = Object.assign({}, t.obj, data)
                window.axios.post("/api/mailinglists/" + t.obj.id, t.obj)
                .then(function(response) {
                    t.setObj(response.data)
                    window.notify('success', t.transUF('record updated') )
                    t.$refs.mailinglistShow.refreshTables()
                    t.filteringInProgress = false
                })
            }
        },
        newObj() {
            var t = this;
            window.axios.post('/api/mailinglists/create')
            .then( function(response) {
                t.setObj(response.data)
                window.notify('success', t.transUF('new record created') )
            })
        },
        deleteObj() {
            var t = this;
            window.axios.post('/api/mailinglists/'+t.obj.id+'/delete')
            .then( function(){
                t.setObj(null)
            })
        },
        duplicateObj() {
            var t = this;
            window.axios.post('/api/mailinglists/'+t.obj.id+'/duplicate')
            .then( function(response){
                window.notify('success', 'Oggetto duplicato')
            })
        }
    },
}
</script>