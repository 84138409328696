<template>
	<div>
        <div v-if="obj === null">
            <div style="text-align:left">
                <button type="button" 
                    v-if="showTable" 
                    class="btn btn-default btn-xs" 
                    @click="showTable = !showTable">
                    <i class="fa fa-picture"></i> Mostra GRIGLIA
                </button>
                <button type="button" 
                    v-else 
                    class="btn btn-default btn-xs" 
                    @click="showTable = !showTable">
                    <i class="fa fa-table"></i> Mostra TABELLA
                </button>
                <br><br>
            </div>
            <templates-grid 
                v-if="!showTable"
                @setObj="setObj"
                @newObj="newObj"
            ></templates-grid>
            <templates-list 
                v-else
                @setObj="setObj"
                @newObj="newObj"
            ></templates-list>
        </div>
        <div class="row" v-else>
            <template-show
                @setObj="setObj"
                @deleteObj="deleteObj"
                @updateObj="updateObj"
            ></template-show>
        </div>
	</div>
</template>

<script>
import TemplatesGrid from './templates/Grid.vue'
import TemplatesList from './templates/List.vue'
import TemplateShow from './templates/Show.vue'
export default {
    name : 'Templates',
    components : {
        TemplateShow,
        TemplatesGrid,
        TemplatesList
    },
    beforeMount() {
        if (this.$route.params.id) {
            window.axios.get('/api/templates/'+this.$route.params.id)
            .then(response => {
                this.setObj(response.data);
            })
        }
    },
    data() {
        return {
            templates : window.__START.templates,
            obj : window.__START.obj,
            showTable : false
        }
    },
    methods : {
        updateTemplates() {
            var t = this
            window.axios.get('/api/templates/all')
            .then(function(response) {
                t.templates = response.data
            })
        },
        updateObj(data) {
            var t = this;
            t.obj = Object.assign({}, t.obj, data)
            window.axios.post('/api/templates/'+t.obj.id, t.obj)
            .then(function() {
                window.notify('success', 'Template salvato')
                t.updateTemplates()
            })
        },
        setObj(data) {
            if (data === null) {
                this.obj = null;
                if ( this.$route.path !== '/templates' )
                    this.$router.push({ path: '/templates' /*, params: { id: '123' } */ })
            }
            else {
                this.obj = data;
                window.axios.get('/api/templates/'+data.id)
                .then(response => {
                    this.obj = response.data
                    if ( this.$route.path !== '/templates/'+data.id )
                        this.$router.push({ path: '/templates/'+data.id })
                })
            }
        },
        newObj() {
            var t = this;
            window.axios.post('/api/templates/create')
            .then(function(response) {
                t.templates.push(response.data)
                t.setObj(response.data)
            })
        },
        deleteObj() {
            var t = this
            window.axios.post('/api/templates/'+t.obj.id+'/delete')
            .then(function() {
                for (var i = 0; i < t.templates.length; i++) {
                    if (t.templates[i].id === t.obj.id)
                        t.templates.splice(i, 1)
                }
                t.setObj(null)
            })
        }
    }
}
</script>