<template>
	<div>
        <b-modal id="modalMerger" 
            ref="modalMerger"
            size="lg" 
            :title="transUF('merge')"
            @ok="doMerge"
        >
            <div class="row" v-if="objects.length > 0">
                <div class="col-12" style="  display: flex;
                    flex-wrap: nowrap;
                    overflow-x: auto;">
                    <div style="flex: 0 0 auto; padding:4px; margin:4px; border:1px solid grey;" v-for="(object, k) in objects" :key="k">
                        <table class="table table-striped table-condensed">
                            <tbody>
                                <tr v-for="(column, k2) in columns" :key="k2">
                                    <td>{{ column | transUF }}</td>
                                    <td><b>{{ object[column] }}</b></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="col-12">
                    <br>
                    <h5>{{ 'merged' | transUF }}:</h5>
                    <p>Compila i campi e clicca "OK" per salvare</p>
                    <table class="table table-striped table-condensed">
                        <tbody>
                            <tr>
                                <td>{{ 'first name' | transUW }}</td>
                                <td>
                                    <b-input type="text" 
                                        name="first_name"
                                        v-model="merged.first_name" 
                                        class="input-sm"
                                        style="display:inline;"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>{{ 'last name' | transUW }}</td>
                                <td>
                                    <b-input type="text" 
                                        name="last_name"
                                        v-model="merged.last_name" 
                                        class="input-sm"
                                        style="display:inline;"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>Email</td>
                                <td>
                                    <b-input type="text" 
                                        name="email"
                                        v-model="merged.email" 
                                        class="input-sm"
                                        style="display:inline;"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>{{ 'telephone' | transUW }}</td>
                                <td>
                                    <b-input type="text" 
                                        name="telephone"
                                        v-model="merged.telephone" 
                                        class="input-sm"
                                        style="display:inline;"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>{{ 'mobile' | transUW }}</td>
                                <td>
                                    <b-input type="text" 
                                        name="mobile"
                                        v-model="merged.mobile" 
                                        class="input-sm"
                                        style="display:inline;"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>{{ 'fax' | transUW }}</td>
                                <td>
                                    <b-input type="text"
                                        name="fax"
                                        v-model="merged.fax" 
                                        class="input-sm"
                                        style="display:inline;"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>{{ 'website' | transUW }}</td>
                                <td>
                                    <b-input type="text" 
                                        name="internet"
                                        v-model="merged.internet" 
                                        class="input-sm"
                                        style="display:inline;"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>{{ 'address' | transUW }}</td>
                                <td>
                                    <b-input type="text" 
                                        name="address"
                                        v-model="merged.address" 
                                        class="input-sm"
                                        style="display:inline;"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>{{ 'postcode' | transUW }}</td>
                                <td>
                                    <b-input type="text" 
                                        name="postcode"
                                        v-model="merged.postcode" 
                                        class="input-sm"
                                        style="display:inline;"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>{{ 'city' | transUW }}</td>
                                <td>
                                    <b-input type="text" 
                                        name="city"
                                        v-model="merged.city" 
                                        class="input-sm"
                                        style="display:inline;"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'province' | transUW }}
                                </td>
                                <td>
                                    <select class="form-control input-inline input-sm" v-model="merged.province_id" ref="province_id" style="width:100%">
                                        <option value="null" disabled selected>Seleziona</option>
                                        <option v-for="province in provinces" v-bind:key="province.name" v-bind:value="province.id">
                                            {{ province.name }}
                                        </option>
                                    </select>
                                    <br>
                                </td>
                            </tr>
                            <tr>
                                <td class="field valign-middle">
                                    {{ 'country' | transUW }}
                                </td>
                                <td>
                                    <select class="form-control input-inline input-sm" v-model="merged.country_id" style="width:100%">
                                        <option value="null" disabled selected>Seleziona</option>
                                        <option v-for="country in countries" v-bind:key="country.name" v-bind:value="country.id">
                                            {{ country['name_'+locale] }}
                                        </option>
                                    </select>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </b-modal>
	</div>
</template>

<script>
export default {
    name : 'JournalistsMergeModal',
    props : [ 'mergebleRef', 'regions', 'provinces', 'countries' ],
    computed : {
        locale() {
            return window.locale
        }
    },
    methods : {
        show(ids) {
            var t = this
            t.ids = ids
            window.axios.get('/api/journalists/for-merge', {
                params: {
                    'ids': ids
                }
            }).then(function(response) {
                t.objects = response.data.objects
                t.merged = response.data.merged
                t.$refs.modalMerger.show()
            })
        },
        doMerge() {
            var t = this

            t.$parent.$refs[t.mergebleRef].loaded = false

            window.axios.post('/api/journalists/merge', {
                ids : t.ids,
                merged : t.merged
            }).then(function(response) {
                window.notify('success', 'Gli oggetti sono stati uniti')
                window.open('/#/journalists/'+response.data)
                t.$parent.$refs[t.mergebleRef].refresh()
            })
        }
    },
    data() {
        return {
            objects : [],
            ids : [],
            merged : {},
            columns : [
                'id',
                'first_name',
                'last_name',
                'telephone',
                'mobile',
                'email',
                'address',
                'postcode',
                'city',
            ]
        }
    }
}
</script>