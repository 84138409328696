<template>
	<div>
        <customers-list 
            v-if="obj === null && !$route.params.id" 
            :obj="obj"
            @setObj="setObj"
            @newObj="newObj"
        ></customers-list>
        <customer-show
            v-else-if="obj" 
            @setObj="setObj"
            @updateObj="updateObj"
        >
        </customer-show>
        <div v-else v-text="transUF('loading')+'...'"></div>
	</div>
</template>

<script>
import CustomersList from './customers/List.vue'
import CustomerShow from './customers/Show.vue'
export default {
    name : 'Customers',
    components: {
        CustomersList,
        CustomerShow,
    },
    beforeMount() {
        if (this.singleCustomer) {
            this.$router.push('/customers/1').catch(() => {})
        }
        if (this.$route.params.id) {
            window.axios.get('/api/customers/'+this.$route.params.id)
            .then(response => {
                this.setObj(response.data);
            })
        }
    },
    data() {
        return {
            singleCustomer : process.env.MIX_SINGLE_CUSTOMER === 'true',
            obj : window.__START.obj,
            users : window.__START.users
        }
    },
    methods : {
        setObj(data) {
            if (data === null) {
                this.obj = null;
                if ( this.$route.path !== '/customers' )
                    this.$router.push({ path: '/customers' /*, params: { id: '123' } */ })
            }
            else {
                this.obj = data;
                if ( this.$route.path !== '/customers/'+data.id )
                    this.$router.push({ path: '/customers/'+data.id })
            }
        },
        updateObj(data) {
            var t = this;
            // false = get back unmodified data from Server
            if (data === false) {
                window.axios.get('/api/customers/'+t.obj.id)
                .then( function(response){
                    t.setObj(response.data)
                })
            } else {
                t.obj = Object.assign({}, t.obj, data)
                window.axios.post("/api/customers/" + t.obj.id, t.obj)
                window.notify('success', t.transUF('record updated') )
            }
        },
        newObj() {
            var t = this;
            window.axios.post('/api/customers/create')
                .then( function(response){
                    t.setObj(response.data)
                })
        }
    },
    watch : {
    }
}
</script>