<template>
    <div class="row">
        
        <div class="col-12" style="margin-bottom:12px">
            <button v-if="$parent.$parent.embeddingTemplates === undefined"
                class="btn btn-xs btn-default float-right"
                @click="$emit('newObj')"
            >
                <i class="fa fa-plus"></i> {{ 'new record' | transUF }}
            </button>
        </div>
        
        <div class="col-lg-3 col-md-6 clickable" v-for="(template, k) in templates" :key="template.id" @click="$emit('setObj', template)">
            <div class="card">
                <div style="height: 200px;
                    background-position: top center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    border: 1px solid #cec;
                    border-bottom: 0px" :style="{ 'background-image': 'url('+template.picture+')' }">&nbsp;</div>
                <!-- <img class="card-img-top" :src="template.picture" alt="" /> -->
                <div class="card-block">
                    <h5 class="card-title m-b-0" v-text="template.name"></h5>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name : 'TemplatesGrid',
    props : ['obj'],
    computed : {
        templates() { 
            if ( this.$parent ) {
                if (this.$parent.templates)
                    return this.$parent.templates
            }
            if ( this.$parent.$parent ) {
                if (this.$parent.$parent.templates)
                    return this.$parent.$parent.templates
            }
            if ( this.$parent.$parent.$parent ) {
                if (this.$parent.$parent.$parent.templates)
                    return this.$parent.$parent.$parent.templates
            }
            if ( this.$parent.$parent.$parent.$parent ) {
                if (this.$parent.$parent.$parent.$parent.templates)
                    return this.$parent.$parent.$parent.$parent.templates
            }
        }
    }
} 
</script>