<template>
	<div>
        <div class="row">
            <div class="col-md-12">
                <imports-modifications></imports-modifications>
                <br>
                <!-- <hr>
                <br> -->
                <!-- <imports-files></imports-files> -->
                <!-- <br> -->
                <hr>
                <br>
            </div>
            <div class="col-md-12">
                <h4>{{ 'files list' | transUF }}</h4>
                <imports-list></imports-list>
            </div>
        </div>
	</div>
</template>

<script>
import ImportsModifications from './imports/Modifications.vue'
// import ImportsFiles from './imports/Files.vue'
import ImportsList from './imports/List.vue'
export default {
    name : 'Imports',
    components: {
        ImportsList,
        ImportsModifications,
        // ImportsFiles
    },
    mounted() {
    },
    data() {
        return {
            files : window.__START.files,
        }
    },
    methods : {
    },
    watch : {
    }
}
</script>