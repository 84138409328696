<template>
	<div>
        <h4>{{ 'emails control' | transUF }}</h4>
        <g-vue-table
            ref="deletableVueTable"
            :apiIndexUrl="'/api/email-verifications'"
            sortBy="email"
            :columns="columns"
            :creable="false"
            :actionsDropdown="actionsDropdown"
        ></g-vue-table>

        <bulk-delete-modal 
            ref="bulkDeleteModal" 
            domain="email-verifications"
            deletableRef="deletableVueTable"
        ></bulk-delete-modal>
	</div>
</template>

<script>
export default {
    name : 'EmailVerifications',
    data(){
        return {
            columns : [{
                name: '__checkbox',
                titleClass: 'text-center short-cell',
                dataClass: 'text-center short-cell',
                visible : true,
            },{
                title: '#',
                name: 'id',
                sortField: 'id',
                titleClass: 'text-right medium-cell',
                dataClass: 'text-right medium-cell',
                visible : true,
            },{
                title : 'EMAIL',
                name: 'email',
                sortField: 'email',
                visible : true,
            },{
                title : 'SOURCE',
                name: 'source',
                sortField: 'source',
                visible : true,
            },{
                title : this.transUC('description'),
                name: 'status_description',
                sortField: 'status_description',
                visible : true,
            },{
                title : 'SMTP',
                name: 'smtp_code',
                sortField: 'smtp_code',
                visible : true,
            },{
                title : 'LOG',
                name: 'smtp_log',
                sortField: 'smtp_log',
                visible : true,
            },{
                title : this.transUC('updated'),
                name: 'updated_at',
                sortField: 'updated_at',
                titleClass: 'text-center updated-at-cell',
                dataClass: 'text-right updated-at-cell',
                visible : true,
            }]
        }
    },
    computed: {
        actionsDropdown() {
            var t = this;
            return [{
                name : this.transUC('delete'),
                clicked : function(ids) {
                    t.$refs.bulkDeleteModal.show( ids );
                }
            }]
        }
    }
}
</script>