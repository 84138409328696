<template>
    <div class="form-group" style="max-width:300px; display:inline-block; float:left">
        <input type="text" v-model="filterText" class="form-control" @keyup.enter="doFilter" :placeholder="transUF('search')+'. . .'">
    </div>
</template>

<script>
export default {
    name : 'FilterBar',
    data () {
        return {
            filterText: ''
        }
    },
    methods: {
        doFilter () {
            this.$emit('setFilter', this.filterText);
        }
    }
}
</script>

<style scoped>
.filter-bar {
    padding: 0px;
}
</style>
