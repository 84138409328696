<template>
	<div>
        <publishers-list 
            v-if="obj === null && !$route.params.id" 
            :obj="obj"
            @setObj="setObj"
            @newObj="newObj"
            :regions="regions"
            :provinces="provinces"
            :countries="countries"
        ></publishers-list>
        <publisher-show
            v-else-if="obj" 
            :regions="regions"
            :provinces="provinces"
            :countries="countries"
        >
        </publisher-show>
        <div v-else v-text="transUF('loading')+'...'"></div>
	</div>
</template>

<script>
import PublishersList from './publishers/List.vue'
import PublisherShow from './publishers/Show.vue'
export default {
    name : 'Publishers',
    components : {
        PublishersList,
        PublisherShow,
    },
    beforeMount() {
        if (this.$route.params.id) {
            window.axios.get('/api/publishers/'+this.$route.params.id)
            .then(response => {
                this.setObj(response.data);
            })
        }
    },
    data() {
        return {
            regions : window.__START.regions,
            provinces : window.__START.provinces,
            countries : window.__START.countries,
            obj : window.__START.obj,
        }
    },
    methods : {
        getModifications() {
            var t = this;
            window.axios.get('/api/publishers/'+t.obj.id+'/modifications')
            .then(function(response){
                t.obj.modifications = response.data
            });
        },
        setObj(data) {
            if (data === null) {
                this.obj = null;
                if ( this.$route.path !== '/publishers' )
                    this.$router.push({ path: '/publishers' /*, params: { id: '123' } */ })
            }
            else {
                this.obj = data;
                this.getModifications();
                if ( this.$route.path !== '/publishers/'+data.id )
                    this.$router.push({ path: '/publishers/'+data.id })
            }
        },
        updateObj(data) {
            var t = this;
            // false = get back unmodified data from Server
            if (data === false) {
                window.axios.get('/api/publishers/'+t.obj.id)
                .then( function(response){
                    t.setObj(response.data)
                })
            } else {
                t.obj = Object.assign({}, t.obj, data)
                window.axios.post("/api/publishers/" + t.obj.id, t.obj)
                .then(function() {
                    t.getModifications()
                    window.notify('success', t.transUF('record updated') )
                })
            }
        },
        newObj() {
            var t = this;
            window.axios.post('/api/publishers/create')
            .then( function(response) {
                t.setObj(response.data)
                window.notify('success', t.transUF('new record created') )
            })
        },
        deleteObj() {
            var t = this;
            window.axios.post('/api/publishers/'+t.obj.id+'/delete')
            .then( function(){
                t.setObj(null)
                window.notify('success', t.transUF('record deleted') )
            })
        },
    }
}
</script>