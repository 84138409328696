<template>
    <b-dropdown variant="link" menu-class="p-b-0">
        <template slot="button-content">
            <img :src="current.icon" style="max-width:20px"/>
            <span class="name">{{ current.name }}</span>
            <b class="caret"></b>
        </template>
        <b-dropdown-item href="javascript:;" v-for="locale in others" :key="locale.short" @click="changeLocale(locale)">
            <img :src="locale.icon" style="max-width:20px" />
            {{ locale.name }}
        </b-dropdown-item>
    </b-dropdown>
</template>

<script>
export default {
    name : 'Flags',
    data() {
        return {
            locales : {
                it : {
                    short : 'it',
                    name : 'Italiano',
                    icon : '/assets/img/flags/it.svg'
                }, 
                en : {
                    short : 'en',
                    name : 'English',
                    icon : '/assets/img/flags/en.svg'
                }
            }
        }
    },
    computed : {
        current() {
            return this.locales[window.locale];
        },
        others() {
            var allLocales = this.locales;
            delete allLocales[this.current.short];
            return allLocales;
        }
    },
    methods : {
        changeLocale(locale) {
            window.location.href = '/'+locale.short;
        }
    }
}
</script>