<template>
	<div>
		<div id="sidebar" class="sidebar" v-bind:class="{ 'sidebar-transparent': pageOptions.pageSidebarTransparent }">
			<div class="height-full bpress-bg" v-scroll="handleScroll" :class="{'overflow-scroll':false}">
				<!-- <sidebar-nav-profile></sidebar-nav-profile> -->
				<sidebar-nav v-bind:scrollTop="scrollTop"></sidebar-nav>
			</div>
		</div>
		<div class="sidebar-bg"></div>
		<div class="mobile-click" v-on:click="dismissSidebar"></div>
	</div>
</template>

<script>
import SidebarNav from './SidebarNav.vue'
// import SidebarNavProfile from './SidebarNavProfile.vue'
import pageOptions from '../../config/PageOptions.vue'

export default {
	name: 'Sidebar',
	components: {
		SidebarNav
		// SidebarNavProfile
	},
	data() {
		return {
			scrollTop: '',
			pageOptions
		}
	},
	directives: {
		scroll: {
			inserted: function (el, binding) {
				let f = function (evt) {
					if (binding.value(evt, el)) {
						el.removeEventListener('scroll', f)
					}
				}
				el.addEventListener('scroll', f)
				
				if (typeof(Storage) !== 'undefined') {
					if (localStorage.sidebarScroll) {
						el.scrollTop = localStorage.sidebarScroll;
					}
				}
			}
		}
	},
	methods: {
		handleScroll: function(evt) {
			this.scrollTop = evt.target.scrollTop;
			if (typeof(Storage) !== 'undefined') {
				localStorage.setItem('sidebarScroll', this.scrollTop);
			}
		},
		dismissSidebar: function() {
			this.pageOptions.pageMobileSidebarToggled = false;
			this.pageOptions.pageMobileRightSidebarToggled = false;
		}
	}
}
</script>
