<template>

    <div class="row" v-if="obj !== null" >
        <div class="col-md-12">
            <button 
                style="float:left"
                @click="setObj(null)" 
                class="btn btn-xs btn-default m-r-5">
                <i class="fa fa-chevron-left"></i> {{ 'show all' | transUF }}
            </button>
            <h4 style="float:left" class="m-l-5">{{ obj.name }}</h4>
            <div v-if="showDuplicator">
                <table style="border:none; float:right">
                    <tr>
                        <td><deleter></deleter></td>
                        <td><duplicator class="nospaces-duplicator" @duplicateObj="duplicateObj"></duplicator></td>
                    </tr>
                </table>
            </div>
            <deleter v-else></deleter>
            <br>
            <br>
        </div>
    </div>

</template>
<script>
import Deleter from './Deleter.vue'
import Duplicator from './Duplicator.vue'
export default {
    name: 'ObjectTopbar',
    components : {
        Deleter,
        Duplicator
    },
    props : {
        obj : { default: null },
        showDuplicator : { default: false }
    },
    methods: {
        deleteObj() {
            this.$parent.deleteObj()
        },
        duplicateObj() {
            this.$parent.duplicateObj()
        },
        setObj(data) {
            this.$parent.setObj(data)
        }
    }
}
</script>