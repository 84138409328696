<template>
	<div>
        <div class="card text-center">
            <div class="card-header" v-text="transUC('latest sending')"></div>
            <div class="card-block">
                <div class="card" v-for="(newsletter, k) in newsletters" :key="k">
                    <div class="row" style="cursor:pointer; border: 1px solid #cecece; border-left:none; border-right:none; padding: 8px;" @click="redirect('/newsletters/'+newsletter.id)">
                        <div class="col-md-3" style="height:100px; background-size:cover; background-repeat:no-repeat; background-position: center middle" :style="{ 'background-image' : 'url('+ newsletter.image +')'}"></div>
                        <div class="col-md-9" style="text-align:left">
                            <h5>{{ newsletter.customer_names }}</h5>
                            <p v-text="newsletter.subject"></p>
                            <p v-html="transUF('sent on')+' <b>'+newsletter.send_start+'</b> | '+trans('to')+' <b>'+newsletter.emails_count+'</b> '+transUF('addresses')"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
	</div>
</template>

<script>
export default {
    name : 'NewslettersList',
    computed : {
        newsletters() { return this.$parent.newsletters }
    },
    data(){
        return {
        }
    },
    methods : {
        redirect(url) {
            if ( this.$route.path !== url )
                this.$router.push({ path: url })
        }
    }
}
</script>