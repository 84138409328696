<template>
    <div style="max-width:400px; padding:30px; margin:auto; text-align:center;">
        <pie-chart :chart-data="chartData" :styles="styles" :options="options"></pie-chart>
    </div>
</template>

<script>
import PieChart from './PieChart.js'
export default {
    name : 'NewsletterPie',
    components: {
        PieChart
    },
    props : [ 'chartData' ],
    computed : {
    },
    data() {
        return {
            styles : {
                height : '300px;',
                position : 'relative'
            },
            options: {
                legend : {
                    position : 'bottom',
                }
			}
        }
    }
}
</script>